//format of original link is https://itxviewer.com/itxviewer/index.html?c3R1ZHlfcGs9MjY1Mzky
//where c3R1ZHlfcGs9MjY1Mzky is base64 encoded from study_pk=265392
// test http://localhost:3000/itxviewer/index.html?c3R1ZHlfcGs9MTgxNjE4
// for the test databse use study pk 181618 c3R1ZHlfcGs9MTgxNjE4
// or for a dummy test use http://localhost:3000/itxviewer/index.html?c3R1ZHlfcGs9MTIzNDU2Nzg5
// now fetch the images for that study

//Justification for using study iuid instead of study pk:
//From https://dicom.nema.org/dicom/2013/output/chtml/part05/chapter_9.html
//"Although a specific implementation may choose some particular structure for its generated UIDs, it should never assume that a UID carries any semantics. Thus, a UID shall not be "parsed" to find a particular value or component. Component definition (for the suffix) is implementation specific and may change as long as uniqueness is maintained. Parsing UIDs may jeopardize the ability to inter-operate as implementations evolve."

import { useState, useEffect, useCallback, useMemo } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { Buffer } from "buffer";
import querySearch from "query-string";
import { toast } from "react-toastify";
import { getStudyByPk } from "services/studies/studies";
import { DefaultSpinner } from "components/loading/DefaultSpinner";

export const DicomRedirect = () => {
  const { error, redirectUrl } = useDicomRedirectDetails();

  if (redirectUrl) {
    return <Navigate to={`${redirectUrl}`} />;
  } else if (error) {
    return <Navigate to="/error" />;
  }

  return <DefaultSpinner spinnerSize={40} />;
};

const useDicomRedirectDetails = () => {
  const [queryParameters] = useSearchParams();
  const studyPk = useMemo(() => {
    const encodedParams = queryParameters.toString();
    const buffer = Buffer.from(encodedParams, "base64");
    const decodedParameterString = buffer.toString("utf8");
    const decodedParams = querySearch.parse(decodedParameterString);
    return decodedParams.study_pk;
  }, [queryParameters]);

  const [studyIuid, setStudyIuid] = useState(undefined);
  const [error, setError] = useState(false);

  const redirectUrl = useMemo(() => {
    if (!studyIuid || !studyPk) {
      return null;
    }
    const queryParams = btoa(`pkid=${studyPk}&pkuid=${studyIuid}`);
    return `/viewer?${queryParams}`;
  }, [studyPk, studyIuid]);
  const getStudyIuid = useCallback(async () => {
    const result = await getStudyByPk(studyPk);
    if (result && result[0]) {
      const study = result[0];

      setStudyIuid(study.study_iuid);
    } else {
      toast.error("Unable to get study iuid for this study");
      setError(true);
    }
  }, [studyPk]);

  useEffect(() => {
    if (studyPk) {
      getStudyIuid();
    }
  }, [studyPk, getStudyIuid]);

  return { redirectUrl, error };
};
