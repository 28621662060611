import cornerstoneTools from "cornerstone-tools";
import Drawing from "../api/Drawing";
import Util from "../api/Util";
import { MEASUREMENT_SUFFIXES, TOOL_IDS } from "../consts/tools.consts";
import {
  getAxisLengths,
  getDimensionData,
  getDisplayedMeasurement,
  getTextBoxAnchorPoints,
} from "./measurementToolUtils";
import { DEFAULT_HANDLE } from "../consts/tools.defaults";
import { ExtendedAnnotationTool } from "../api/ExtendedAnnotationTool";
import { checkEventData } from "../functions/eventHandlingValidation";
import { ToolData } from "../tools.types";

const ELLIPTICAL_AREA_CONFIG = {
  drawHandles: true,
  renderDashed: true,
  digits: 2,
  drawHandlesOnHover: false,
  hideHandlesIfMoving: false,
};
export default class EllipticalArea extends ExtendedAnnotationTool {
  constructor() {
    super(TOOL_IDS.ELLIPTICAL_AREA);
  }
  createNewMeasurement = (eventData) => {
    const isEventDataValid = checkEventData(eventData);
    if (isEventDataValid) {
      const { x, y } = eventData.currentPoints.image;
      return {
        visible: true,
        active: true,
        color: undefined,
        invalidated: true,
        handles: {
          start: {
            x,
            y,
            highlight: true,
            active: false,
          },
          end: {
            x,
            y,
            highlight: true,
            active: true,
          },
          textBox: {
            active: false,
            hasMoved: false,
            movesIndependently: false,
            drawnIndependently: true,
            allowedOutsideImage: true,
            hasBoundingBox: true,
          },
        },
      };
    }
  };

  pointNearTool = (element, data, coords) => {
    const hasStartAndEndHandles =
      data && data.handles && data.handles.start && data.handles.end;
    const validParameters = hasStartAndEndHandles;

    if (!validParameters) {
      return false;
    }

    if (data.visible === false) {
      return false;
    }

    return (
      Util.lineSegDistance(
        element,
        data.handles.start,
        data.handles.end,
        coords
      ) < 25
    );
  };

  updateCachedStats(image, _element, data) {
    const {
      handles: { start, end },
    } = data;

    const { rowPixelSpacing, colPixelSpacing } = getDimensionData(
      image,
      this.imageMetaData
    );
    const { dx, dy } = getAxisLengths(
      start,
      end,
      colPixelSpacing,
      rowPixelSpacing
    );
    const majorRadius = Math.max(dx, dy) / 2;
    const minorRadius = Math.min(dx, dy) / 2;

    const area = majorRadius * minorRadius * Math.PI;

    data.area = area;
    data.suffix = MEASUREMENT_SUFFIXES.MM2;
    data.invalidated = false;
  }

  drawToolData(
    element: HTMLElement,
    context: CanvasRenderingContext2D,
    toolData: ToolData
  ): void {
    const { drawHandlesOnHover, hideHandlesIfMoving, drawHandles } =
      ELLIPTICAL_AREA_CONFIG;
    const { area, visible, suffix, handles } = toolData;
    if (visible !== false) {
      const color = cornerstoneTools.toolColors.getColorIfActive(toolData);
      const lineOptions = { color };
      Drawing.drawEllipse(
        context,
        element,
        handles.start,
        handles.end,
        lineOptions
      );

      const handleOptions = {
        ...DEFAULT_HANDLE,
        color,
        drawHandlesIfActive: drawHandlesOnHover,
        hideHandlesIfMoving,
      };
      if (drawHandles) {
        Drawing.drawHandles(context, { element }, handles, handleOptions);
      }

      if (!handles.textBox.hasMoved) {
        const coords = {
          x: Math.max(handles.start.x, handles.end.x),
          y: 0,
        };

        // Depending on which handle has the largest x-value,
        // Set the y-value for the text box
        if (coords.x === handles.start.x) {
          coords.y = handles.start.y;
        } else {
          coords.y = handles.end.y;
        }

        handles.textBox.x = coords.x;
        handles.textBox.y = coords.y;
      }

      // Move the textbox slightly to the right and upwards
      const xOffset = 10;
      const text = getDisplayedMeasurement(area, suffix);

      const lineWidth = 3;
      Drawing.drawLinkedTextBox(
        context,
        element,
        handles.textBox,
        text,
        handles,
        getTextBoxAnchorPoints,
        color,
        lineWidth,
        xOffset,
        true
      );
    }
  }
}
