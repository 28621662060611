export const areObjectsDifferent = (
  obj1: Record<string, unknown>,
  obj2: Record<string, unknown>
) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return true;
  }
  if (keys1.length && !keys1.every((key) => keys2.includes(key))) {
    return true;
  }
  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return true;
    }
  }
  return false;
};
