import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import { CustomFab } from "components/buttons/CustomFab";
import { PatientDetails } from "./PatientDetails";
import { ModelSelection } from "./ModelSelection";
import { DefaultSpinner } from "components/loading/DefaultSpinner";

export const XCaliberHeader = ({
  patientDetails,
  onSubmitButtonClick,
  isSubmissionDisabled,
  infoText,
  submissionInProgress,
  onDropdownChange,
  selectedDropdown,
}) => {
  const navigate = useNavigate();
  const navigateHome = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className="xcaliber-header">
      <CustomFab ariaLabel="back" onClick={navigateHome}>
        <>
          <FontAwesomeIcon icon={faBackward} style={{ marginRight: "15px" }} />
          BACK{" "}
        </>
      </CustomFab>
      <PatientDetails {...patientDetails} />
      <ModelSelection
        infoText={infoText}
        isSubmissionDisabled={isSubmissionDisabled}
        onSubmit={onSubmitButtonClick}
        onModelChanged={onDropdownChange}
        selectedModel={selectedDropdown}
      />

      {submissionInProgress && (
        <DefaultSpinner spinnerSize={60} displayText={"Analysing"} />
      )}
    </div>
  );
};
