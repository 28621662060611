import Drawing from "../api/Drawing";
import cornerstoneTools from "cornerstone-tools";
import { ToolData } from "../tools.types";
import cornerstone from "cornerstone-core";

export const renderSpinalLabels = (
  labelConfig,
  toolData: ToolData,
  context: CanvasRenderingContext2D,
  element: HTMLElement
) => {
  const { visible, value, handles } = toolData;
  if (visible) {
    const color = cornerstoneTools.toolColors.getColorIfActive(toolData);
    Drawing.draw(context, (context) => {
      Drawing.setShadow(context, labelConfig);
      const textCoords = cornerstone.pixelToCanvas(element, handles.end);
      const options = {
        centering: {
          x: true,
          y: true,
        },
      };
      handles.end.boundingBox = Drawing.drawTextBox(
        context,
        value,
        textCoords.x,
        textCoords.y - 10,
        color,
        options
      );
    });
  }
};
