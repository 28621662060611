import { INITIAL_MPR_COORDINATE } from "pages/viewer/dicomViewer.consts";
import { ViewportState } from "pages/viewer/dicomViewer.types";
import { DIRECTIONS } from "pages/viewer/tools/consts/tools.consts";

export const updateFrameIndexes = (
  viewportFrameIndexes: number[],
  viewports: ViewportState[],
  direction: string,
  isMPRActive: boolean
) => {
  if (!isMPRActive) {
    const updatedFrameIndexes = viewportFrameIndexes.map(
      (frameIndex, index) => {
        const totalFrameCount = viewports[index].totalFrames;
        return updateIndex(frameIndex, totalFrameCount, direction);
      }
    );
    return {
      updatedFrameIndexes,
      updatedMPRCoordinates: INITIAL_MPR_COORDINATE,
    };
  } else {
    const updatedFrameIndexes = viewportFrameIndexes.map(
      (frameIndex, index) => {
        const totalFrameCount = viewports[index].totalFrames;
        if (index !== 0) {
          return frameIndex;
        }
        return updateIndex(frameIndex, totalFrameCount, direction);
      }
    );
    const axialFrameIndex = updatedFrameIndexes[0];
    const updatedMPRCoordinates = { x: axialFrameIndex, y: axialFrameIndex };
    return { updatedFrameIndexes, updatedMPRCoordinates };
  }
};

const updateIndex = (
  currentIndex: number,
  totalFrameCount: number,
  direction: string
) => {
  const newIndex = currentIndex + (direction === DIRECTIONS.FORWARD ? 1 : -1);
  const updatedIndex =
    newIndex >= totalFrameCount
      ? 0
      : newIndex < 0
      ? totalFrameCount - 1
      : newIndex;
  return updatedIndex;
};
