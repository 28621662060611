import { useState } from "react";
import { Dialog } from "@mui/material";
import { PDFViewer } from "components/pdf-viewer/PDFViewer";

const PDF_GUIDE_URL = "../assets/documents/XCaliber-Guide.pdf";
const GUIDE_TITLE = "Guidebook X Caliber Vet Diagnostic Assistant";

export const XCaliberGuideDialog = () => {
  const [isGuideDialogOpen, toggleIsGuideDialogOpen] = useState<boolean>(false);
  const onClose = () => {
    toggleIsGuideDialogOpen(false);
  };
  return (
    <>
      <span>
        Please follow{" "}
        <a
          style={{
            textDecoration: "underline",
            fontWeight: "bold",
            cursor: "pointer",
          }}
          onClick={() => toggleIsGuideDialogOpen(!isGuideDialogOpen)}
        >
          this guide
        </a>{" "}
        for choosing and uploading images for optimal results
      </span>
      <Dialog maxWidth={"xl"} onClose={onClose} open={isGuideDialogOpen}>
        <PDFViewer pdfUrl={PDF_GUIDE_URL} pdfTitle={GUIDE_TITLE} />
      </Dialog>
    </>
  );
};
