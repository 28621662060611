import { postData } from "utils/networking/postData";

export const submitXCaliberRequest = async (
  userId: string,
  studyIuid: string,
  imageIuids: string,
  analysisModel: string
) => {
  try {
    const SUBMISSION_URI = "xcaliber/submission";
    const result = await postData({
      uri: SUBMISSION_URI,
      body: {
        userId,
        studyIuid,
        imageIuids,
        analysisModel,
      },
    });
    return result;
  } catch (error) {
    throw error;
  }
};

export const retrieveXcaliberResult = async (userId, requestId) => {
  const url = "xcaliber/retrieval";
  const result = await postData({
    uri: url,
    body: {
      userId,
      requestId,
    },
  });
  return result;
};

export async function retrieveXcaliberPdf(userId, requestId) {
  const url = "xcaliber/pdf";
  const result = postData({
    uri: url,
    body: { userId, requestId },
    parsedHeaders: { responseType: "blob" },
  });
  return result;
}

export const retrieveXcaliberStatus = async (userId: string) => {
  try {
    const url = "xcaliber/status";
    const result = await postData({
      uri: url,
      body: { userId },
    });
    return result;
  } catch (error) {
    throw error;
  }
};

export const retrieveXcaliberFullAnalysis = async ( userId: string, sopIuid: string, studyIuid: string, imageIuids) => {
  try {
    const url = "xcaliber/analysis";
    const result = await postData({
      uri: url,
      body: { userId, sopIuid, studyIuid, imageIuids }
    });
    return result;
  } catch (error) {
    console.log("RetrieveXcaliberFullAnalysis Error: ", error);
    throw error;
  }
};