import { Stack } from "@mui/material";
import { StudyImageData } from "../../dicomViewer.types";
import { PersistentDrawer } from "components/drawer/Drawer";
import { DRAWER_SIDES } from "components/drawer/drawer.consts";
import { ImageBarItem } from "./ImageBarItem";

interface DicomImageBarProps {
  activeImages: number[];
  setImageActive: (index: number) => void;
  studyImageData: StudyImageData[];
  onImageDrag: (cursorX: number, cursorY: number, imageIndex: number) => void;
}

export const DicomImageBar = ({
  activeImages,
  setImageActive,
  studyImageData,
  onImageDrag,
}: DicomImageBarProps) => {
  return (
    <PersistentDrawer drawerButtonTitle="Images" drawerSide={DRAWER_SIDES.LEFT}>
      <Stack
        style={{ marginTop: "1rem" }}
        direction="column"
        spacing={1}
        sx={{ px: 1, pt: 1 }}
      >
        {studyImageData.map(({ id, image }, index) => {
          const isActive = activeImages.includes(index);
          return (
            <ImageBarItem
              key={`image-item-${id}`}
              id={id}
              imageUrls={image}
              imageIndex={index}
              setImageActive={setImageActive}
              onImageDrag={onImageDrag}
              isActive={isActive}
            />
          );
        })}
      </Stack>
    </PersistentDrawer>
  );
};
