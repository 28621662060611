import { DicomDetails } from "../../../dicomViewer.types";
import { ReactNode } from "react";

export interface OverlayDetailsProps {
  isRetrievingImageMetaData?: boolean;
  details: DicomDetails;
  verticalPosition: string;
  horizontalPosition: string;
  children?: ReactNode;
}

export const OverlayDetails = ({
  details,
  verticalPosition,
  horizontalPosition,
  children,
}: OverlayDetailsProps) => (
  <div
    className={`viewer-overlay-details-container ${verticalPosition} ${horizontalPosition}`}
  >
    {children}
    {Object.keys(details).map((key) => (
      <div key={key}>
        <strong>{key}</strong>: {details[key]}
      </div>
    ))}
  </div>
);
