import { styled } from "@mui/system";
import { OverlayDetails } from "./overlay-details/OverlayDetails";
import { ImageViewportData } from "./overlay-details/ImageViewportData";
import { OverlayData } from "../../dicomViewer.types";
import {
  DICOM_DETAILS_HORIZONTAL_POSITIONS,
  DICOM_DETAILS_VERTICAL_POSITIONS,
} from "../../dicomViewer.consts";
import { ViewerStatusDetails } from "./overlay-details/ViewerStatusDetails";
import { useViewerContext } from "pages/viewer/context/viewer.context";
import { useMemo } from "react";
import {
  CORONAL_OVERLAY_DATA,
  SAGITTAL_OVERLAY_DATA,
} from "./viewerOverlay.consts";
import {
  formatStudyDateAndTime,
  getPhysicianData,
} from "./viewerOverlay.functions";

const OverlayContainer = styled("div")(() => ({
  position: "absolute",
  height: "100%",
  width: "100%",
  pointerEvents: "none",
}));

interface DicomViewerOverlayProps {
  viewportIndex: number;
}
export const DicomViewerOverlay = ({
  viewportIndex,
}: DicomViewerOverlayProps) => {
  const { topLeftDetails, bottomLeftDetails, bottomRightDetails } =
    useOverlayData(viewportIndex);

  return (
    <OverlayContainer>
      {topLeftDetails && (
        <OverlayDetails
          details={topLeftDetails}
          verticalPosition={DICOM_DETAILS_VERTICAL_POSITIONS.TOP}
          horizontalPosition={DICOM_DETAILS_HORIZONTAL_POSITIONS.LEFT}
        />
      )}

      <ViewerStatusDetails viewportIndex={viewportIndex} />
      {bottomLeftDetails && (
        <OverlayDetails
          details={bottomLeftDetails}
          verticalPosition={DICOM_DETAILS_VERTICAL_POSITIONS.BOTTOM}
          horizontalPosition={DICOM_DETAILS_HORIZONTAL_POSITIONS.LEFT}
        />
      )}
      {bottomRightDetails && <ImageViewportData details={bottomRightDetails} />}
    </OverlayContainer>
  );
};

const useOverlayData = (viewportIndex: number): OverlayData => {
  const {
    state: { isMPRActive, studyImageMetaData, studyImageData, viewports },
  } = useViewerContext();

  return useMemo(() => {
    if (isMPRActive && viewportIndex !== 0) {
      if (viewportIndex === 1) {
        return CORONAL_OVERLAY_DATA;
      } else {
        return SAGITTAL_OVERLAY_DATA;
      }
    }
    const { studyImageIndex } = viewports[viewportIndex];
    if (studyImageMetaData) {
      const viewportMetaData = studyImageMetaData[studyImageIndex];
      if (viewportMetaData) {
        return {
          topLeftDetails: {
            "Patient Name": viewportMetaData.patientName,
            "Patient Id": viewportMetaData.patientId,
            "Institution Name": viewportMetaData.institutionName,
            ...formatStudyDateAndTime(
              viewportMetaData.studyTime,
              viewportMetaData.studyDate
            ),
          },
          bottomLeftDetails: getPhysicianData(viewportMetaData),
          bottomRightDetails: {
            Image: `${studyImageIndex + 1}/${studyImageData.length}`,
            ...viewportMetaData,
          },
        };
      }
      return {
        topLeftDetails: {},
        bottomLeftDetails: {},
        bottomRightDetails: {
          Image: `${studyImageIndex + 1}/${studyImageData.length}`,
        },
      };
    }
    return {};
  }, [studyImageMetaData, viewportIndex, studyImageData, viewports]);
};
