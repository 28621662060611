import cornerstoneTools from "cornerstone-tools";
import Util from "../api/Util";
import { TOOL_IDS } from "../consts/tools.consts";
import { ExtendedAnnotationTool } from "../api/ExtendedAnnotationTool";
import { ToolData } from "../tools.types";
import { renderSpinalLabels } from "./spinalLabel.functions";

const THORATIC_CONFIGURATION = {
  markers: [
    "T1",
    "T2",
    "T3",
    "T4",
    "T5",
    "T6",
    "T7",
    "T8",
    "T9",
    "T10",
    "T11",
    "T12",
  ],
  current: "T1",
  ascending: true,
  loop: true,
};
const LumbarTool = cornerstoneTools.TextMarkerTool;

export default class ThoraticLabel extends ExtendedAnnotationTool {
  hasIncomplete: boolean;
  counter: number;
  constructor() {
    super(TOOL_IDS.THORATIC_LABEL);
    this.hasIncomplete = false;
    this.counter = -1;
  }

  activeCallback(_element) {
    cornerstoneTools.addTool(LumbarTool, { THORATIC_CONFIGURATION });
  }

  preMouseDownCallback(_event) {
    if (this.counter < THORATIC_CONFIGURATION.markers.length - 1) {
      this.counter += 1;
    } else {
      this.counter = 0;
    }
  }

  createNewMeasurement(event) {
    const goodEventData =
      event && event.currentPoints && event.currentPoints.image;
    if (!goodEventData) {
      // //console.log(
      //     `required eventData not supplied to tool ${this.name}'s createNewMeasurement`
      // );
      return;
    }

    const { x, y } = event.currentPoints.image;

    return {
      visible: true,
      active: true,
      color: "greenyellow",
      value: THORATIC_CONFIGURATION.markers[this.counter],
      invalidated: true,
      handles: {
        start: {
          x,
          y,
          highlight: true,
          active: false,
        },
        end: {
          x,
          y,
          highlight: true,
          active: false,
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }

  pointNearTool(element, data, coords) {
    const validParameters =
      data && data.handles && data.handles.start && data.handles.end;

    if (!validParameters) {
      // //console.log(
      //     `invalid parameters supplied to tool ${this.name}'s pointNearTool`
      // );
      return false;
    }

    if (data.visible === false) {
      return false;
    }

    if (this.hasIncomplete) {
      return false;
    }

    const line =
      Util.lineSegDistance(
        element,
        data.handles.start,
        data.handles.end,
        coords
      ) < 10;

    if (line) {
      data.handles.start.movesIndependently = false;
      data.handles.end.movesIndependently = false;
    }
    return line;
  }

  distanceFromPoint(event) {}

  drawToolData(
    element: HTMLElement,
    context: CanvasRenderingContext2D,
    toolData: ToolData
  ) {
    renderSpinalLabels(THORATIC_CONFIGURATION, toolData, context, element);
  }

  _changeText(evt) {
    const eventData = evt.detail;
    const { element, currentPoints } = eventData;
    let data;

    function doneChangingTextCallback(data, updatedText, deleteTool) {
      if (deleteTool === true) {
        cornerstoneTools.removeToolState(element, this.name, data);
      } else {
        data.text = updatedText;
      }

      data.active = false;
      cornerstoneTools.updateImage(element);
    }

    const config = THORATIC_CONFIGURATION;
    const coords = currentPoints.canvas;
    const toolData = cornerstoneTools.getToolState(element, this.toolId);

    // Now check to see if there is a handle we can move
    if (!toolData) {
      return;
    }

    for (let i = 0; i < toolData.data.length; i++) {
      data = toolData.data[i];
      if (this.pointNearTool(element, data, coords)) {
        data.active = true;
        cornerstoneTools.updateImage(element);

        // Allow relabelling via a callback
        // @ts-ignore
        config.changeTextCallback(data, eventData, doneChangingTextCallback);

        evt.stopImmediatePropagation();
        evt.preventDefault();
        evt.stopPropagation();

        return;
      }
    }
  }
}
