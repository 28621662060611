import { useMemo } from "react";
import { ToolSection } from "pages/viewer/dicomViewer.types";
import { ToolConfig } from "pages/viewer/tools/tools.types";
import { TOOL_BAR_CATEGORIES } from "pages/viewer/tools/consts/tools.consts";
import { useFramedModality } from "pages/viewer/hooks/useFramedModality";

export const useOrderTools = (
  enabledTools: ToolConfig[]
): ToolSection[] | null[] => {
  const { isFramedModality } = useFramedModality();
  return useMemo(() => {
    const artificialIntelligenceTools = [];
    const measurementTools = [];
    const defaultTools = [];
    const annotationTools = [];
    const manipulationTools = [];
    const spinalLabels = [];
    const framedModalityTools = [];
    enabledTools.forEach((tool) => {
      const { toolbarCategory } = tool;
      switch (toolbarCategory) {
        case TOOL_BAR_CATEGORIES.ARTIFICIAL_INTELLIGENCE:
          artificialIntelligenceTools.push(tool);
          break;
        case TOOL_BAR_CATEGORIES.MEASUREMENT:
          measurementTools.push(tool);
          break;
        case TOOL_BAR_CATEGORIES.SPINAL_LABELS:
          spinalLabels.push(tool);
          break;
        case TOOL_BAR_CATEGORIES.ANNOTATION:
          annotationTools.push(tool);
          break;
        case TOOL_BAR_CATEGORIES.MANIPULATION:
          manipulationTools.push(tool);
          break;
        case TOOL_BAR_CATEGORIES.FRAMED_MODALITIES:
          framedModalityTools.push(tool);
          break;
        default:
          defaultTools.push(tool);
      }
    });

    return [
      framedModalityTools.length && isFramedModality
        ? formatToToolSection(
            framedModalityTools,
            TOOL_BAR_CATEGORIES.FRAMED_MODALITIES
          )
        : null,
      artificialIntelligenceTools.length
        ? formatToToolSection(artificialIntelligenceTools, TOOL_BAR_CATEGORIES.ARTIFICIAL_INTELLIGENCE)
        : null,
      measurementTools.length
        ? formatToToolSection(measurementTools, TOOL_BAR_CATEGORIES.MEASUREMENT)
        : null,
      spinalLabels.length
        ? formatToToolSection(spinalLabels, TOOL_BAR_CATEGORIES.SPINAL_LABELS)
        : null,
      manipulationTools.length
        ? formatToToolSection(
            manipulationTools,
            TOOL_BAR_CATEGORIES.MANIPULATION
          )
        : null,
      annotationTools.length
        ? formatToToolSection(annotationTools, TOOL_BAR_CATEGORIES.ANNOTATION)
        : null,
      defaultTools.length
        ? formatToToolSection(defaultTools, "Miscellaneous")
        : null,
    ].flatMap((config) => config ?? []);
  }, [enabledTools, isFramedModality]);
};

const formatToToolSection = (orderedTools, sectionTitle) => ({
  firstColumn: orderedTools.slice(0, orderedTools.length / 3),
  secondColumn: orderedTools.slice(
    orderedTools.length / 3,
    (orderedTools.length / 3) * 2
  ),
  thirdColumn: orderedTools.slice(
    (orderedTools.length / 3) * 2,
    orderedTools.length
  ),
  sectionTitle,
});
