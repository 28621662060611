import { useUserType } from "hooks/useUserType";
import { useMemo } from "react";
import { getUserTypeColorName } from "utils/colors/userColors";

export const HomeIcon = () => {
  const logoUrl = useLogoUrl();
  return <img src={logoUrl} alt="ATX Logo" className="home-icon-image" />;
};

const useLogoUrl = () => {
  const { userTypeColour } = useUserType();
  return useMemo(() => {
    const rootUrl = "../assets/images/icons/home-icon/atx-logo";
    const typeSpecificImageSuffix = getUserTypeColorName(userTypeColour);
    return `${rootUrl}-${typeSpecificImageSuffix}.png`;
  }, [userTypeColour]);
};
