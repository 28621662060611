import { ToggleButtonGroup } from "@mui/material";
import { ToolButton } from "./tool-button/ToolButton";
import { REQUIRES_PARSED_METADATA, REQUIRES_XCALIBER_PARAMETERS } from "pages/viewer/tools/consts/tools.consts";
import { ToolConfig } from "pages/viewer/tools/tools.types";

interface ToolToggleColumnProps {
  handleToolSelect: (toolId: string) => void;
  tools: ToolConfig[];
  isLoadingMetadata: boolean;
  isLoadingXcaliber: boolean;
}
export const ToolToggleColumn = ({
  handleToolSelect,
  tools,
  isLoadingMetadata,
  isLoadingXcaliber,
}: ToolToggleColumnProps) => {
  return (
    <ToggleButtonGroup exclusive orientation="vertical">
      {tools.map(({ name, label, iconFileName }, index) => {
        const showLoadingMetaData =
          isLoadingMetadata && REQUIRES_PARSED_METADATA.includes(name ?? "");
        const showLoadingXcaliber = 
          isLoadingXcaliber && REQUIRES_XCALIBER_PARAMETERS.includes(name ?? "");

        return (
          <ToolButton
            key={`${name}-${index}`}
            name={name}
            label={label}
            iconFileName={iconFileName}
            showLoadingMetaData={showLoadingMetaData}
            handleToolSelect={handleToolSelect}
            showLoadingXcaliber={showLoadingXcaliber}
          />
        );
      })}
    </ToggleButtonGroup>
  );
};
