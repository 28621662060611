import cornerstone from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";
import { ALL_TOOLS, NON_ERASER_TOOLS, TOOL_IDS } from "../consts/tools.consts";
import { ExtendedAnnotationTool } from "../api/ExtendedAnnotationTool";
import { distance } from "../measurement-tools/curve-radius/curveRadiusFunctions";

export class EraserTool extends ExtendedAnnotationTool {
  constructor() {
    super(TOOL_IDS.ERASER);
    this.onToolActivation = this.onToolActivation.bind(this);
    this.onToolDeactivation = this.onToolDeactivation.bind(this);
  }

  public onToolActivation(element) {
    ALL_TOOLS.forEach((tool) => {
      if (!NON_ERASER_TOOLS.includes(tool.name)) {
        cornerstoneTools.setToolEnabledForElement(element, tool.name);
      }
    });
  }

  public onToolDeactivation(element) {
    ALL_TOOLS.forEach((tool) => {
      if (!NON_ERASER_TOOLS.includes(tool.name)) {
        cornerstoneTools.setToolPassiveForElement(element, tool.name, {
          mouseButtonMask: null,
        });
      }
    });
  }
  createNewMeasurement({ element, currentPoints }) {
    const { x: pointX, y: pointY } = currentPoints.image;
    const tools = ALL_TOOLS;
    tools.forEach((tool) => {
      const toolData = cornerstoneTools.getToolState(element, tool.name);
      if (toolData && toolData.data) {
        const toolStatesToRemove = toolData.data.flatMap((data) => {
          const { handles } = data;
          const handlesArray = Object.keys(handles).flatMap((key) => {
            const handleData = handles[key];
            if (handleData.x && handleData.y) {
              return handleData;
            }
            return [];
          });
          const shouldRemove = handlesArray.some(({ x, y }) => {
            const handleDistance = distance({ x: pointX, y: pointY }, { x, y });
            if (handleDistance < 30) {
              return true;
            }
            return false;
          });
          if (shouldRemove) {
            return data;
          }
          return [];
        });

        toolStatesToRemove.forEach((data) => {
          cornerstoneTools.removeToolState(element, tool.name, data);
        });
      }
    });

    cornerstone.updateImage(element);
  }
}
