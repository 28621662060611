import {
  DROPDOWN_FILTER_FIELDS,
  EMPTY_DROPDOWN_VALUES,
  FALSE_IS_NULL_FIELDS,
  STUDY_DATE_TIME_FILTERS,
} from "pages/home-page/components/study-table-controls/study-filter/studyFilter.consts";
import { convertStatusValueToId } from "pages/home-page/components/study-table/functions/convertStatusValueToId";
import { FIELD_KEYS } from "pages/home-page/components/study-table/study-table-columns/columns.consts";
import { encodeDateToHL7Date } from "utils/formatting/encodeHL7";
import { changeTimezoneToUtc, isDate } from "utils/formatting/formatValue";

export const formatFilterData = (filterData: Record<string, unknown>) =>
  Object.entries(filterData).reduce((acc, [key, value]) => {
    if (
      DROPDOWN_FILTER_FIELDS.includes(key) &&
      EMPTY_DROPDOWN_VALUES.includes(String(value))
    ) {
      return acc;
    }
    if (FALSE_IS_NULL_FIELDS.includes(key) && String(value) === "false") {
      acc[key] = null;
      return acc;
    }

    if (key === FIELD_KEYS.STATUS) {
      const statusId = convertStatusValueToId(value as string | undefined);
      if (statusId !== undefined) {
        acc[key] = statusId;
        return acc;
      }
      return acc;
    }

    if (isDate(value)) {
      if (STUDY_DATE_TIME_FILTERS.includes(key)) {
        const dateTimeObject = acc[FIELD_KEYS.STUDY_DATETIME] || {};
        const convertedDate = changeTimezoneToUtc(value as Date);
        dateTimeObject[key] = convertedDate.toISOString();
        acc[FIELD_KEYS.STUDY_DATETIME] = dateTimeObject;
      } else if (key === FIELD_KEYS.PAT_BIRTHDATE) {
        acc[key] = encodeDateToHL7Date(value as Date);
      } else {
        // @ts-ignore
        acc[key] = value.toISOString();
      }
      return acc;
    }

    if (String(value).length > 0 || !isNaN(Number(value))) {
      acc[key] = value;
    } else {
      console.warn(
        `value: ${value} has not been configured correctly see ${key}`
      );
    }
    return acc;
  }, {});
