import { OverlayDetails } from "./OverlayDetails";
import {
  DICOM_DETAILS_HORIZONTAL_POSITIONS,
  DICOM_DETAILS_VERTICAL_POSITIONS,
  VIEWPORT_STATUSES,
} from "pages/viewer/dicomViewer.consts";
import { DefaultSpinner } from "components/loading/DefaultSpinner";
import { useViewportStatus } from "pages/viewer/hooks/useViewportStatus";
import { SPINNER_SIZES } from "components/loading/defaultSpinner.consts";

interface ViewerStatusDetailsProps {
  viewportIndex: number;
}
export const ViewerStatusDetails = ({
  viewportIndex,
}: ViewerStatusDetailsProps) => {
  const { viewportStatus } = useViewportStatus(viewportIndex);

  return (
    <OverlayDetails
      details={{}}
      verticalPosition={DICOM_DETAILS_VERTICAL_POSITIONS.TOP}
      horizontalPosition={DICOM_DETAILS_HORIZONTAL_POSITIONS.RIGHT}
    >
      <div className="viewer-status-spinner">
        {viewportStatus && viewportStatus !== VIEWPORT_STATUSES.COMPLETE && (
          <DefaultSpinner
            displayText={viewportStatus}
            spinnerSize={SPINNER_SIZES.SMALL}
          />
        )}
      </div>
    </OverlayDetails>
  );
};
