import { useSearchParams } from "react-router-dom";
import { FIELD_KEYS } from "../../../study-table/study-table-columns/columns.consts";
import { STUDY_DATE_FILTERS } from "../studyFilter.consts";
import { convertStatusValueToId } from "../../../study-table/functions/convertStatusValueToId";
import { decodeHL7DateToDate } from "utils/formatting/decodeHL7";
import { useHomePageContext } from "pages/home-page/context/homepage.context";
import { useEffect, useMemo } from "react";
import { areObjectsDifferent } from "utils/validation/validation";

const FILTER_KEYS = {
  PAGE: "page",
  SORT_COL: "sortCol",
  SORT_DIR: "sortDir",
};

type FilterQueryParams = {
  filterData: Record<string, unknown>;
  page: number;
  sortColumn: string;
  sortDirection: string;
};
export const useFilterQueryParams = (): FilterQueryParams => {
  const {
    state: {
      filters,
      page,
      sortingColumn,
      sortingDirection,
      hasLoadedFirstPage,
      loadedFromUrl,
    },
    dispatch,
  } = useHomePageContext();
  const [queryParameters] = useSearchParams();
  const filterQueryParameterData = useMemo(
    () =>
      Array.from(queryParameters.entries()).reduce((acc, curr) => {
        const [key, value] = curr;
        switch (key) {
          case FILTER_KEYS.PAGE:
            acc["page"] = Math.max(Number(value) - 1, 0);
            break;
          case FILTER_KEYS.SORT_COL:
            acc["sortColumn"] = value;
            break;
          case FILTER_KEYS.SORT_DIR:
            acc["sortDirection"] = value;
            break;
          default:
            const filterData = acc["filterData"] ?? {};
            const filterValue = getFilterDataValue(key, value);
            if (filterValue !== undefined) {
              acc["filterData"] = {
                ...filterData,
                [key]: getFilterDataValue(key, value),
              };
            }
            break;
        }
        return acc;
      }, {}),
    [queryParameters]
  ) as FilterQueryParams;

  useEffect(() => {
    const {
      page: urlPage,
      filterData,
      sortColumn: urlSortColumn,
      sortDirection: urlSortDirection,
    } = filterQueryParameterData;

    const isPageChanged = urlPage !== page;
    const isSortChanged =
      urlSortColumn !== sortingColumn || urlSortDirection !== sortingDirection;
    const isFilterChanged =
      filterData !== undefined && areObjectsDifferent(filterData, filters);

    if ((isFilterChanged || isPageChanged || isSortChanged) && !loadedFromUrl) {
      dispatch({
        type: "CHANGE_SEARCH_PARAMS",
        payload: {
          page: urlPage,
          filters: filterData ?? {},
          sortingColumn: urlSortColumn,
          sortingDirection: urlSortDirection,
          loadedFromUrl: true,
        },
      });
    }
  }, [
    filterQueryParameterData,
    filters,
    page,
    sortingColumn,
    sortingDirection,
    dispatch,
    hasLoadedFirstPage,
    loadedFromUrl,
  ]);
  return {
    ...filterQueryParameterData,
    filterData: filterQueryParameterData["filterData"]
      ? filterQueryParameterData["filterData"]
      : {},
  };
};

const DATE_FIELDS = [
  FIELD_KEYS.PAT_BIRTHDATE,
  STUDY_DATE_FILTERS.STUDY_FROM,
  STUDY_DATE_FILTERS.STUDY_TO,
];

const BOOLEAN_FIELDS = [FIELD_KEYS.CLAIM_VERIFICATION, FIELD_KEYS.LOCK];
const getFilterDataValue = (key, value) => {
  if (DATE_FIELDS.includes(key)) {
    if (key === FIELD_KEYS.PAT_BIRTHDATE) {
      const decodedDateValue = decodeHL7DateToDate(value, true);
      return decodedDateValue;
    }
    return new Date(value);
  }
  if (key === FIELD_KEYS.STATUS) {
    const statusId = convertStatusValueToId(value as string | undefined);
    if (statusId === undefined) {
      return undefined;
    }
  }
  if (BOOLEAN_FIELDS.includes(key)) {
    if (value === "true") {
      return true;
    } else if (value === "false") {
      return false;
    }
    return undefined;
  }
  return value;
};
