import { DisplayedDicomMetaData } from "pages/viewer/dicomViewer.types";
import { decodeDateString } from "utils/formatting/decodeHL7";

export const getPhysicianData = (metadata: Partial<DisplayedDicomMetaData>) => {
  const physicianMetaData = {};
  if (metadata.consultingPhysiciansName) {
    physicianMetaData["Consulting Physician"] =
      metadata.consultingPhysiciansName;
  }
  if (metadata.performingPhysiciansName) {
    physicianMetaData["Performing Physician"] =
      metadata.performingPhysiciansName;
  }
  if (metadata.referringPhysiciansName) {
    physicianMetaData["Referring Physician"] = metadata.referringPhysiciansName;
  }
  return physicianMetaData;
};

export const formatStudyDateAndTime = (
  studyTime: string,
  studyDate: string
) => {
  const hour = studyTime.substring(0, 2);
  const min = studyTime.substring(2, 4);
  const sec = studyTime.substring(4, 6);

  // Construct the date string in the format DD/MM/YYYY
  const formattedDate = decodeDateString(studyDate);
  const formattedTime = `${hour}:${min}:${sec}`;
  return { "Study Date": formattedDate, "Study Time": formattedTime };
};
