import { useCallback } from "react";
import { FIELD_KEYS } from "../../../study-table-columns/columns.consts";
import {
  DOB_FORM,
  MEDICARE_FORM,
  PATIENT_NAME_FORM,
  SERVICES_FORM,
} from "../../studyTableRows.consts";
import { StudyData } from "../../../studyTable.types";
import { validateAllRequired } from "utils/validation/formValidation";

export const useFormValidation = (_studyData: StudyData, fieldName: string) => {
  return useCallback(
    (values: Record<string, string>) => {
      const errors: Record<string, string> = {};
      switch (fieldName) {
        case FIELD_KEYS.MEDICARE_NUMBER: {
          const allRequired = validateAllRequired(MEDICARE_FORM, values);
          const { irnNumber } = values;
          if (Number(irnNumber) === 0) {
            errors["irnNumber"] = "IRN cannot be zero";
          }
          return { ...allRequired, errors };
        }
        case FIELD_KEYS.SERVICES: {
          const allRequired = validateAllRequired(SERVICES_FORM, values);
          return allRequired;
        }
        case FIELD_KEYS.PAT_BIRTHDATE: {
          const allRequired = validateAllRequired(DOB_FORM, values);
          return allRequired;
        }
        case FIELD_KEYS.PAT_NAME: {
          const allRequired = validateAllRequired(PATIENT_NAME_FORM, values);
          return allRequired;
        }
      }
    },
    [fieldName]
  );
};
