import { ColumnConfig, RowConfig } from "components/table/table.types";
import { useAppContext } from "context/app.context";
import { useState, useCallback, useEffect } from "react";
import { getFieldValueComponent } from "./displayFieldValueComponent";
import { formatValue } from "utils/formatting/formatValue";
import { useHomePageContext } from "pages/home-page/context/homepage.context";
import { StudyData } from "../studyTable.types";
import { fetchStudyTableRowData } from "services/studies/studies";

export const useRowData = (
  columns: ColumnConfig[],
  editableColumns: string[]
) => {
  const {
    state: { userId },
  } = useAppContext();

  const {
    dispatch,
    state: {
      cachedStudyData,
      requiresReload,
      filters,
      page,
      sortingColumn,
      sortingDirection,
    },
  } = useHomePageContext();
  const [rowData, setRowData] = useState<RowConfig[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const fetchRowData = useCallback(async () => {
    setIsLoadingData(true);
    if (userId) {
      const studyDataResult = (await fetchStudyTableRowData(
        userId,
        page,
        sortingColumn,
        sortingDirection,
        filters
      )) as StudyData[];

      dispatch({
        type: "LOAD_PAGE_DATA",
        payload: { studyData: studyDataResult },
      });
      const formattedRowData = await formatRowData(
        studyDataResult,
        columns,
        editableColumns
      );
      setRowData(formattedRowData);
    }
    setIsLoadingData(false);
  }, [
    page,
    sortingColumn,
    sortingDirection,
    userId,
    setRowData,
    editableColumns,
    columns,
    filters,
    dispatch,
  ]);

  const getRowData = useCallback(async () => {
    if (cachedStudyData[page] && !requiresReload) {
      const formattedRowData = await formatRowData(
        cachedStudyData[page],
        columns,
        editableColumns
      );
      setRowData(formattedRowData);
    } else {
      fetchRowData();
    }
  }, [fetchRowData, setRowData, cachedStudyData, page, requiresReload]);

  useEffect(() => {
    getRowData();
  }, [fetchRowData]);

  return { rowData, isLoadingData };
};

const formatRowData = async (
  result: StudyData[],
  columns: ColumnConfig[],
  editableColumns: string[]
) => {
  if (!result) return [];
  return result.map((item, index) => {
    const dataFieldNames = Object.keys(item);
    const rowFields = columns.map(({ field }) => {
      const isEditable = editableColumns.includes(field);
      if (dataFieldNames.includes(field) && !isEditable) {
        const rawValue = item[field];
        const fieldValue = formatValue(rawValue, field);
        return { fieldValue };
      } else {
        const component = getFieldValueComponent(item, field, isEditable);
        return { fieldValue: field, component };
      }
    });
    return { rowFields, name: `row ${index}` };
  });
};
