import cornerstoneTools from "cornerstone-tools";
import cornerstone from "cornerstone-core";
import Drawing from "../api/Drawing";
import Util from "../api/Util";
import Manipulators from "../api/Manipulators";
import { TOOL_IDS } from "../consts/tools.consts";
import { getDimensionData } from "../measurement-tools/measurementToolUtils";
import { ExtendedAnnotationTool } from "../api/ExtendedAnnotationTool";
import { VertebralHeartScoreData } from "../tools.types";
import { DEFAULT_HANDLE } from "../consts/tools.defaults";

const VERTEBRAL_HEART_SCORE_CONFIG = {
  drawHandles: true,
  drawHandlesOnHover: false,
  hideHandlesIfMoving: false,
  renderDashed: false,
};
export class VertebralHeartScore extends ExtendedAnnotationTool {
  hasIncomplete: boolean;
  constructor() {
    super(TOOL_IDS.VERTEBRAL_HEART_SCORE);
    this.hasIncomplete = false;
  }
  createNewMeasurement(eventData) {
    // Create the measurement data for this tool with the end handle activated
    this.hasIncomplete = true;

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      complete: false,
      value: "",
      handles: {
        start: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        start2: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
          drawnIndependently: true,
        },
        end2: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
          drawnIndependently: true,
        },
        start3: {
          x: eventData.currentPoints.image.x + 100,
          y: eventData.currentPoints.image.y + 100,
          highlight: true,
          active: false,
          drawnIndependently: true,
        },
        end3: {
          x: eventData.currentPoints.image.x + 100,
          y: eventData.currentPoints.image.y + 100,
          highlight: true,
          active: false,
          drawnIndependently: true,
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: true,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
        textBox2: {
          active: false,
          hasMoved: false,
          movesIndependently: true,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }

  pointNearTool(element, data, coords) {
    if (data.visible === false) {
      return false;
    }

    if (this.hasIncomplete) {
      return false;
    }

    const seg1Near =
      Util.lineSegDistance(
        element,
        data.handles.start,
        data.handles.end,
        coords
      ) < 25;
    const seg2Near =
      Util.lineSegDistance(
        element,
        data.handles.start2,
        data.handles.end2,
        coords
      ) < 25;

    //const segMoveLine = Util.lineSegDistance(element, data.handles.start3, data.handles.end3, coords) < 25;

    if (seg1Near) {
      data.handles.start.movesIndependently = false;
      data.handles.end.movesIndependently = false;
      data.handles.start2.movesIndependently = true;
      data.handles.end2.movesIndependently = true;
    } else if (seg2Near) {
      data.handles.start.movesIndependently = true;
      data.handles.end.movesIndependently = true;
      data.handles.start2.movesIndependently = false;
      data.handles.end2.movesIndependently = false;
      // } else if (segMoveLine) {
      //     data.handles.start.movesIndependently = true;
      //     data.handles.start2.movesIndependently = true;
      //     data.handles.end.movesIndependently = true;
      //     data.handles.end2.movesIndependently = true;
      // }
    }

    return seg1Near || seg2Near;
  }

  updateCachedStats(image, element, data) {
    const { rowPixelSpacing, colPixelSpacing } = getDimensionData(
      image,
      this.imageMetaData
    );

    // Set rowPixelSpacing and columnPixelSpacing to 1 if they are undefined (or zero)
    const dx =
      (data.handles.end.x - data.handles.start.x) * (colPixelSpacing || 1);
    const dy =
      (data.handles.end.y - data.handles.start.y) * (rowPixelSpacing || 1);

    const dx1 =
      (data.handles.end2.x - data.handles.start2.x) * (colPixelSpacing || 1);
    const dy1 =
      (data.handles.end2.y - data.handles.start2.y) * (rowPixelSpacing || 1);

    // Calculate the length, and create the text variable with the millimeters or pixels suffix
    const length = Math.sqrt(dx * dx + dy * dy);
    const length2 = Math.sqrt(dx1 * dx1 + dy1 * dy1);

    data.length = length.toFixed(2);
    data.length2 = length2.toFixed(2);
    data.invalidated = false;
    data.suffix = "mm";
  }

  drawToolData(
    element: HTMLElement,
    context: CanvasRenderingContext2D,
    toolData: VertebralHeartScoreData
  ) {
    const { drawHandlesOnHover, hideHandlesIfMoving } =
      VERTEBRAL_HEART_SCORE_CONFIG;

    const lineWidth = cornerstoneTools.toolStyle.getToolWidth();

    const font = cornerstoneTools.textStyle.getFont();

    const { visible, value, value2, handles, complete, suffix } = toolData;
    if (visible) {
      Drawing.draw(context, (context) => {
        Drawing.setShadow(context, VERTEBRAL_HEART_SCORE_CONFIG);

        // Differentiate the color of activation tool
        const color = cornerstoneTools.toolColors.getColorIfActive(toolData);
        const textBoxAnchorPoints = (handles) =>
          findTextBoxAnchorPoints(handles.start, handles.end);
        const textBoxAnchorPoints2 = (handles) =>
          findTextBoxAnchorPoints(handles.start2, handles.end2);

        const lineOptions = { color };

        Drawing.drawLine(
          context,
          element,
          handles.start,
          handles.end,
          lineOptions
        );

        if (complete) {
          Drawing.drawLine(
            context,
            element,
            handles.start2,
            handles.end2,
            lineOptions
          );
          const lengthX = Math.abs(handles.start.x - handles.end.x);
          const lengthY = Math.abs(handles.start.y - handles.end.y);
          const lengthOX = handles.end2.x - handles.start2.x;
          const lengthOY = handles.end2.y - handles.start2.y;

          if (lengthOY !== lengthY || lengthOX !== lengthX) {
            handles.start.x = handles.end.x + lengthX;
            const cordsEnd = {
              active: false,
              drawnIndependently: false,
              highlight: true,
              x: handles.start3.x + lengthOY,
              y: handles.start3.y + lengthOX,
            };
            Drawing.drawLine(
              context,
              element,
              handles.start3,
              cordsEnd,
              lineOptions
            );
          }
        }

        // Draw the handles
        const handleOptions = {
          ...DEFAULT_HANDLE,
          color,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };

        if (VERTEBRAL_HEART_SCORE_CONFIG.drawHandles) {
          Drawing.drawHandles(context, { element }, handles, handleOptions);
        }

        // Draw the text
        context.fillStyle = color;

        if (!handles.textBox.hasMoved) {
          const textCoords = {
            x: (handles.start.x + handles.end.x) / 2,
            y: (handles.start.y + handles.end.y) / 2 - 10,
          };

          context.font = font;
          handles.textBox.x = textCoords.x;
          handles.textBox.y = textCoords.y;
        }

        if (!handles.textBox2.hasMoved) {
          const textCoords = {
            x: (handles.start2.x + handles.end2.x) / 2,
            y: (handles.start2.y + handles.end2.y) / 2 - 10,
          };

          context.font = font;
          handles.textBox2.x = textCoords.x;
          handles.textBox2.y = textCoords.y;
        }
        if (value !== undefined) {
          const textBoxValue1 = `${value}${suffix}`;
          Drawing.drawLinkedTextBox(
            context,
            element,
            handles.textBox,
            textBoxValue1,
            handles,
            textBoxAnchorPoints,
            color,
            lineWidth,
            0,
            true
          );
        }

        if (complete) {
          const textBoxValue2 = `${value2}${suffix}`;
          Drawing.drawLinkedTextBox(
            context,
            element,
            handles.textBox2,
            textBoxValue2,
            handles,
            textBoxAnchorPoints2,
            color,
            lineWidth,
            10,
            true
          );
        }
      });
    }
  }

  getIncomplete(element) {
    const toolState = cornerstoneTools.getToolState(element, this.toolId);

    if (toolState && Array.isArray(toolState.data)) {
      return toolState.data.find(({ complete }) => complete === false);
    }
  }

  addNewMeasurement(evt, interactionType) {
    evt.preventDefault();
    evt.stopPropagation();

    const eventData = evt.detail;

    let measurementData;
    let toMoveHandle;
    let doneMovingCallback = (success) => {
      // DoneMovingCallback for first measurement.
      if (!success) {
        cornerstoneTools.removeToolState(element, this.toolId, measurementData);

        return;
      }
      const eventType = cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED;
      const eventData = {
        toolName: this.toolId,
        toolType: this.toolId, // Deprecation notice: toolType will be replaced by toolName
        element,
        measurementData,
      };

      Util.triggerEvent(element, eventType, eventData);
    };

    // Search for incomplete measurements
    const element = evt.detail.element;
    const pendingMeasurement = this.getIncomplete(element);

    if (pendingMeasurement) {
      measurementData = pendingMeasurement;
      measurementData.complete = true;
      measurementData.handles.start2 = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: false,
      };
      measurementData.handles.end2 = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: true,
      };
      measurementData.handles.start3 = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: true,
      };
      measurementData.handles.end3 = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: true,
      };
      toMoveHandle = measurementData.handles.end2;
      this.hasIncomplete = false;
      doneMovingCallback = (success) => {
        // DoneMovingCallback for second measurement
        if (!success) {
          cornerstoneTools.removeToolState(
            element,
            this.toolId,
            measurementData
          );

          return;
        }

        const eventType = cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED;
        const eventData = {
          toolName: this.toolId,
          toolType: this.toolId, // Deprecation notice: toolType will be replaced by toolName
          element,
          measurementData,
        };

        Util.triggerEvent(element, eventType, eventData);
      };
    } else {
      measurementData = this.createNewMeasurement(eventData);
      cornerstoneTools.addToolState(element, this.toolId, measurementData);
      toMoveHandle = measurementData.handles.end;
    }

    // Associate this data with this imageId so we can render it and manipulate it
    cornerstone.updateImage(element);

    Manipulators.moveNewHandle(
      eventData,
      this.toolId,
      measurementData,
      toMoveHandle,
      {},
      interactionType,
      doneMovingCallback
    );
  }

  activeCallback(element) {
    //console.log("ACTIVE CALLBACK");
    this.onMeasureModified = this.onMeasureModified.bind(this);
    element.addEventListener(
      cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }

  passiveCallback(element) {
    //console.log("PASSIVE CALLBACK");
    this.onMeasureModified = this.onMeasureModified.bind(this);
    element.addEventListener(
      cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }

  enabledCallback(element) {
    //console.log("ENABLED CALLBACK");
    element.removeEventListener(
      cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }

  disabledCallback(element) {
    //console.log("DISABLED CALLBACK");
    element.removeEventListener(
      cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }

  onMeasureModified(ev) {
    //console.log("ON MEASURE MODIFIED");
    const { element } = ev.detail;
    const image = cornerstone.getEnabledElement(element).image;
    const { rowPixelSpacing, colPixelSpacing } = Util.getPixelSpacing(image);

    if (ev.detail.toolName !== this.toolId) {
      return;
    }
    const data = ev.detail.measurementData;

    // Update textbox stats
    if (data.invalidated === true) {
      if (data.length) {
        this.throttledUpdateCachedStats(image, element, data);
      } else {
        this.updateCachedStats(image, element, data);
      }
    }

    data.value = data.length;
    data.value2 = data.length2;
  }
}

function findTextBoxAnchorPoints(startHandle, endHandle) {
  return [
    {
      x: startHandle.x,
      y: startHandle.y,
    },
    {
      x: endHandle.x,
      y: endHandle.y,
    },
  ];
}
