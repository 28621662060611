import { ActionButton } from "components/buttons/ActionButton";
import { XCaliberGuideDialog } from "./XCaliberGuideDialog";
import { TableDropdown } from "components/dropdown/TableDropdown";
import { useUserType } from "hooks/useUserType";

export const ModelSelection = ({
  onModelChanged,
  selectedModel,
  onSubmit,
  isSubmissionDisabled,
  infoText,
}) => {
  const { userColorClass } = useUserType();
  return (
    <div className={`xcaliber-model-selection header-container `}>
      <span className={`title  ${userColorClass}`}>XCaliber AI</span>
      <XCaliberGuideDialog />
      <span className="info-text">{infoText}</span>
      <div className="model-selection-input">
        <SelectionDropdown onSelection={onModelChanged} value={selectedModel} />
        <ActionButton
          disabled={isSubmissionDisabled}
          title={
            isSubmissionDisabled
              ? "Select images to analyze first"
              : "Submit for analysis"
          }
          onClick={onSubmit}
        >
          SUBMIT
        </ActionButton>
      </div>
    </div>
  );
};

const MODEL_SELECTION_OPTIONS = [
  { value: "01|vet_vhs", label: "DOG VHS" },
  { value: "01|vet_msk", label: "DOG MSK" },
  { value: "01|vet_thorax", label: "DOG THORAX" },
  { value: "01|vet_canine_abdomen", label: "DOG ABDOMEN" },
  { value: "01|vet_canine_vlas", label: "DOG VLAS" },
  { value: "02|vet_vhs", label: "CAT VHS" },
  { value: "02|vet_feline_thorax", label: "CAT THORAX" },
  { value: "02|vet_feline_abdomen", label: "CAT ABDOMEN" },
  { value: "02|vet_feline_ctr", label: "CAT CTR" },
];

const SelectionDropdown = ({ onSelection, value }) => {
  return (
    <TableDropdown
      title={""}
      value={value}
      optionsConfig={MODEL_SELECTION_OPTIONS}
      onChange={onSelection}
    />
  );
};
