import { VIEWPORT_STATUSES } from "pages/viewer/dicomViewer.consts";
import { useViewportStatus } from "pages/viewer/hooks/useViewportStatus";
import { useEffect } from "react";
import cornerstone from "cornerstone-core";
import { INITIAL_RENDER_TOOLS } from "../consts/tools.consts";
import cornerstoneTools from "cornerstone-tools";

export const useRenderToolsOnComplete = (viewportIndex: number) => {
  const { viewportStatus } = useViewportStatus(viewportIndex);
  useEffect(() => {
    if (viewportStatus === VIEWPORT_STATUSES.COMPLETE) {
      const enabledElementsArray = cornerstone.getEnabledElements();
      enabledElementsArray.forEach(({ element }) => {
        // get element for viewport
        INITIAL_RENDER_TOOLS.forEach((toolId) => {
          const toolInstance = cornerstoneTools.getToolForElement(
            element,
            toolId
          );
          if (toolInstance && toolInstance.initialRenderForElement) {
            toolInstance.initialRenderForElement(element);
            cornerstoneTools.setToolPassiveForElement(element, toolId, {
              mouseButtonMask: null,
            });
          }
        });
      });
    }
  }, [viewportStatus]);
};
