import { useCallback, useEffect, useState } from "react";
import { useViewerContext } from "../context/viewer.context";
import { useViewportStatus } from "./useViewportStatus";
import { VIEWPORT_STATUSES } from "../dicomViewer.consts";

export const useOnViewportInitialized = (viewportIndex: number) => {
  const {
    state: { isMPRActive },
  } = useViewerContext();
  const { viewportStatus, setViewportStatus } =
    useViewportStatus(viewportIndex);

  const [isViewportInitialized, setIsViewportInitialized] =
    useState<boolean>(false);

  useEffect(() => {
    if (viewportStatus === VIEWPORT_STATUSES.INITIALIZING) {
      setIsViewportInitialized(false);
    }
  }, [viewportStatus, setIsViewportInitialized]);

  useEffect(() => {
    if (isViewportInitialized) {
      if (isMPRActive && viewportIndex !== 0) {
        setViewportStatus(VIEWPORT_STATUSES.LOADING_AXIAL_IMAGES);
      } else {
        setViewportStatus(VIEWPORT_STATUSES.FETCHING_METADATA);
      }
    }
  }, [isMPRActive, viewportIndex, isViewportInitialized]);

  return useCallback(() => {
    setIsViewportInitialized(true);
  }, [setIsViewportInitialized]);
};
