import { useEffect } from "react";
import cornerstoneTools from "cornerstone-tools";
import cornerstone from "cornerstone-core";
import { REQUIRES_XCALIBER_PARAMETERS } from "../consts/tools.consts";
import { MIN_CLIENT_WIDTH_PX } from "pages/viewer/dicomViewer.consts";
import { XcaliberAnalysis } from "pages/viewer/dicomViewer.types";
import { useImageMetaData } from "pages/viewer/hooks/metadata/useImageMetaData";

const getObjectUIDFromImageId = (imageId) => {
  const urlParams = new URLSearchParams(imageId.split("?")[1]);
  return urlParams.get("objectUID");
};

export const useParseXcaliberAnalysis = (
  xcaliberAnalysis: XcaliberAnalysis | null
) => {
  const { imageMetaData } = useImageMetaData();
  useEffect(() => {
    if (xcaliberAnalysis) {
      const enabledElementsArray = cornerstone.getEnabledElements();
      enabledElementsArray.forEach((enabledElement) => {
        if (enabledElement.element.clientWidth > MIN_CLIENT_WIDTH_PX) {
          const enabledElementImageIuid = enabledElement?.image
            ? getObjectUIDFromImageId(enabledElement.image.imageId)
            : null;
          if (
            enabledElementImageIuid &&
            enabledElementImageIuid === xcaliberAnalysis.sopIuid
          ) {
            REQUIRES_XCALIBER_PARAMETERS.forEach((toolType) => {
              try {
                const toolInstance = cornerstoneTools.getToolForElement(
                  enabledElement.element,
                  toolType
                );
                if (toolInstance && toolInstance.setXcaliberAnalysis) {
                  toolInstance.setXcaliberAnalysis(xcaliberAnalysis);
                }
              } catch (error) {
                console.error(error);
                console.warn(
                  `Error trying to parse xcaliber analysis for ${toolType}`
                );
              }
            });
          }
        }
      });
    }
  }, [xcaliberAnalysis, imageMetaData]);
};
