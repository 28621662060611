import { fetchData } from "utils/networking/fetchData";
import { postData } from "utils/networking/postData";

export const updatePatientValues = async (
  patientpk: string,
  updatedFields: Record<string, string | boolean | number>
) => {
  const UPDATE_STUDY_URL = "patients/update-patient";
  const result = await postData({
    uri: UPDATE_STUDY_URL,
    body: {
      patientpk,
      updatedFields,
    },
  });
  return result as { success: boolean; message?: string };
};

export const getPatientsByPk = async (patientPk) => {
  const URL = `patients/patientbypk/${patientPk}`;
  const result = await fetchData(URL, true);
  return result;
};
