import { DicomDetails } from "pages/viewer/dicomViewer.types";
import { OverlayDetails, OverlayDetailsProps } from "./OverlayDetails";
import { useFramedModality } from "pages/viewer/hooks/useFramedModality";
import { useMemo } from "react";
import {
  DICOM_DETAILS_HORIZONTAL_POSITIONS,
  DICOM_DETAILS_VERTICAL_POSITIONS,
} from "pages/viewer/dicomViewer.consts";

export const ImageViewportData = ({
  details,
}: Partial<OverlayDetailsProps>) => {
  const viewportDetails = useViewportDetails(details);
  return (
    <OverlayDetails
      details={viewportDetails}
      verticalPosition={DICOM_DETAILS_VERTICAL_POSITIONS.BOTTOM}
      horizontalPosition={DICOM_DETAILS_HORIZONTAL_POSITIONS.RIGHT}
    />
  );
};

const useViewportDetails = (details: DicomDetails) => {
  const { isFramedModality, viewportFrameIndexes, totalFrames } =
    useFramedModality();

  return useMemo(
    () => ({
      Image: details.Image,
      ...(isFramedModality
        ? { Frames: `${viewportFrameIndexes[0] + 1}/${totalFrames}` }
        : {}),
    }),
    [isFramedModality, viewportFrameIndexes, totalFrames, details]
  );
};
