import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Container, CssBaseline, Grid } from "@mui/material";
import { Navigate } from "react-router-dom";
import { motion } from "framer-motion";
import { DicomPageLoader } from "./components/dicom-page-loader/DicomPageLoader";
import { useStudyImageData } from "./hooks/useStudyImageData";
import { DicomViewerOverlay } from "./components/dicom-viewer-overlay/DicomViewerOverlay";
import { DicomViewerToolbar } from "./components/dicom-viewer-toolbar/DicomViewerToolbar";
import { DicomImageBar } from "./components/dicom-image-bar/DicomImageBar";
import { useDicomViewports } from "./hooks/useDicomViewports";
import { useGridControllerActions } from "./components/dicom-grid-controller/useGridControllerActions";
import { useScrollImageStack } from "./tools/default-tools/scroll-stack/useScrollImageStack";
import { useSavedAnnotations } from "./tools/default-tools/save-annotations/useSavedAnnotations";
import { useTools } from "./tools/hooks/useTools";
import { ViewerHeaderBar } from "./components/viewer-header-bar/ViewerHeaderBar";
import { useViewerContext, ViewerProvider } from "./context/viewer.context";
import { useFramedModality } from "./hooks/useFramedModality";
import { usePlayPauseFrames } from "./tools/framed-modality-tools/usePlayFrames";
import { useMPRTool } from "./tools/mpr-tool/useMPRTool";
import CornerstoneViewport from "./components/cornerstone-viewport/CornerstoneViewport";
import { useFetchMetaData } from "./hooks/metadata/useFetchMetaData";
import { useViewportStatus } from "./hooks/useViewportStatus";
import { VIEWPORT_STATUSES } from "./dicomViewer.consts";
import { useOnViewportInitialized } from "./hooks/useOnViewportInitialized";
import { useRetrieveXcaliberAnalysis } from "./hooks/xcaliber/useRetrieveXcaliberAnalysis";

export const DicomViewer = () => {
  return (
    <ViewerProvider>
      <Viewer />
    </ViewerProvider>
  );
};

const Viewer = () => {
  const [queryParameters] = useSearchParams();

  const { studyPk, studyIuid } = useMemo(() => {
    const encodedParams = queryParameters.entries().next().value[0];
    const decodedParams = atob(encodedParams);
    const urlSearchParams = new URLSearchParams(decodedParams);
    const studyPk = urlSearchParams.get("pkid");
    const studyIuid = urlSearchParams.get("pkuid");
    return { studyPk, studyIuid };
  }, [queryParameters]);

  const {
    state: { studyImageData },
  } = useViewerContext();
  const { isLoading } = useStudyImageData(studyPk, studyIuid);

  const { viewports, onChangeActiveViewport, changeViewportDataOnDrag } =
    useDicomViewports();

  const { onToolButtonClick, addToolCallback } = useTools();

  const { gridDimensions } = useGridControllerActions();

  const { isFramedModality } = useFramedModality();
  useScrollImageStack(studyImageData?.length ?? 0, onChangeActiveViewport);

  useMPRTool();
  const { isRetrievingXcaliberData } =
    useRetrieveXcaliberAnalysis(studyImageData);

  if (studyImageData === null && !isLoading) {
    return <Navigate to="/error" />;
  }
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.25,
      }}
      exit={{ opacity: 0 }}
    >
      <Box sx={{ display: "flex", backgroundColor: "black" }}>
        <CssBaseline />
        <ViewerHeaderBar />
        {isLoading ? (
          <DicomPageLoader />
        ) : (
          <Container maxWidth={false} sx={{ mt: 6, backgroundColor: "black" }}>
            <DicomViewerToolbar
              setActiveTool={onToolButtonClick}
              isLoadingXcaliber={isRetrievingXcaliberData}
            />
            <DicomImageBar
              activeImages={viewports.map(
                ({ studyImageIndex }) => studyImageIndex
              )}
              studyImageData={studyImageData}
              setImageActive={onChangeActiveViewport}
              onImageDrag={changeViewportDataOnDrag}
            />

            <Grid container spacing={0}>
              {viewports.map(({ imageIds, images, frames }, index) => {
                const viewerImageIds =
                  isFramedModality && frames ? frames : imageIds;

                return (
                  <Grid item key={index} xs={12 / gridDimensions[0]}>
                    <DicomImageViewport
                      key={`${viewports.length}-viewport-${index}`}
                      viewerImageIds={viewerImageIds}
                      images={images}
                      viewportIndex={index}
                      addToolCallback={addToolCallback}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        )}
      </Box>
    </motion.div>
  );
};

const DicomImageViewport = ({
  viewerImageIds,
  images,
  viewportIndex,
  addToolCallback,
}) => {
  const isPlaying = usePlayPauseFrames();
  const onImageLoadComplete = useOnViewportInitialized(viewportIndex);
  useFetchMetaData(viewportIndex);
  useSavedAnnotations(viewportIndex);
  return (
    <CornerstoneViewport
      imageIds={viewerImageIds}
      loadedImages={images}
      isPlaying={isPlaying}
      viewportIndex={viewportIndex}
      registerToolsToViewport={addToolCallback}
      onImageLoadComplete={onImageLoadComplete}
      viewportOverlayComponent={() => (
        <DicomViewerOverlay viewportIndex={viewportIndex} />
      )}
    />
  );
};
