import cornerstoneTools from "cornerstone-tools";
import Drawing from "../api/Drawing";
import Util from "../api/Util";
import cornerstone, { PixelCoordinate } from "cornerstone-core";
import { TOOL_IDS } from "../consts/tools.consts";
import { getDimensionData } from "../measurement-tools/measurementToolUtils";
import { ExtendedAnnotationTool } from "../api/ExtendedAnnotationTool";
import { ToolData } from "../tools.types";
import { DEFAULT_HANDLE } from "../consts/tools.defaults";

export class GeorgesLine extends ExtendedAnnotationTool {
  hasIncomplete: boolean;
  constructor() {
    super(TOOL_IDS.GEORGES_LINE);
    this.hasIncomplete = false;
  }
  activeCallback(element) {}
  preMouseDownCallback(event) {}
  createNewMeasurement(event) {
    const goodEventData =
      event && event.currentPoints && event.currentPoints.image;
    if (!goodEventData) {
      return;
    }

    const { x, y } = event.currentPoints.image;

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,

      complete: false,

      handles: {
        start: {
          x,
          y,
          highlight: true,
          active: false,
        },
        end: {
          x,
          y,
          highlight: true,
          active: false,
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }

  pointNearTool(element, data, coords) {
    const validParameters =
      data && data.handles && data.handles.start && data.handles.end;

    if (!validParameters) {
      // //console.log(
      //     `invalid parameters supplied to tool ${this.name}'s pointNearTool`
      // );
      return false;
    }

    if (data.visible === false) {
      return false;
    }

    if (this.hasIncomplete) {
      return false;
    }

    const line =
      Util.lineSegDistance(
        element,
        data.handles.start,
        data.handles.end,
        coords
      ) < 10;

    if (line) {
      data.handles.start.movesIndependently = false;
      data.handles.end.movesIndependently = false;
    }
    return line;
  }

  distanceFromPoint(event) {}
  updateCachedStats(image, element, data) {
    const { rowPixelSpacing, colPixelSpacing } = getDimensionData(
      image,
      this.imageMetaData
    );
    data.rowPixelSpacing = rowPixelSpacing;
    data.colPixelSpacing = colPixelSpacing;
    data.suffix = "mm";
  }
  drawToolData(
    element: HTMLElement,
    context: CanvasRenderingContext2D,
    toolData: ToolData
  ) {
    // We have tool data for this element - iterate over each one and draw it
    const { handles, visible, rowPixelSpacing, suffix } = toolData;
    if (visible) {
      const textBoxAnchorPoints = (handles) => textBoxAnchorPointsLine(handles);
      const firstPointX = {
        x: handles.end.x,
        y: handles.end.y,
      };
      const secondPointX = {
        x: handles.start.x,
        y: handles.end.y,
      };

      const textCoordsStart = cornerstone.pixelToCanvas(
        element,
        firstPointX as PixelCoordinate
      );
      const textCoordsEnd = cornerstone.pixelToCanvas(
        element,
        secondPointX as PixelCoordinate
      );

      const dist = (
        distance(handles.start.x, handles.end.x) * rowPixelSpacing
      ).toFixed(2);

      Drawing.drawArrow(context, textCoordsStart, textCoordsEnd, "red", 1);
      Drawing.draw(context, (context) => {
        const handleOptions = DEFAULT_HANDLE;
        Drawing.drawHandles(context, { element }, handles, handleOptions);

        Drawing.drawLinkedTextBox(
          context,
          element,
          handles.textBox,
          dist + suffix,
          handles,
          textBoxAnchorPoints,
          "red",
          2,
          true
        );
      });
    }
  }
}

function textBoxAnchorPointsLine(handles) {
  return [handles.start, handles.end];
}

const distance = (A, B) => {
  if (A > B) {
    let dist = A - B;
    return dist;
  } else {
    let dist = B - A;
    return dist;
  }
};
