import cornerstoneTools from "cornerstone-tools";
import Drawing from "../api/Drawing";
import Util from "../api/Util";
import { MEASUREMENT_SUFFIXES, TOOL_IDS } from "../consts/tools.consts";
import {
  getAxisLengths,
  getDimensionData,
  getDisplayedMeasurement,
  getTextBoxAnchorPoints,
} from "./measurementToolUtils";
import { ExtendedAnnotationTool } from "../api/ExtendedAnnotationTool";
import { DEFAULT_HANDLE } from "../consts/tools.defaults";

const RECTANGULAR_AREA_CONFIG = {
  drawHandles: true,
  drawHandlesOnHover: false,
  hideHandlesIfMoving: false,
  renderDashed: true,
  digits: 2,
};

export default class RectangularArea extends ExtendedAnnotationTool {
  throttledUpdateCachedStats: any;
  constructor() {
    super(TOOL_IDS.RECTANGULAR_AREA);
    this.throttledUpdateCachedStats = Util.throttle(
      this.updateCachedStats,
      110
    );
  }

  createNewMeasurement = (eventData) => {
    const goodEventData =
      eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      return;
    }

    const { x, y } = eventData.currentPoints.image;

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      handles: {
        start: {
          x,
          y,
          highlight: true,
          active: false,
        },
        end: {
          x,
          y,
          highlight: true,
          active: true,
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  };

  pointNearTool = (element, data, coords) => {
    const hasStartAndEndHandles =
      data && data.handles && data.handles.start && data.handles.end;
    const validParameters = hasStartAndEndHandles;

    if (!validParameters) {
      // //console.log(
      //     `invalid parameters supplied to tool ${this.name}'s pointNearTool`
      // );

      return false;
    }

    if (data.visible === false) {
      return false;
    }

    return (
      Util.lineSegDistance(
        element,
        data.handles.start,
        data.handles.end,
        coords
      ) < 25
    );
  };

  updateCachedStats(image, _element, data) {
    const {
      handles: { start, end },
    } = data;
    const { rowPixelSpacing, colPixelSpacing } = getDimensionData(
      image,
      this.imageMetaData
    );
    const { dx, dy } = getAxisLengths(
      start,
      end,
      colPixelSpacing,
      rowPixelSpacing
    );
    const area = dx * dy;
    data.area = area;
    data.suffix = MEASUREMENT_SUFFIXES.MM2;
    data.invalidated = false;
  }

  drawToolData(element, context, toolData): void {
    const { visible, area, suffix, handles } = toolData;
    if (visible !== false) {
      const color = cornerstoneTools.toolColors.getColorIfActive(toolData);
      const { drawHandlesOnHover, hideHandlesIfMoving, drawHandles } =
        RECTANGULAR_AREA_CONFIG;
      const lineOptions = { color };
      Drawing.setShadow(context, RECTANGULAR_AREA_CONFIG);
      Drawing.drawRect(
        context,
        element,
        handles.start,
        handles.end,
        lineOptions
      );
      const handleOptions = {
        ...DEFAULT_HANDLE,
        color,
        drawHandlesIfActive: drawHandlesOnHover,
        hideHandlesIfMoving,
      };

      if (drawHandles) {
        Drawing.drawHandles(context, { element }, handles, handleOptions);
      }

      if (!handles.textBox.hasMoved) {
        const coords = {
          x: Math.max(handles.start.x, handles.end.x),
          y: 0,
        };

        // Depending on which handle has the largest x-value,
        // Set the y-value for the text box
        if (coords.x === handles.start.x) {
          coords.y = handles.start.y;
        } else {
          coords.y = handles.end.y;
        }

        handles.textBox.x = coords.x;
        handles.textBox.y = coords.y;
      }

      // Move the textbox slightly to the right and upwards
      // So that it sits beside the length tool handle
      const xOffset = 10;

      const text = getDisplayedMeasurement(area, suffix);
      const lineWidth = 3;

      Drawing.drawLinkedTextBox(
        context,
        element,
        handles.textBox,
        text,
        handles,
        getTextBoxAnchorPoints,
        color,
        lineWidth,
        xOffset,
        true
      );
    }
  }
}
