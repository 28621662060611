import { useInitializeTools } from "./useInitializeTools";
import { useActiveTools } from "./useActiveTools";
import { useParseImageMetadataOnLoad } from "./useParseImageMetadataOnLoad";
import { useParseXcaliberAnalysis } from "./useParseXcaliberAnalysis";
import { useXcaliberAnalysis } from "pages/viewer/hooks/xcaliber/useXcaliberAnalysis";

export const useTools = () => {
  const { xcaliberAnalysis } = useXcaliberAnalysis();
  const { addToolCallback, initializedTools } = useInitializeTools();
  const { onToolButtonClick } = useActiveTools(
    initializedTools,
    xcaliberAnalysis
  );
  useParseXcaliberAnalysis(xcaliberAnalysis);
  useParseImageMetadataOnLoad();
  return {
    addToolCallback,
    onToolButtonClick,
  };
};
