import { useDarkModeTheme } from "hooks/useDarkmodeTheme";
import { DropdownOptionConfig } from "./dropdown.types";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";

export interface DropdownProps {
  title: string;
  isDisabled?: boolean;
  value?: string;
  defaultValue?: string;
  optionsConfig: DropdownOptionConfig[];
  onChange: (selectedOptionValue: string) => void;
}

export const Dropdown = ({
  title,
  isDisabled = false,
  value,
  defaultValue = "0",
  optionsConfig,
  onChange,
}: DropdownProps) => {
  const { backgroundThemeColor, textFieldThemeColor } = useDarkModeTheme();
  const handleChange = (e: SelectChangeEvent) => {
    onChange(e.target.value as string);
  };
  return (
    <Select
      style={{
        cursor: "pointer",
        color: textFieldThemeColor,
        backgroundColor: backgroundThemeColor,
        zIndex: 1000,
      }}
      native={false}
      value={value}
      name={title}
      disabled={isDisabled}
      defaultValue={defaultValue}
      onChange={(e) => handleChange(e)}
    >
      {optionsConfig.map(({ label, value, disabled, hidden }) => (
        <MenuItem
          style={{
            zIndex: 1000,
          }}
          key={value}
          value={value}
          disabled={disabled}
          hidden={hidden}
        >
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};
