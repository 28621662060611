import { useCallback, useMemo } from "react";
import { useViewerContext } from "../context/viewer.context";

export const useViewportStatus = (viewportIndex: number) => {
  const {
    dispatch,
    state: { viewportStatuses },
  } = useViewerContext();
  const viewportStatus = useMemo(
    () => viewportStatuses[viewportIndex] ?? null,
    [viewportStatuses, viewportIndex]
  );
  const setViewportStatus = useCallback(
    (newStatus: string) => {
      dispatch({
        type: "SET_VIEWPORT_STATUS",
        payload: { newStatus, viewportIndex },
      });
    },
    [dispatch, viewportIndex]
  );

  return { setViewportStatus, viewportStatus };
};
