import { useImageMetaData } from "pages/viewer/hooks/metadata/useImageMetaData";
import { useEffect, useState } from "react";
import { useViewerContext } from "pages/viewer/context/viewer.context";
import { reconstructImages } from "./reconstructImage";
import { useAxialImages } from "./useAxialImages";
import { VIEWPORT_STATUSES } from "pages/viewer/dicomViewer.consts";

export const useLoadMprData = () => {
  const {
    dispatch,
    state: { axialImages, isMPRActive, mprCoordinates },
  } = useViewerContext();
  const [mprImagesLoaded, setMprImagesLoaded] = useState(false);

  const { imageMetaData } = useImageMetaData();
  useAxialImages();
  useEffect(() => {
    const loadAndSetUpMPRData = async () => {
      if (isMPRActive && axialImages.length && imageMetaData) {
        dispatch({
          type: "SET_MPR_VIEWPORT_STATUSES",
          payload: { updatedStatus: VIEWPORT_STATUSES.RECONSTRUCTING_IMAGES },
        });
        try {
          const { coronalImage, sagittalImage } = await reconstructImages(
            axialImages,
            mprCoordinates,
            imageMetaData
          );
          if (coronalImage && sagittalImage) {
            dispatch({
              type: "MPR_IMAGE_GENERATED",
              payload: {
                coronalImage,
                sagittalImage,
              },
            });
            setMprImagesLoaded(true);
          }
        } catch {
        } finally {
          dispatch({
            type: "SET_MPR_VIEWPORT_STATUSES",
            payload: { updatedStatus: VIEWPORT_STATUSES.COMPLETE },
          });
        }
      }
    };
    loadAndSetUpMPRData();
  }, [, isMPRActive, axialImages, mprCoordinates, setMprImagesLoaded]);

  return { mprImagesLoaded };
};
