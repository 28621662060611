import { useCallback, useEffect, useMemo, useState } from "react";
import { StudyImageData } from "../../dicomViewer.types";
import { retrieveXcaliberFullAnalysis } from "services/xCaliber";
import { useAppContext } from "context/app.context";
import { useViewerContext } from "pages/viewer/context/viewer.context";

export const useRetrieveXcaliberAnalysis = (
  studyImageData: StudyImageData[] | null
) => {
  const {
    dispatch,
    state: { xcaliberAnalysisStudy, viewports },
  } = useViewerContext();

  const selectedImageIndex = useMemo(
    () => viewports[0]?.studyImageIndex ?? null,
    [viewports]
  );

  const [isRetrievingXcaliberData, setIsRetrievingXcaliberData] =
    useState<boolean>(false);

  const {
    state: { userId, xcaliberStatus },
  } = useAppContext();

  const canRetrieveXcaliberAnalysis = useMemo(
    () =>
      Boolean(
        selectedImageIndex !== null &&
          !isRetrievingXcaliberData &&
          (xcaliberAnalysisStudy[selectedImageIndex] === null ||
            xcaliberAnalysisStudy[selectedImageIndex] === undefined) &&
          xcaliberStatus !== null
      ),
    [
      selectedImageIndex,
      isRetrievingXcaliberData,
      xcaliberAnalysisStudy,
      xcaliberStatus,
    ]
  );

  const fetchData = useCallback(async () => {
    setIsRetrievingXcaliberData(true);
    if (xcaliberStatus !== null) {
      try {
        let sopIuid = "";
        if (studyImageData && studyImageData.length > 0) {
          sopIuid = studyImageData[selectedImageIndex].sopIuid;
        }

        if (xcaliberStatus === true) {
          setIsRetrievingXcaliberData(true);
        } else if (xcaliberStatus === false) {
          dispatch({
            type: "SAVE_XCALIBER_ANALYSIS",
            payload: {
              xcaliberAnalysisStudy: { subscribed: false, sopIuid: sopIuid },
              studyImageIndex: selectedImageIndex,
            },
          });
          setIsRetrievingXcaliberData(false);
          return;
        }

        const studyIuid = studyImageData[0].studyIuid;
        const imageIuids = studyImageData;
        const xcaliberAnalysis = await retrieveXcaliberFullAnalysis(
          userId,
          sopIuid,
          studyIuid,
          imageIuids
        );
        xcaliberAnalysis["sopIuid"] = sopIuid;
        xcaliberAnalysis["subscribed"] = true;

        dispatch({
          type: "SAVE_XCALIBER_ANALYSIS",
          payload: {
            xcaliberAnalysisStudy: xcaliberAnalysis,
            studyImageIndex: selectedImageIndex,
          },
        });

        setIsRetrievingXcaliberData(false);
      } catch (error) {
        console.error("Error retrieving Xcaliber analysis:", error);
        setIsRetrievingXcaliberData(false);
      }
    } else {
      console.warn(
        "Cannot fetch Xcaliber Analysis as subscription status is null"
      );
    }
  }, [
    dispatch,
    setIsRetrievingXcaliberData,
    selectedImageIndex,
    xcaliberStatus,
  ]);

  useEffect(() => {
    if (canRetrieveXcaliberAnalysis) {
      if (
        xcaliberAnalysisStudy[selectedImageIndex] === null ||
        xcaliberAnalysisStudy[selectedImageIndex] === undefined
      ) {
        fetchData();
      }
    }
  }, [xcaliberStatus, fetchData, canRetrieveXcaliberAnalysis]);

  return {
    isRetrievingXcaliberData,
  };
};
