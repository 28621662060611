import {
  HL7_DECODE,
  FIELD_KEYS,
} from "pages/home-page/components/study-table/study-table-columns/columns.consts";
import {
  decodeDateString,
  decodeNameFromHL7,
  decodeStudyDate,
} from "./decodeHL7";
import { DropdownOption } from "components/form/Form.types";
import { STATUS_OPTIONS } from "pages/home-page/components/study-table/study-table-rows/studyTableRows.consts";

export const formatValue = (rawValue: string | null, fieldName: string) => {
  if (HL7_DECODE.includes(fieldName)) {
    return decodeHL7(rawValue, fieldName);
  }
  if (fieldName === FIELD_KEYS.STATUS) {
    return getDropdownLabel(rawValue, STATUS_OPTIONS);
  }
  return rawValue;
};

export const decodeHL7 = (value: string | null, fieldName) => {
  switch (fieldName) {
    case FIELD_KEYS.PAT_BIRTHDATE:
      return decodeDateString(value);
    case FIELD_KEYS.PHYSICIAN:
    case FIELD_KEYS.PAT_NAME:
      return decodeNameFromHL7(value);
    case FIELD_KEYS.STUDY_DATETIME:
      return decodeStudyDate(value);
    default:
      return "Unknown";
  }
};

export const getDropdownLabel = (
  selectedValue: string,
  dropdownOptions: DropdownOption[]
) => {
  const selectedOption = dropdownOptions.find(
    ({ value }) => String(selectedValue) === value
  );

  if (selectedOption) {
    return selectedOption.label;
  }
  return "Unknown";
};

export const isDate = (value) => {
  return value instanceof Date;
};

export const pluralize = (value: number, singular: string, plural: string) => {
  if (value === 1) {
    return singular;
  } else {
    return plural;
  }
};

export const changeTimezoneToUtc = (date: Date): Date => {
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    )
  );
};
