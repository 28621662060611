export const CORONAL_OVERLAY_DATA = {
  topLeftDetails: {
    "View Type": "Coronal",
  },
};

export const SAGITTAL_OVERLAY_DATA = {
  topLeftDetails: {
    "View Type": "Sagittal",
  },
};
