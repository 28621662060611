import { getColourByUserType } from "../utils/colors/getColorByUserType";
import { useMemo, useEffect } from "react";
import { useAppContext } from "context/app.context";
import { setFaviconAndBackgroundColors } from "utils/colors/setFaviconAndBackgroundColors";
import { getUserColorClass } from "utils/colors/getUserColorClass";
import { useIsDevelopmentMode } from "./useIsDevelopment";
import { USER_TYPES } from "consts";

export const useUserType = () => {
  const {
    state: { userType: appUserType },
  } = useAppContext();

  const isDevelopment = useIsDevelopmentMode();
  const userType = useMemo(
    () => (isDevelopment ? USER_TYPES.VET_XCALIBER : appUserType),
    [appUserType, isDevelopment]
  );

  useEffect(() => {
    setFaviconAndBackgroundColors(userType);
  }, [userType]);

  return useMemo(
    () => ({
      userType,
      userTypeColour: getColourByUserType(userType),
      userColorClass: getUserColorClass(userType),
    }),
    [userType]
  );
};
