import { useUserType } from "hooks/useUserType";
import {
  decodeDateString,
  decodeNameFromHL7,
} from "utils/formatting/decodeHL7";

interface PatientDetailsProps {
  patName: string;
  patId: string;
  patBirthdate: string;
  patSex: string;
}
export const PatientDetails = ({
  patName,
  patId,
  patBirthdate,
  patSex,
}: PatientDetailsProps) => {
  const { userColorClass } = useUserType();
  return (
    <div className="patient-details-container header-container">
      <span className={`title ${userColorClass}`}>Patient Details</span>
      <span className="subtitle2">
        Patient Name: {decodeNameFromHL7(patName)}
      </span>
      <span className="subtitle2">Patient ID: {patId}</span>
      <span className="subtitle2">
        Birthday: {decodeDateString(patBirthdate)}
      </span>
      <span className="subtitle2">Sex: {patSex}</span>
    </div>
  );
};
