import { createContext, useReducer, useContext, ReactNode } from "react";
import {
  HomePageActions,
  HomePageContextType,
  HomePageState,
} from "./homepage.context.types";
import {
  FIELD_KEYS,
  SORT_DIRECTIONS,
} from "../components/study-table/study-table-columns/columns.consts";
import { studyFilterToUrl } from "../components/study-table-controls/study-filter/url-persistent-filter/studyFilterToUrl";
import { areObjectsDifferent } from "utils/validation/validation";

export const INITIAL_HOME_PAGE_STATE: HomePageState = {
  page: 0,
  hasLoadedFirstPage: false,
  cachedStudyData: {},
  lockedRows: [],
  unlockedRows: [],
  requiresReload: true,
  filters: {},
  selectedRows: [],
  sortingColumn: FIELD_KEYS.STUDY_DATETIME,
  sortingDirection: SORT_DIRECTIONS.DESC,
  loadedFromUrl: false,
  reloadTableCount: true,
};

export const HomePageContext = createContext<HomePageContextType | undefined>(
  undefined
);

const reducer = (state: HomePageState, action: HomePageActions) => {
  switch (action.type) {
    case "CHANGE_SEARCH_PARAMS": {
      const haveFiltersChanged =
        action.payload.filters !== undefined &&
        areObjectsDifferent(action.payload?.filters ?? {}, state.filters);
      const page =
        !action.payload.loadedFromUrl && haveFiltersChanged
          ? 0
          : action.payload.page ?? state.page;

      const tableDataQueryUrl = studyFilterToUrl(
        action.payload.filters !== undefined
          ? action.payload.filters
          : state.filters,
        page,
        action.payload.sortingColumn !== undefined
          ? action.payload.sortingColumn
          : state.sortingColumn,
        action.payload.sortingDirection !== undefined
          ? action.payload.sortingDirection
          : state.sortingDirection
      );
      window.history.pushState(null, null, tableDataQueryUrl);

      const resetFirstPageLoad =
        haveFiltersChanged ||
        (action.payload.sortingColumn !== undefined &&
          action.payload.sortingColumn !== state.sortingColumn) ||
        (action.payload.sortingDirection !== undefined &&
          state.sortingDirection !== action.payload.sortingDirection);

      const cachedStudyData = resetFirstPageLoad ? {} : state.cachedStudyData;
      // this wasn't working on production
      const updatedPayload = Object.entries(action.payload).reduce(
        (acc, [key, value]) => {
          if (value !== undefined && value !== null) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );
      // We only reload the table count when the filter has changed - page and sorting wont result in a different count
      return {
        ...state,
        ...updatedPayload,
        requiresReload: resetFirstPageLoad,
        hasLoadedFirstPage: !resetFirstPageLoad,
        cachedStudyData,
        reloadTableCount: haveFiltersChanged || action.payload.loadedFromUrl,
        page,
      };
    }
    case "LOAD_PAGE_DATA": {
      return {
        ...state,
        hasLoadedFirstPage: true,
        cachedStudyData: {
          ...state.cachedStudyData,
          [state.page]: action.payload.studyData,
        },
      };
    }
    case "ON_COUNT_LOAD": {
      return {
        ...state,
        requiresReload: false,
      };
    }
    case "UPDATE_ROW_ITEM": {
      return {
        ...state,
        cachedStudyData: {
          ...state.cachedStudyData,
          [action.payload.page]: state.cachedStudyData[action.payload.page].map(
            (item) =>
              item.studypk === action.payload.studypk
                ? { ...item, ...action.payload.updatedStudyData }
                : item
          ),
        },
      };
    }
    case "TOGGLE_ROW_LOCK": {
      return {
        ...state,
        lockedRows: [
          ...state.lockedRows.filter(
            (item) => !action.payload.unlockedRows.includes(item)
          ),
          ...(action.payload.lockedRows ?? []),
        ],
        unlockedRows: [
          ...state.unlockedRows.filter(
            (item) => !action.payload.lockedRows.includes(item)
          ),
          ...(action.payload.unlockedRows ?? []),
        ],
      };
    }
    case "TOGGLE_ROW_SELECT": {
      const { selectedStudy, isChecked } = action.payload;
      const { studypk } = selectedStudy;
      const selectedStudies = state.selectedRows.flatMap((row) =>
        isChecked === true || row.studypk !== studypk ? row : []
      );
      if (isChecked === true) {
        selectedStudies.push(selectedStudy);
      }
      return {
        ...state,
        selectedRows: selectedStudies,
      };
    }

    default:
      return state;
  }
};

const HomePageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_HOME_PAGE_STATE);
  return (
    <HomePageContext.Provider value={{ state, dispatch }}>
      {children}
    </HomePageContext.Provider>
  );
};

const useHomePageContext = () => {
  const context = useContext(HomePageContext);
  if (!context) {
    throw new Error(
      "useHomePageContext must be used within a HomePage Provider"
    );
  }

  return context;
};

export { HomePageProvider, useHomePageContext };
