import cornerstoneTools from "cornerstone-tools";
import cornerstone from "cornerstone-core";
import { useViewerContext } from "pages/viewer/context/viewer.context";
import { useCallback } from "react";
import { getViewportIdFromElement } from "../../functions/getViewportIdFromElement";
import { VIEWPORT_STATUSES } from "pages/viewer/dicomViewer.consts";
import { ALL_TOOLS } from "../../consts/tools.consts";
import { saveDicomImageAnnotations } from "services/studies/studies";
import { toast } from "react-toastify";

export const useClearAllAnnotations = () => {
  const { dispatch } = useViewerContext();
  return useCallback(async (enabledElement, image) => {
    const viewportIndex = getViewportIdFromElement(enabledElement);
    if (!isNaN(viewportIndex)) {
      dispatch({
        type: "SET_VIEWPORT_STATUS",
        payload: {
          viewportIndex,
          newStatus: VIEWPORT_STATUSES.DELETING_ANNOTATIONS,
        },
      });
      const allTools = ALL_TOOLS;
      allTools.forEach((tool) => {
        cornerstoneTools.clearToolState(enabledElement, tool.name);
        cornerstoneTools.setToolDisabledForElement(enabledElement, tool.name);
        cornerstone.updateImage(enabledElement);
      });
      const { imageId } = image;
      const imageURL = new URL(imageId);
      const searchParams = new URLSearchParams(imageURL.search);
      const studyIUID = searchParams.get("studyUID");
      const seriesIUID = searchParams.get("seriesUID");
      const instanceIUID = searchParams.get("objectUID");
      const stringifiedToolState = JSON.stringify("");
      const result = await saveDicomImageAnnotations(
        stringifiedToolState,
        studyIUID,
        seriesIUID,
        instanceIUID
      );
      if (result.errorMessage) {
        toast.error("Unable to save annotations");
      } else {
        toast.success("Annotations successfully cleared");
      }
    } else {
      toast.warn("No tool data to save");
    }

    dispatch({
      type: "SET_VIEWPORT_STATUS",
      payload: { viewportIndex, newStatus: VIEWPORT_STATUSES.COMPLETE },
    });
  }, []);
};
