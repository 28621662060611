import {
  CssBaseline,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  Container,
} from "@mui/material";
import { ActionButton } from "../../components/buttons/Buttons";
import { motion } from "framer-motion";
import { DarkModeSwitch } from "../../components/darkmode-switch/DarkmodeSwitch";
import { Copyright } from "../../components/Copywrite";
import { DefaultTextField } from "../../components/text-field/TextFields";
import { useDarkModeTheme } from "hooks/useDarkmodeTheme";
import { useHandleLoginWithCredentials } from "./useHandleLoginWithCredentials";
import { GoogleLoginSection } from "../../components/google/google-login/GoogleLoginSection";
import { useCallback, useState } from "react";
import { useSavedRoute } from "hooks/useSavedRoute";
import { DefaultSpinner } from "components/loading/DefaultSpinner";

export const UserLogin = () => {
  const {
    backgroundThemeColor,
    textFieldThemeColor,
    borderThemeColor,
    inputThemeColor,
    textThemeColor,
    isDarkModeOn,
  } = useDarkModeTheme();

  const TEXT_FIELD_AUTO_FILL_STYLE = {
    WebkitBoxShadow: isDarkModeOn
      ? "0 0 0 1000px #2d2d2d inset"
      : "0 0 0 1000px #ffffff inset",
    WebkitTextFillColor: isDarkModeOn ? "#ffffff" : "#000000",
  };

  const [isLoading, setIsLoading] = useState(false);
  const toggleIsLoading = useCallback(() => {
    setIsLoading((prev) => !prev);
  }, [setIsLoading]);
  const redirectUrlOnLogin = useSavedRoute();
  const { handleLogin, handlePassword, handleUsername } =
    useHandleLoginWithCredentials(toggleIsLoading, redirectUrlOnLogin);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.25,
      }}
      exit={{ opacity: 0 }}
    >
      <>
        {isLoading ? (
          <div className="login-spinner-container">
            <DefaultSpinner spinnerSize={60} displayText="Logging In" />
          </div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 0.25,
            }}
            exit={{ opacity: 0 }}
          >
            <DarkModeSwitch />
            <form
              style={{
                backgroundColor: backgroundThemeColor,
                borderColor: borderThemeColor,
              }}
              className="form login-form "
              onSubmit={handleLogin}
            >
              <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                  sx={{
                    marginTop: 5,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{ color: textThemeColor }}
                    variant="h5"
                    sx={{ color: "black" }}
                  >
                    iTX Cloud PACS Login
                  </Typography>
                  {redirectUrlOnLogin && (
                    <Typography
                      style={{
                        color: textThemeColor,
                        textAlign: "center",
                        marginTop: "0.5rem",
                        fontSize: "0.7rem",
                      }}
                    >
                      For security reasons you have been asked to login again
                      <br />
                      You will be redirected to the page you wish to view after
                      successful authentication.
                    </Typography>
                  )}
                  <Box className="box" sx={{ mt: 1 }}>
                    <DefaultTextField
                      margin="normal"
                      required
                      fullWidth
                      id="Username"
                      label="Username"
                      name="Username"
                      autoComplete="Username"
                      onChange={handleUsername}
                      autoFocus
                      color="secondary"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      activecolor={textFieldThemeColor}
                      inputProps={{ style: TEXT_FIELD_AUTO_FILL_STYLE }}
                      sx={{ backgroundColor: "none" }}
                    />
                    <DefaultTextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      onChange={handlePassword}
                      autoComplete="current-password"
                      color="secondary"
                      activecolor={textFieldThemeColor}
                      style={{ color: inputThemeColor }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ style: TEXT_FIELD_AUTO_FILL_STYLE }}
                      sx={{ backgroundColor: "none" }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="remember"
                          sx={{
                            color: inputThemeColor,
                          }}
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            color: inputThemeColor,
                          }}
                          variant="body2"
                        >
                          Remember me
                        </Typography>
                      }
                    />
                    <ActionButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      color="secondary"
                    >
                      Log in
                    </ActionButton>
                    <Box
                      component={"div"}
                      className="box"
                      sx={{ mt: 2, mb: 2, textAlign: "center" }}
                    >
                      OR
                    </Box>
                    <Box
                      component={"div"}
                      className="box"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      <GoogleLoginSection
                        toggleIsLoading={toggleIsLoading}
                        redirectUrlOnLogin={redirectUrlOnLogin}
                      />
                    </Box>
                  </Box>
                </Box>
                <Copyright
                  style={{ color: textThemeColor }}
                  sx={{ mt: 8, mb: 4 }}
                />
              </Container>
            </form>
          </motion.div>
        )}
      </>
    </motion.div>
  );
};
