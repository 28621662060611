//@ts-nocheck
import cornerstoneTools from "cornerstone-tools";
import cornerstone, { CanvasCoordinate } from "cornerstone-core";
import Drawing from "../../api/Drawing";
import Util from "../../api/Util";
import Manipulators from "../../api/Manipulators";
import { TOOL_IDS } from "../../consts/tools.consts";
import { getDimensionData } from "../../measurement-tools/measurementToolUtils";
import { formatHandles } from "./formatHandles";
import { setToolInstructions } from "./setToolInstructions";
import { GONSTEAD_POINTS } from "./gonsteadTool.consts";
import { ExtendedAnnotationTool } from "../../api/ExtendedAnnotationTool";
const circleRoiCursor = cornerstoneTools.importInternal("tools/cursors");
const roundToDecimal = cornerstoneTools.importInternal("util/roundToDecimal");
const drawLinkedTextBox = cornerstoneTools.import("drawing/drawLinkedTextBox");

const GONSTEAD_CONFIGURATION = {
  handleRadius: 2, //12, //Used for drawing the placed handles. Can change the name of this variable to something more appropriate.
  renderDashed: false,
  hideHandlesIfMoving: true,
  prePlacementHandleRadius: 2, //15,//12,
  drawHandlesOnHover: true,
  circleColour: "aqua",
  circleColourOutsideImage: "red",
  labelColour: "aqua",
  measurementColour: "white",
  lineColour: "lime",
  lineColourOutsideImage: "red",
};
export class GonsteadTool extends ExtendedAnnotationTool {
  hasIncomplete: boolean;
  preventNewMeasurement: boolean;
  currentPoint: string;
  constructor() {
    const defaultProps = {
      svgCursor: circleRoiCursor,
    };
    super(TOOL_IDS.GONSTEAD_TOOL);
    this.hasIncomplete = false;
    this.preventNewMeasurement = false;
    this.currentPoint = null;
  }

  public onToolActivation(_element) {
    setToolInstructions(GONSTEAD_POINTS.RIGHT_FEMUR_HEAD);
    this.currentPoint = GONSTEAD_POINTS.RIGHT_FEMUR_HEAD;
  }

  createNewMeasurement(eventData) {
    this.hasIncomplete = true;
    const goodEventData =
      eventData && eventData.currentPoints && eventData.currentPoints.image;
    if (!goodEventData) {
      console.error(
        `required eventData not supplied to tool ${this.toolId}'s createNewMeasurement`
      );
      return;
    }
    const currentImagePointData = eventData.currentPoints.image;
    const handleData = formatHandles(currentImagePointData);
    return handleData;
  }

  //Check whether each handle is inside the image boundary and update handle insideImage parameter if not
  activeCallback(element) {
    this.onMeasureModified = this.onMeasureModified.bind(this);
    element.addEventListener(
      cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }

  onMeasureModified(evt) {
    //Go through handle list and check if any of the coordinates are outside image boundry. If so, change the state of the handle
    const { element } = evt.detail;
    const image = cornerstone.getEnabledElement(element).image;
    const handles = evt.detail.measurementData.handles;
    if (handles.length <= 0) {
      return;
    }

    const xLeftBorder = 0;
    const xRightBorder = image.width; //400;
    const yTopBorder = 0;
    const yBottomBorder = image.height; //400;
    //For placed handles
    Object.keys(handles).map((key) => {
      let handle = handles[key];
      if (!key.includes("textBox")) {
        if (
          handle.x < xLeftBorder ||
          handle.x > xRightBorder ||
          handle.y < yTopBorder ||
          handle.y > yBottomBorder
        ) {
          handle.insideImage = false;
        } else {
          handle.insideImage = true;
        }
      }
    });
    //For the pre-placement handle
    const prePlacementHandle =
      evt.detail.measurementData.prePlacementHandles.handle;
    if (!prePlacementHandle) {
      return;
    }
    if (
      prePlacementHandle.x < xLeftBorder ||
      prePlacementHandle.x > xRightBorder ||
      prePlacementHandle.y < yTopBorder ||
      prePlacementHandle.y > yBottomBorder
    ) {
      prePlacementHandle.insideImage = false;
    } else {
      prePlacementHandle.insideImage = true;
    }
  }
  updateCachedStats(image, _element, data) {
    const { rowPixelSpacing, colPixelSpacing } = getDimensionData(
      image,
      this.imageMetaData
    );

    data.rowPixelSpacing = rowPixelSpacing;
    data.colPixelSpacing = colPixelSpacing;
  }

  addNewMeasurement(evt, interactionType) {
    setToolInstructions(GONSTEAD_POINTS.LEFT_FEMUR_HEAD);
    this.currentPoint = GONSTEAD_POINTS.LEFT_FEMUR_HEAD;

    if (this.preventNewMeasurement) {
      setToolInstructions("");
      return;
    }
    this.preventNewMeasurement = true;
    evt.preventDefault; //Prevents the default behaviour of the event, and thus we can change the way the event is handled.
    evt.stopPropagation; //Prevents the event from "bubbling up" the DOM tree and triggering other event listeners.

    const eventData = evt.detail;
    const measurementData = this.createNewMeasurement(eventData);
    const element = evt.detail.element;

    cornerstoneTools.addToolState(element, this.toolId, measurementData);
    //Associate this data with this imageId so we can render it and manipulate it
    cornerstone.updateImage(element);
    const toolInstructions = document.getElementById("toolInstructions");
    //Move prePlacementHandle to follow the mouse cursor
    Manipulators.moveNewHandle(
      eventData,
      this.toolId,
      measurementData,
      measurementData.prePlacementHandles.handle,
      this.options,
      interactionType
    );
    //Place Handle 2
    Manipulators.moveNewHandle(
      eventData,
      this.toolId,
      measurementData,
      measurementData.handles.leftFemurHeadHandle,
      this.options,
      interactionType,
      (success) => {
        measurementData.active = false;
        measurementData.handles.leftFemurHeadHandle.placed = true;
        measurementData.handles.leftFemurHeadTextBox.placed = true;
        if (!success) {
          cornerstoneTools.removeToolState(
            element,
            this.toolId,
            measurementData
          );
          this.preventNewMeasurement = false;
          toolInstructions.innerHTML = ""; //Reset the instruction message if not successful
          return;
        }

        //Move prePlacementHandle to follow the mouse cursor
        Manipulators.moveNewHandle(
          eventData,
          this.toolId,
          measurementData,
          measurementData.prePlacementHandles.handle,
          this.options,
          interactionType
        );
        //Place Handle 3
        measurementData.handles.rightIliacCrestHandle.active = true;
        cornerstone.updateImage(element);
        toolInstructions.innerHTML =
          "3. Click the highest point of the right iliac crest";
        Manipulators.moveNewHandle(
          eventData,
          this.toolId,
          measurementData,
          measurementData.handles.rightIliacCrestHandle,
          this.options,
          interactionType,
          (success) => {
            measurementData.active = false;
            measurementData.handles.rightIliacCrestHandle.placed = true;
            measurementData.handles.rightIliacCrestTextBox.placed = true;
            if (!success) {
              cornerstoneTools.removeToolState(
                element,
                this.toolId,
                measurementData
              );
              this.preventNewMeasurement = false;
              setToolInstructions("");
              return;
            }

            //Move prePlacementHandle to follow the mouse cursor
            Manipulators.moveNewHandle(
              eventData,
              this.toolId,
              measurementData,
              measurementData.prePlacementHandles.handle,
              this.options,
              interactionType
            );

            measurementData.handles.rightIschialTuberosityHandle.active = true;
            cornerstone.updateImage(element);
            setToolInstructions(GONSTEAD_POINTS.RIGHT_ISCHIAL_TUBEROSITY);
            Manipulators.moveNewHandle(
              eventData,
              this.toolId,
              measurementData,
              measurementData.handles.rightIschialTuberosityHandle,
              this.options,
              interactionType,
              (success) => {
                measurementData.active = false;
                measurementData.handles.rightIschialTuberosityHandle.placed =
                  true;
                measurementData.handles.rightIschialTuberosityTextBox.placed =
                  true;
                if (!success) {
                  cornerstoneTools.removeToolState(
                    element,
                    this.toolId,
                    measurementData
                  );
                  this.preventNewMeasurement = false;
                  toolInstructions.innerHTML = ""; //Reset the instruction message if not successful
                  return;
                }

                //Move prePlacementHandle to follow the mouse cursor
                Manipulators.moveNewHandle(
                  eventData,
                  this.toolId,
                  measurementData,
                  measurementData.prePlacementHandles.handle,
                  this.options,
                  interactionType
                );
                //Place Handle 5
                measurementData.handles.leftIliacCrestHandle.active = true;
                cornerstone.updateImage(element);
                toolInstructions.innerHTML =
                  "5. Click the highest point of the left iliac crest";
                Manipulators.moveNewHandle(
                  eventData,
                  this.toolId,
                  measurementData,
                  measurementData.handles.leftIliacCrestHandle,
                  this.options,
                  interactionType,
                  (success) => {
                    measurementData.active = false;
                    measurementData.handles.leftIliacCrestHandle.placed = true;
                    measurementData.handles.leftIliacCrestTextBox.placed = true;
                    if (!success) {
                      cornerstoneTools.removeToolState(
                        element,
                        this.toolId,
                        measurementData
                      );
                      this.preventNewMeasurement = false;
                      toolInstructions.innerHTML = ""; //Reset the instruction message if not successful
                      return;
                    }

                    //Move prePlacementHandle to follow the mouse cursor
                    Manipulators.moveNewHandle(
                      eventData,
                      this.toolId,
                      measurementData,
                      measurementData.prePlacementHandles.handle,
                      this.options,
                      interactionType
                    );
                    //Place Handle 6
                    measurementData.handles.leftIschialTuberosityHandle.active =
                      true;
                    cornerstone.updateImage(element);
                    toolInstructions.innerHTML =
                      "6. Click the lowest point of the left ischial tuberosity";
                    Manipulators.moveNewHandle(
                      eventData,
                      this.toolId,
                      measurementData,
                      measurementData.handles.leftIschialTuberosityHandle,
                      this.options,
                      interactionType,
                      (success) => {
                        measurementData.active = false;
                        measurementData.handles.leftIschialTuberosityHandle.placed =
                          true;
                        measurementData.handles.leftIschialTuberosityTextBox.placed =
                          true;
                        if (!success) {
                          cornerstoneTools.removeToolState(
                            element,
                            this.toolId,
                            measurementData
                          );
                          this.preventNewMeasurement = false;
                          toolInstructions.innerHTML = ""; //Reset the instruction message if not successful
                          return;
                        }

                        //Move prePlacementHandle to follow the mouse cursor
                        Manipulators.moveNewHandle(
                          eventData,
                          this.toolId,
                          measurementData,
                          measurementData.prePlacementHandles.handle,
                          this.options,
                          interactionType
                        );
                        //Place Handle 7
                        measurementData.handles.s2TubercleHandle.active = true;
                        cornerstone.updateImage(element);
                        toolInstructions.innerHTML =
                          "7. Click on the S2 tubercle (or S1 if preferred)";
                        Manipulators.moveNewHandle(
                          eventData,
                          this.toolId,
                          measurementData,
                          measurementData.handles.s2TubercleHandle,
                          this.options,
                          interactionType,
                          (success) => {
                            measurementData.active = false;
                            measurementData.handles.s2TubercleHandle.placed =
                              true;
                            measurementData.handles.s2TubercleTextBox.placed =
                              true;
                            if (!success) {
                              cornerstoneTools.removeToolState(
                                element,
                                this.toolId,
                                measurementData
                              );
                              this.preventNewMeasurement = false;
                              toolInstructions.innerHTML = ""; //Reset the instruction message if not successful
                              return;
                            }

                            //Move prePlacementHandle to follow the mouse cursor
                            Manipulators.moveNewHandle(
                              eventData,
                              this.toolId,
                              measurementData,
                              measurementData.prePlacementHandles.handle,
                              this.options,
                              interactionType
                            );
                            //Place Handle 8
                            measurementData.handles.pubicSymphysisHandle.active =
                              true;
                            cornerstone.updateImage(element);
                            toolInstructions.innerHTML =
                              "8. Click the center of the pubic symphysis";
                            Manipulators.moveNewHandle(
                              eventData,
                              this.toolId,
                              measurementData,
                              measurementData.handles.pubicSymphysisHandle,
                              this.options,
                              interactionType,
                              (success) => {
                                measurementData.active = false;
                                measurementData.handles.pubicSymphysisHandle.placed =
                                  true;
                                measurementData.handles.pubicSymphysisTextBox.placed =
                                  true;
                                if (!success) {
                                  cornerstoneTools.removeToolState(
                                    element,
                                    this.toolId,
                                    measurementData
                                  );
                                  this.preventNewMeasurement = false;
                                  toolInstructions.innerHTML = ""; //Reset the instruction message if not successful
                                  return;
                                }

                                //Move prePlacementHandle to follow the mouse cursor
                                Manipulators.moveNewHandle(
                                  eventData,
                                  this.toolId,
                                  measurementData,
                                  measurementData.prePlacementHandles.handle,
                                  this.options,
                                  interactionType
                                );
                                //Place Handle 9
                                measurementData.handles.rightS1FacetBaseHandle.active =
                                  true;
                                cornerstone.updateImage(element);
                                toolInstructions.innerHTML =
                                  "9. Click the lateral right S1 facet base";
                                Manipulators.moveNewHandle(
                                  eventData,
                                  this.toolId,
                                  measurementData,
                                  measurementData.handles
                                    .rightS1FacetBaseHandle,
                                  this.options,
                                  interactionType,
                                  (success) => {
                                    measurementData.active = false;
                                    measurementData.handles.rightS1FacetBaseHandle.placed =
                                      true;
                                    measurementData.handles.rightS1FacetBaseTextBox.placed =
                                      true;
                                    if (!success) {
                                      cornerstoneTools.removeToolState(
                                        element,
                                        this.toolId,
                                        measurementData
                                      );
                                      this.preventNewMeasurement = false;
                                      toolInstructions.innerHTML = ""; //Reset the instruction message if not successful
                                      return;
                                    }

                                    //Move prePlacementHandle to follow the mouse cursor
                                    Manipulators.moveNewHandle(
                                      eventData,
                                      this.toolId,
                                      measurementData,
                                      measurementData.prePlacementHandles
                                        .handle,
                                      this.options,
                                      interactionType
                                    );
                                    //Place Handle 10
                                    measurementData.handles.leftS1FacetBaseHandle.active =
                                      true;
                                    cornerstone.updateImage(element);
                                    toolInstructions.innerHTML =
                                      "10. Click on the lateral left S1 facet base";
                                    Manipulators.moveNewHandle(
                                      eventData,
                                      this.toolId,
                                      measurementData,
                                      measurementData.handles
                                        .leftS1FacetBaseHandle,
                                      this.options,
                                      interactionType,
                                      (success) => {
                                        measurementData.active = false;
                                        measurementData.handles.leftS1FacetBaseHandle.placed =
                                          true;
                                        measurementData.handles.leftS1FacetBaseTextBox.placed =
                                          true;
                                        if (!success) {
                                          cornerstoneTools.removeToolState(
                                            element,
                                            this.toolId,
                                            measurementData
                                          );
                                          this.preventNewMeasurement = false;
                                          toolInstructions.innerHTML = ""; //Reset the instruction message if not successful
                                          return;
                                        }

                                        //Move prePlacementHandle to follow the mouse cursor
                                        Manipulators.moveNewHandle(
                                          eventData,
                                          this.toolId,
                                          measurementData,
                                          measurementData.prePlacementHandles
                                            .handle,
                                          this.options,
                                          interactionType
                                        );
                                        //Place Handle 11
                                        measurementData.handles.rightSacralWingHandle.active =
                                          true;
                                        cornerstone.updateImage(element);
                                        toolInstructions.innerHTML =
                                          "11. Click the most lateral aspect of the right sacral wing";
                                        Manipulators.moveNewHandle(
                                          eventData,
                                          this.toolId,
                                          measurementData,
                                          measurementData.handles
                                            .rightSacralWingHandle,
                                          this.options,
                                          interactionType,
                                          (success) => {
                                            measurementData.active = false;
                                            measurementData.handles.rightSacralWingHandle.placed =
                                              true;
                                            measurementData.handles.rightSacralWingTextBox.placed =
                                              true;
                                            if (!success) {
                                              cornerstoneTools.removeToolState(
                                                element,
                                                this.toolId,
                                                measurementData
                                              );
                                              this.preventNewMeasurement =
                                                false;
                                              toolInstructions.innerHTML = ""; //Reset the instruction message if not successful
                                              return;
                                            }

                                            //Move prePlacementHandle to follow the mouse cursor
                                            Manipulators.moveNewHandle(
                                              eventData,
                                              this.toolId,
                                              measurementData,
                                              measurementData
                                                .prePlacementHandles.handle,
                                              this.options,
                                              interactionType
                                            );
                                            //Place Handle 12
                                            measurementData.handles.leftSacralWingHandle.active =
                                              true;
                                            cornerstone.updateImage(element);
                                            toolInstructions.innerHTML =
                                              "12. Click the most lateral aspect of the left sacral wing";
                                            Manipulators.moveNewHandle(
                                              eventData,
                                              this.toolId,
                                              measurementData,
                                              measurementData.handles
                                                .leftSacralWingHandle,
                                              this.options,
                                              interactionType,
                                              (success) => {
                                                measurementData.active = false;
                                                measurementData.handles.leftSacralWingHandle.placed =
                                                  true;
                                                measurementData.handles.leftSacralWingTextBox.placed =
                                                  true;
                                                if (!success) {
                                                  cornerstoneTools.removeToolState(
                                                    element,
                                                    this.toolId,
                                                    measurementData
                                                  );
                                                  this.preventNewMeasurement =
                                                    false;
                                                  toolInstructions.innerHTML =
                                                    ""; //Reset the instruction message if not successful
                                                  return;
                                                }

                                                //Move prePlacementHandle to follow the mouse cursor
                                                Manipulators.moveNewHandle(
                                                  eventData,
                                                  this.toolId,
                                                  measurementData,
                                                  measurementData
                                                    .prePlacementHandles.handle,
                                                  this.options,
                                                  interactionType
                                                );
                                                //Place Handle 13
                                                measurementData.handles.rightIliacWingHandle.active =
                                                  true;
                                                cornerstone.updateImage(
                                                  element
                                                );
                                                toolInstructions.innerHTML =
                                                  "13. Click the most lateral aspect of the right iliac wing";
                                                Manipulators.moveNewHandle(
                                                  eventData,
                                                  this.toolId,
                                                  measurementData,
                                                  measurementData.handles
                                                    .rightIliacWingHandle,
                                                  this.options,
                                                  interactionType,
                                                  (success) => {
                                                    measurementData.active =
                                                      false;
                                                    measurementData.handles.rightIliacWingHandle.placed =
                                                      true;
                                                    measurementData.handles.rightIliacWingTextBox.placed =
                                                      true;
                                                    if (!success) {
                                                      cornerstoneTools.removeToolState(
                                                        element,
                                                        this.toolId,
                                                        measurementData
                                                      );
                                                      this.preventNewMeasurement =
                                                        false;
                                                      toolInstructions.innerHTML =
                                                        ""; //Reset the instruction message if not successful
                                                      return;
                                                    }

                                                    //Move prePlacementHandle to follow the mouse cursor
                                                    Manipulators.moveNewHandle(
                                                      eventData,
                                                      this.toolId,
                                                      measurementData,
                                                      measurementData
                                                        .prePlacementHandles
                                                        .handle,
                                                      this.options,
                                                      interactionType
                                                    );
                                                    //Place Handle 14
                                                    measurementData.handles.leftIliacWingHandle.active =
                                                      true;
                                                    cornerstone.updateImage(
                                                      element
                                                    );
                                                    toolInstructions.innerHTML =
                                                      "14. Click the most lateral aspect of the left iliac wing";
                                                    Manipulators.moveNewHandle(
                                                      eventData,
                                                      this.toolId,
                                                      measurementData,
                                                      measurementData.handles
                                                        .leftIliacWingHandle,
                                                      this.options,
                                                      interactionType,
                                                      (success) => {
                                                        measurementData.active =
                                                          false;
                                                        measurementData.handles.leftIliacWingHandle.placed =
                                                          true;
                                                        measurementData.handles.leftIliacWingTextBox.placed =
                                                          true;
                                                        if (!success) {
                                                          cornerstoneTools.removeToolState(
                                                            element,
                                                            this.toolId,
                                                            measurementData
                                                          );
                                                          this.preventNewMeasurement =
                                                            false;
                                                          toolInstructions.innerHTML =
                                                            ""; //Reset the instruction message if not successful
                                                          return;
                                                        }

                                                        //Move prePlacementHandle to follow the mouse cursor
                                                        Manipulators.moveNewHandle(
                                                          eventData,
                                                          this.toolId,
                                                          measurementData,
                                                          measurementData
                                                            .prePlacementHandles
                                                            .handle,
                                                          this.options,
                                                          interactionType
                                                        );
                                                        //Place Handle 15
                                                        measurementData.handles.rightPsisHandle.active =
                                                          true;
                                                        cornerstone.updateImage(
                                                          element
                                                        );
                                                        toolInstructions.innerHTML =
                                                          "15. Click the most medial aspect of the right posterior superior iliac spine";
                                                        Manipulators.moveNewHandle(
                                                          eventData,
                                                          this.toolId,
                                                          measurementData,
                                                          measurementData
                                                            .handles
                                                            .rightPsisHandle,
                                                          this.options,
                                                          interactionType,
                                                          (success) => {
                                                            measurementData.active =
                                                              false;
                                                            measurementData.handles.rightPsisHandle.placed =
                                                              true;
                                                            measurementData.handles.rightPsisTextBox.placed =
                                                              true;
                                                            if (!success) {
                                                              cornerstoneTools.removeToolState(
                                                                element,
                                                                this.toolId,
                                                                measurementData
                                                              );
                                                              this.preventNewMeasurement =
                                                                false;
                                                              toolInstructions.innerHTML =
                                                                ""; //Reset the instruction message if not successful
                                                              return;
                                                            }

                                                            //Move prePlacementHandle to follow the mouse cursor
                                                            Manipulators.moveNewHandle(
                                                              eventData,
                                                              this.toolId,
                                                              measurementData,
                                                              measurementData
                                                                .prePlacementHandles
                                                                .handle,
                                                              this.options,
                                                              interactionType
                                                            );
                                                            //Place Handle 16
                                                            measurementData.handles.leftPsisHandle.active =
                                                              true;
                                                            cornerstone.updateImage(
                                                              element
                                                            );
                                                            toolInstructions.innerHTML =
                                                              "16. Click the most medial aspect of the left posterior superior iliac spine";
                                                            Manipulators.moveNewHandle(
                                                              eventData,
                                                              this.toolId,
                                                              measurementData,
                                                              measurementData
                                                                .handles
                                                                .leftPsisHandle,
                                                              this.options,
                                                              interactionType,
                                                              (success) => {
                                                                measurementData.active =
                                                                  false;
                                                                measurementData.handles.leftPsisHandle.placed =
                                                                  true;
                                                                measurementData.handles.leftPsisTextBox.placed =
                                                                  true;
                                                                if (!success) {
                                                                  cornerstoneTools.removeToolState(
                                                                    element,
                                                                    this.toolId,
                                                                    measurementData
                                                                  );
                                                                  this.preventNewMeasurement =
                                                                    false;
                                                                  toolInstructions.innerHTML =
                                                                    ""; //Reset the instruction message if not successful
                                                                  return;
                                                                }

                                                                //Once the final handle has been placed, finalise the measurement
                                                                cornerstone.updateImage(
                                                                  element
                                                                );
                                                                this.preventNewMeasurement =
                                                                  false;
                                                                cornerstone.updateImage(
                                                                  element
                                                                );
                                                                const modifiedEventData =
                                                                  {
                                                                    toolName:
                                                                      this
                                                                        .toolId,
                                                                    toolType:
                                                                      this
                                                                        .toolId, //Depreciation notice: toolType will be replaced by toolName
                                                                    element,
                                                                    measurementData,
                                                                  };
                                                                Util.triggerEvent(
                                                                  element,
                                                                  cornerstone
                                                                    .EVENTS
                                                                    .MEASUREMENTCOMPLETED,
                                                                  modifiedEventData
                                                                );
                                                                toolInstructions.innerHTML =
                                                                  ""; //Reset the instruction message
                                                                this.firstClick =
                                                                  true; //Resets the firstClick - Keep this at the end of the measurement cycle

                                                                //Change the tool to pointer
                                                                cornerstoneTools.setToolActive(
                                                                  "Pointer",
                                                                  {
                                                                    mouseButtonMask: 1,
                                                                  }
                                                                );
                                                              }
                                                            );
                                                          }
                                                        );
                                                      }
                                                    );
                                                  }
                                                );
                                              }
                                            );
                                          }
                                        );
                                      }
                                    );
                                  }
                                );
                              }
                            );
                          }
                        );
                      }
                    );
                  }
                );
              }
            );
          }
        );
      }
    );
  }

  pointNearTool(element, data, coords, interactionType) {
    let nearTool = false;

    ////console.log('Calling point near tool. Data: ', data);

    const validParameters = data && data.handles; //&& data.handles.start && data.handles.end;
    if (!validParameters) {
      //console.log(
      //   `invalid parameters supplied to tool ${this.toolId}'s pointNearTool`
      // );
      return false;
    }

    if (data.visible === false) {
      return false;
    }

    const toolGeometry = this.getToolGeometry(element, data);

    Object.keys(toolGeometry)
      .filter((key) => key.slice(0, 5) == "line_")
      .forEach((key) => {
        let distance = 1000;

        //Get the coordinates for the calculations from the line coordinates and the current cursor coordinates
        const { x: x1, y: y1 } = toolGeometry[key].start;
        const { x: x2, y: y2 } = toolGeometry[key].end;
        const { x, y } = coords;
        //Calculate squared length of the line segment
        const squaredLength = Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2);

        if (squaredLength === 0) {
          //Line segment is a point
          distance = Math.sqrt(Math.pow(x - x1, 2) + Math.pow(y - y1, 2));
        }

        //Calculate the projection parameter
        const projection =
          ((x - x1) * (x2 - x1) + (y - y1) * (y2 - y1)) / squaredLength;

        if (projection < 0) {
          //Closest point is the start point
          distance = Math.sqrt(Math.pow(x - x1, 2) + Math.pow(y - y1, 2));
        } else if (projection > 1) {
          //Closest point is the end point
          distance = Math.sqrt(Math.pow(x - x2, 2) + Math.pow(y - y2, 2));
        } else {
          //Closest point lies on the line segment
          const px = x1 + projection * (x2 - x1);
          const py = y1 + projection * (y2 - y1);
          distance = Math.sqrt(Math.pow(x - px, 2) + Math.pow(y - py, 2));
        }

        ////console.log('Distance from ' + key + ': ' + distance + ', Projection: ' + projection + ', Near tool: ' + nearTool);

        if (distance < 5) {
          nearTool = true;
        }
      });

    return nearTool;
  }

  getToolGeometry(element, data) {
    //Convert handles from pixel coordinates to canvas coordinates
    const handle1Canvas = cornerstone.pixelToCanvas(
      element,
      data.handles.rightFemurHeadHandle
    );
    const handle2Canvas = cornerstone.pixelToCanvas(
      element,
      data.handles.leftFemurHeadHandle
    );
    const handle3Canvas = cornerstone.pixelToCanvas(
      element,
      data.handles.rightIliacCrestHandle
    );
    const handle4Canvas = cornerstone.pixelToCanvas(
      element,
      data.handles.rightIschialTuberosityHandle
    );
    const handle5Canvas = cornerstone.pixelToCanvas(
      element,
      data.handles.leftIliacCrestHandle
    );
    const leftIschialTuberosityHandleCanvas = cornerstone.pixelToCanvas(
      element,
      data.handles.leftIschialTuberosityHandle
    );
    const s2TubercleHandleCanvas = cornerstone.pixelToCanvas(
      element,
      data.handles.s2TubercleHandle
    );
    const handle8Canvas = cornerstone.pixelToCanvas(
      element,
      data.handles.pubicSymphysisHandle
    );
    const s1FacetBaseHandleCanvas = cornerstone.pixelToCanvas(
      element,
      data.handles.rightS1FacetBaseHandle
    );
    const handle10Canvas = cornerstone.pixelToCanvas(
      element,
      data.handles.leftS1FacetBaseHandle
    );
    const handle11Canvas = cornerstone.pixelToCanvas(
      element,
      data.handles.rightSacralWingHandle
    );
    const handle12Canvas = cornerstone.pixelToCanvas(
      element,
      data.handles.leftSacralWingHandle
    );
    const handle13Canvas = cornerstone.pixelToCanvas(
      element,
      data.handles.rightIliacWingHandle
    );
    const handle14Canvas = cornerstone.pixelToCanvas(
      element,
      data.handles.leftIliacWingHandle
    );
    const handle15Canvas = cornerstone.pixelToCanvas(
      element,
      data.handles.rightPsisHandle
    );
    const handle16Canvas = cornerstone.pixelToCanvas(
      element,
      data.handles.leftPsisHandle
    );

    //Calculate gradients and line extension distances for the Femur Head line (FHL)
    const fhlGradient =
      (handle2Canvas.y - handle1Canvas.y) / (handle2Canvas.x - handle1Canvas.x);
    const fhlPerpGradient = -1 * (1 / fhlGradient);
    const fhlLength = Math.sqrt(
      Math.pow(handle2Canvas.x - handle1Canvas.x, 2) +
        Math.pow(handle2Canvas.y - handle1Canvas.y, 2)
    );
    const extLengthShort = fhlLength / 12; //arbitrary
    const xExtShort = Math.sqrt(
      Math.pow(extLengthShort, 2) / (Math.pow(fhlGradient, 2) + 1)
    );
    const xExtPerpShort = Math.sqrt(
      Math.pow(extLengthShort, 2) / (Math.pow(fhlPerpGradient, 2) + 1)
    );
    const extLength = fhlLength / 8; //arbitrary
    const xExt = Math.sqrt(
      Math.pow(extLength, 2) / (Math.pow(fhlGradient, 2) + 1)
    );
    const xExtPerp = Math.sqrt(
      Math.pow(extLength, 2) / (Math.pow(fhlPerpGradient, 2) + 1)
    );
    const extLengthLong = fhlLength / 2; //arbitrary
    const xExtLong = Math.sqrt(
      Math.pow(extLengthLong, 2) / (Math.pow(fhlGradient, 2) + 1)
    );
    const xExtPerpLong = Math.sqrt(
      Math.pow(extLengthLong, 2) / (Math.pow(fhlPerpGradient, 2) + 1)
    );

    //Calculate gradients and line extension distances for the Sacral Base Line (SBL)
    const sblGradient =
      (handle10Canvas.y - s1FacetBaseHandleCanvas.y) /
      (handle10Canvas.x - s1FacetBaseHandleCanvas.x);
    const sblPerpGradient = -1 * (1 / sblGradient);
    const sblLength = Math.sqrt(
      Math.pow(handle10Canvas.x - s1FacetBaseHandleCanvas.x, 2) +
        Math.pow(handle10Canvas.y - s1FacetBaseHandleCanvas.y, 2)
    );
    const sblxExt = Math.sqrt(
      Math.pow(extLengthLong, 2) / (Math.pow(sblGradient, 2) + 1)
    );
    const sblxExtPerp = Math.sqrt(
      Math.pow(extLengthLong, 2) / (Math.pow(sblPerpGradient, 2) + 1)
    );

    //Calculate FHL, Iliac Crest and Ischial Tuberosity lines
    //const fhl = {xStart:(handle1Canvas.x - xExt), yStart:(handle1Canvas.y - fhlGradient*xExt), xEnd:(handle2Canvas.x + xExt), yEnd:(handle2Canvas.y + fhlGradient*xExt)};
    const line_fhl = {
      start: {
        x: handle1Canvas.x - xExt,
        y: handle1Canvas.y - fhlGradient * xExt,
      },
      end: {
        x: handle2Canvas.x + xExt,
        y: handle2Canvas.y + fhlGradient * xExt,
      },
    };
    //const iliacCrestRLine = {xStart:(handle3Canvas.x - xExt), yStart:(handle3Canvas.y - fhlGradient*xExt), xEnd:(handle3Canvas.x + xExt), yEnd:(handle3Canvas.y + fhlGradient*xExt)};
    const line_iliacCrestR = {
      start: {
        x: handle3Canvas.x - xExt,
        y: handle3Canvas.y - fhlGradient * xExt,
      },
      end: {
        x: handle3Canvas.x + xExt,
        y: handle3Canvas.y + fhlGradient * xExt,
      },
    };
    //const ischialTuberosityRLine = {xStart:(handle4Canvas.x - xExt), yStart:(handle4Canvas.y - fhlGradient*xExt), xEnd:(handle4Canvas.x + xExt), yEnd:(handle4Canvas.y + fhlGradient*xExt)};
    const line_ischialTuberosityR = {
      start: {
        x: handle4Canvas.x - xExt,
        y: handle4Canvas.y - fhlGradient * xExt,
      },
      end: {
        x: handle4Canvas.x + xExt,
        y: handle4Canvas.y + fhlGradient * xExt,
      },
    };
    //const iliacCrestLLine = {xStart:(handle5Canvas.x - xExt), yStart:(handle5Canvas.y - fhlGradient*xExt), xEnd:(handle5Canvas.x + xExt), yEnd:(handle5Canvas.y + fhlGradient*xExt)};
    const line_iliacCrestL = {
      start: {
        x: handle5Canvas.x - xExt,
        y: handle5Canvas.y - fhlGradient * xExt,
      },
      end: {
        x: handle5Canvas.x + xExt,
        y: handle5Canvas.y + fhlGradient * xExt,
      },
    };
    //const ischialTuberosityLLine = {xStart:(leftIschialTuberosityHandleCanvas.x - xExt), yStart:(leftIschialTuberosityHandleCanvas.y - fhlGradient*xExt), xEnd:(leftIschialTuberosityHandleCanvas.x + xExt), yEnd:(leftIschialTuberosityHandleCanvas.y + fhlGradient*xExt)};
    const line_ischialTuberosityL = {
      start: {
        x: leftIschialTuberosityHandleCanvas.x - xExt,
        y: leftIschialTuberosityHandleCanvas.y - fhlGradient * xExt,
      },
      end: {
        x: leftIschialTuberosityHandleCanvas.x + xExt,
        y: leftIschialTuberosityHandleCanvas.y + fhlGradient * xExt,
      },
    };

    //Calculate the S2 Tubercle Line
    const s2TubercleStartX =
      (fhlPerpGradient * s2TubercleHandleCanvas.x -
        s2TubercleHandleCanvas.y -
        sblGradient * s1FacetBaseHandleCanvas.x +
        s1FacetBaseHandleCanvas.y) /
      (fhlPerpGradient - sblGradient);
    const s2TubercleStartY =
      (sblGradient * fhlPerpGradient * s2TubercleHandleCanvas.x -
        sblGradient * s2TubercleHandleCanvas.y -
        fhlPerpGradient * sblGradient * s1FacetBaseHandleCanvas.x +
        fhlPerpGradient * s1FacetBaseHandleCanvas.y) /
      (fhlPerpGradient - sblGradient);
    const s2TubercleEndX =
      (fhlPerpGradient * s2TubercleHandleCanvas.x -
        s2TubercleHandleCanvas.y -
        fhlGradient * handle8Canvas.x +
        handle8Canvas.y) /
      (fhlPerpGradient - fhlGradient); //X coordinate of oint perpendicular to pubicSymphysisHandle
    const s2TubercleEndY =
      (fhlGradient * fhlPerpGradient * s2TubercleHandleCanvas.x -
        fhlGradient * s2TubercleHandleCanvas.y -
        fhlGradient * fhlPerpGradient * handle8Canvas.x +
        fhlPerpGradient * handle8Canvas.y) /
      (fhlPerpGradient - fhlGradient); //Y coordinate of oint perpendicular to pubicSymphysisHandle
    //const s2TubercleLine = {xStart:(s2TubercleStartX), yStart:(s2TubercleStartY), xEnd:(fhlGradient < 0 ? s2TubercleEndX + xExtPerp : s2TubercleEndX - xExtPerp), yEnd:(fhlGradient < 0? s2TubercleEndY + fhlPerpGradient*xExtPerp : s2TubercleEndY - fhlPerpGradient*xExtPerp)};
    const line_s2Tubercle = {
      start: { x: s2TubercleStartX, y: s2TubercleStartY },
      end: {
        x:
          fhlGradient < 0
            ? s2TubercleEndX + xExtPerp
            : s2TubercleEndX - xExtPerp,
        y:
          fhlGradient < 0
            ? s2TubercleEndY + fhlPerpGradient * xExtPerp
            : s2TubercleEndY - fhlPerpGradient * xExtPerp,
      },
    };

    //Calculate the Pubic Symphysis line
    //const pubicSymphysisLine = {xStart:(handle8Canvas.x - xExtPerp), yStart:(handle8Canvas.y - fhlPerpGradient*xExtPerp), xEnd:(handle8Canvas.x + xExtPerp), yEnd:(handle8Canvas.y + fhlPerpGradient*xExtPerp)};
    const line_pubicSymphysis = {
      start: {
        x: handle8Canvas.x - xExtPerp,
        y: handle8Canvas.y - fhlPerpGradient * xExtPerp,
      },
      end: {
        x: handle8Canvas.x + xExtPerp,
        y: handle8Canvas.y + fhlPerpGradient * xExtPerp,
      },
    };

    //Calculate the Sacral Base Line (SBL)
    //const sbl = {xStart:(s1FacetBaseHandleCanvas.x - sblxExt), yStart:(s1FacetBaseHandleCanvas.y - sblGradient*sblxExt), xEnd:(handle10Canvas.x + sblxExt), yEnd:(handle10Canvas.y + sblGradient*sblxExt)};
    const line_sbl = {
      start: {
        x: s1FacetBaseHandleCanvas.x - sblxExt,
        y: s1FacetBaseHandleCanvas.y - sblGradient * sblxExt,
      },
      end: {
        x: handle10Canvas.x + sblxExt,
        y: handle10Canvas.y + sblGradient * sblxExt,
      },
    };

    //Calculate the Sacral Wing lines
    const rightSacralWingStartX =
      (fhlPerpGradient * handle11Canvas.x -
        handle11Canvas.y -
        sblGradient * s1FacetBaseHandleCanvas.x +
        s1FacetBaseHandleCanvas.y) /
      (fhlPerpGradient - sblGradient);
    const rightSacralWingStartY =
      (sblGradient * fhlPerpGradient * handle11Canvas.x -
        sblGradient * handle11Canvas.y -
        fhlPerpGradient * sblGradient * s1FacetBaseHandleCanvas.x +
        fhlPerpGradient * s1FacetBaseHandleCanvas.y) /
      (fhlPerpGradient - sblGradient);
    //const rightSacralWingLine = {xStart:(rightSacralWingStartX), yStart:(rightSacralWingStartY), xEnd:(fhlGradient < 0 ? handle11Canvas.x + xExtPerp : handle11Canvas.x - xExtPerp), yEnd:(fhlGradient < 0 ? handle11Canvas.y + fhlPerpGradient*xExtPerp : handle11Canvas.y - fhlPerpGradient*xExtPerp)};
    const line_rightSacralWing = {
      start: { x: rightSacralWingStartX, y: rightSacralWingStartY },
      end: {
        x:
          fhlGradient < 0
            ? handle11Canvas.x + xExtPerp
            : handle11Canvas.x - xExtPerp,
        y:
          fhlGradient < 0
            ? handle11Canvas.y + fhlPerpGradient * xExtPerp
            : handle11Canvas.y - fhlPerpGradient * xExtPerp,
      },
    };
    const leftSacralWingStartX =
      (fhlPerpGradient * handle12Canvas.x -
        handle12Canvas.y -
        sblGradient * s1FacetBaseHandleCanvas.x +
        s1FacetBaseHandleCanvas.y) /
      (fhlPerpGradient - sblGradient);
    const leftSacralWingStartY =
      (sblGradient * fhlPerpGradient * handle12Canvas.x -
        sblGradient * handle12Canvas.y -
        fhlPerpGradient * sblGradient * s1FacetBaseHandleCanvas.x +
        fhlPerpGradient * s1FacetBaseHandleCanvas.y) /
      (fhlPerpGradient - sblGradient);
    //const leftSacralWingLine = {xStart:(leftSacralWingStartX), yStart:(leftSacralWingStartY), xEnd:(fhlGradient < 0 ? handle12Canvas.x + xExtPerp : handle12Canvas.x - xExtPerp), yEnd:(fhlGradient < 0 ? handle12Canvas.y + fhlPerpGradient*xExtPerp : handle12Canvas.y - fhlPerpGradient*xExtPerp)};
    const line_leftSacralWing = {
      start: { x: leftSacralWingStartX, y: leftSacralWingStartY },
      end: {
        x:
          fhlGradient < 0
            ? handle12Canvas.x + xExtPerp
            : handle12Canvas.x - xExtPerp,
        y:
          fhlGradient < 0
            ? handle12Canvas.y + fhlPerpGradient * xExtPerp
            : handle12Canvas.y - fhlPerpGradient * xExtPerp,
      },
    };

    //Calculate the Measured Deficiency (MD) point, line and distance
    const mdPoint =
      handle1Canvas.y <= handle2Canvas.y
        ? { x: handle2Canvas.x, y: handle1Canvas.y }
        : { x: handle1Canvas.x, y: handle2Canvas.y }; //Measured Deficiency point
    //const mdPointLine = {xStart:(mdPoint.x - xExt), yStart:(mdPoint.y), xEnd:(mdPoint.x + xExt), yEnd:(mdPoint.y)};
    const line_mdPoint = {
      start: { x: mdPoint.x - xExt, y: mdPoint.y },
      end: { x: mdPoint.x + xExt, y: mdPoint.y },
    };
    const dist_md = Math.abs(handle2Canvas.y - handle1Canvas.y);
    const dist_md_px = Math.abs(
      data.handles.leftFemurHeadHandle.y - data.handles.rightFemurHeadHandle.y
    );

    //Calculate the Iliac Wing and Posterior Superior Iliac Spine lines
    //const rightIliacWingLine = {xStart:(handle13Canvas.x - xExtPerp), yStart:(handle13Canvas.y - fhlPerpGradient*xExtPerp), xEnd:(handle13Canvas.x + xExtPerp), yEnd:(handle13Canvas.y + fhlPerpGradient*xExtPerp)};
    const line_rightIliacWing = {
      start: {
        x: handle13Canvas.x - xExtPerp,
        y: handle13Canvas.y - fhlPerpGradient * xExtPerp,
      },
      end: {
        x: handle13Canvas.x + xExtPerp,
        y: handle13Canvas.y + fhlPerpGradient * xExtPerp,
      },
    };
    //const leftIliacWingLine = {xStart:(handle14Canvas.x - xExtPerp), yStart:(handle14Canvas.y - fhlPerpGradient*xExtPerp), xEnd:(handle14Canvas.x + xExtPerp), yEnd:(handle14Canvas.y + fhlPerpGradient*xExtPerp)};
    const line_leftIliacWing = {
      start: {
        x: handle14Canvas.x - xExtPerp,
        y: handle14Canvas.y - fhlPerpGradient * xExtPerp,
      },
      end: {
        x: handle14Canvas.x + xExtPerp,
        y: handle14Canvas.y + fhlPerpGradient * xExtPerp,
      },
    };
    //const rightPSISLine = {xStart:(handle15Canvas.x - xExtPerp), yStart:(handle15Canvas.y - fhlPerpGradient*xExtPerp), xEnd:(handle15Canvas.x + xExtPerp), yEnd:(handle15Canvas.y + fhlPerpGradient*xExtPerp)};
    const line_rightPSIS = {
      start: {
        x: handle15Canvas.x - xExtPerp,
        y: handle15Canvas.y - fhlPerpGradient * xExtPerp,
      },
      end: {
        x: handle15Canvas.x + xExtPerp,
        y: handle15Canvas.y + fhlPerpGradient * xExtPerp,
      },
    };
    //const leftPSISLine = {xStart:(handle16Canvas.x - xExtPerp), yStart:(handle16Canvas.y - fhlPerpGradient*xExtPerp), xEnd:(handle16Canvas.x + xExtPerp), yEnd:(handle16Canvas.y + fhlPerpGradient*xExtPerp)};
    const line_leftPSIS = {
      start: {
        x: handle16Canvas.x - xExtPerp,
        y: handle16Canvas.y - fhlPerpGradient * xExtPerp,
      },
      end: {
        x: handle16Canvas.x + xExtPerp,
        y: handle16Canvas.y + fhlPerpGradient * xExtPerp,
      },
    };

    //Calculate the perpendicular points and distances for the Iliac Crest lines and Ischial Tuberosity lines
    const perpPoint_iliacCrestRLine_fhl = {
      x:
        (fhlPerpGradient * handle3Canvas.x -
          fhlGradient * handle1Canvas.x +
          handle1Canvas.y -
          handle3Canvas.y) /
        (fhlPerpGradient - fhlGradient),
      y:
        (fhlPerpGradient * handle1Canvas.y -
          fhlPerpGradient * fhlGradient * handle1Canvas.x +
          fhlPerpGradient * fhlGradient * handle3Canvas.x -
          fhlGradient * handle3Canvas.y) /
        (fhlPerpGradient - fhlGradient),
    };
    const perpPoint_iliacCrestRLine_fhl_px = cornerstone.canvasToPixel(
      element,
      perpPoint_iliacCrestRLine_fhl
    );
    const dist_iliacCrestRLine_fhl = Math.sqrt(
      Math.pow(handle3Canvas.x - perpPoint_iliacCrestRLine_fhl_px.x, 2) +
        Math.pow(
          data.handles.rightIliacCrestHandle.y -
            perpPoint_iliacCrestRLine_fhl_px.y,
          2
        )
    ); //Distance between rightIliacCrestHandle and fhl line
    const dist_iliacCrestRLine_fhl_px = Math.sqrt(
      Math.pow(
        data.handles.rightIliacCrestHandle.x - perpPoint_iliacCrestRLine_fhl.x,
        2
      ) + Math.pow(handle3Canvas.y - perpPoint_iliacCrestRLine_fhl.y, 2)
    );
    const perpPoint_iliacCrestLLine_fhl = {
      x:
        (fhlPerpGradient * handle5Canvas.x -
          fhlGradient * handle1Canvas.x +
          handle1Canvas.y -
          handle5Canvas.y) /
        (fhlPerpGradient - fhlGradient),
      y:
        (fhlPerpGradient * handle1Canvas.y -
          fhlPerpGradient * fhlGradient * handle1Canvas.x +
          fhlPerpGradient * fhlGradient * handle5Canvas.x -
          fhlGradient * handle5Canvas.y) /
        (fhlPerpGradient - fhlGradient),
    };
    const perpPoint_iliacCrestLLine_fhl_px = cornerstone.canvasToPixel(
      element,
      perpPoint_iliacCrestLLine_fhl
    );
    const dist_iliacCrestLLine_fhl = Math.sqrt(
      Math.pow(handle5Canvas.x - perpPoint_iliacCrestLLine_fhl.x, 2) +
        Math.pow(handle5Canvas.y - perpPoint_iliacCrestLLine_fhl.y, 2)
    ); //Distance between leftIliacCrestHandle and fhl line
    const dist_iliacCrestLLine_fhl_px = Math.sqrt(
      Math.pow(
        data.handles.leftIliacCrestHandle.x -
          perpPoint_iliacCrestLLine_fhl_px.x,
        2
      ) +
        Math.pow(
          data.handles.leftIliacCrestHandle.y -
            perpPoint_iliacCrestLLine_fhl_px.y,
          2
        )
    );
    const perpPoint_ischialTuberosityRLine_fhl = {
      x:
        (fhlPerpGradient * handle4Canvas.x -
          fhlGradient * handle1Canvas.x +
          handle1Canvas.y -
          handle4Canvas.y) /
        (fhlPerpGradient - fhlGradient),
      y:
        (fhlPerpGradient * handle1Canvas.y -
          fhlPerpGradient * fhlGradient * handle1Canvas.x +
          fhlPerpGradient * fhlGradient * handle4Canvas.x -
          fhlGradient * handle4Canvas.y) /
        (fhlPerpGradient - fhlGradient),
    };
    const perpPoint_ischialTuberosityRLine_fhl_px = cornerstone.canvasToPixel(
      element,
      perpPoint_ischialTuberosityRLine_fhl
    );
    const dist_ischialTuberosityRLine_fhl = Math.sqrt(
      Math.pow(handle4Canvas.x - perpPoint_ischialTuberosityRLine_fhl.x, 2) +
        Math.pow(handle4Canvas.y - perpPoint_ischialTuberosityRLine_fhl.y, 2)
    ); //Distance between rightIschialTuberosityHandle and fhl line
    const dist_ischialTuberosityRLine_fhl_px = Math.sqrt(
      Math.pow(
        data.handles.rightIschialTuberosityHandle.x -
          perpPoint_ischialTuberosityRLine_fhl_px.x,
        2
      ) +
        Math.pow(
          data.handles.rightIschialTuberosityHandle.y -
            perpPoint_ischialTuberosityRLine_fhl_px.y,
          2
        )
    );
    const perpPoint_ischialTuberosityLLine_fhl = {
      x:
        (fhlPerpGradient * leftIschialTuberosityHandleCanvas.x -
          fhlGradient * handle1Canvas.x +
          handle1Canvas.y -
          leftIschialTuberosityHandleCanvas.y) /
        (fhlPerpGradient - fhlGradient),
      y:
        (fhlPerpGradient * handle1Canvas.y -
          fhlPerpGradient * fhlGradient * handle1Canvas.x +
          fhlPerpGradient * fhlGradient * leftIschialTuberosityHandleCanvas.x -
          fhlGradient * leftIschialTuberosityHandleCanvas.y) /
        (fhlPerpGradient - fhlGradient),
    };
    const perpPoint_ischialTuberosityLLine_fhl_px = cornerstone.canvasToPixel(
      element,
      perpPoint_ischialTuberosityLLine_fhl
    );
    const dist_ischialTuberosityLLine_fhl = Math.sqrt(
      Math.pow(
        leftIschialTuberosityHandleCanvas.x -
          perpPoint_ischialTuberosityLLine_fhl.x,
        2
      ) +
        Math.pow(
          leftIschialTuberosityHandleCanvas.y -
            perpPoint_ischialTuberosityLLine_fhl.y,
          2
        )
    ); //Distance between leftIschialTuberosityHandle and fhl line
    const dist_ischialTuberosityLLine_fhl_px = Math.sqrt(
      Math.pow(
        data.handles.leftIschialTuberosityHandle.x -
          perpPoint_ischialTuberosityLLine_fhl_px.x,
        2
      ) +
        Math.pow(
          data.handles.leftIschialTuberosityHandle.y -
            perpPoint_ischialTuberosityLLine_fhl_px.y,
          2
        )
    );
    //Note, the above calculations should have been from the Iliac Crest lines to the Ischial Tuberosity lines without stopping at the FHL. Redo if necessary.
    const dist_iliacCrestRLine_ischialTuberosityRLine =
      dist_iliacCrestRLine_fhl + dist_ischialTuberosityRLine_fhl;
    const dist_iliacCrestRLine_ischialTuberosityRLine_px =
      dist_iliacCrestRLine_fhl_px + dist_ischialTuberosityRLine_fhl_px;
    const dist_iliacCrestLLine_ischialTuberosityLLine =
      dist_iliacCrestLLine_fhl + dist_ischialTuberosityLLine_fhl;
    const dist_iliacCrestLLine_ischialTuberosityLLine_px =
      dist_iliacCrestLLine_fhl_px + dist_ischialTuberosityLLine_fhl_px;

    //Calculate the parallel line and distance between the S2 TUbercle and the Pubic Symphysis
    const perpPoint_S2TubercleLine_pubicSymphysisLine = {
      x:
        (fhlPerpGradient * s2TubercleHandleCanvas.x -
          fhlGradient * handle8Canvas.x -
          s2TubercleHandleCanvas.y +
          handle8Canvas.y) /
        (fhlPerpGradient - fhlGradient),
      y:
        (fhlPerpGradient * handle8Canvas.y -
          fhlGradient * s2TubercleHandleCanvas.y +
          fhlPerpGradient * fhlGradient * s2TubercleHandleCanvas.x -
          fhlPerpGradient * fhlGradient * handle8Canvas.x) /
        (fhlPerpGradient - fhlGradient),
    };
    const perpPoint_S2TubercleLine_pubicSymphysisLine_px =
      cornerstone.canvasToPixel(
        element,
        perpPoint_S2TubercleLine_pubicSymphysisLine
      );
    const dist_S2TubercleLine_pubicSymphysisLine = Math.sqrt(
      Math.pow(
        handle8Canvas.x - perpPoint_S2TubercleLine_pubicSymphysisLine.x,
        2
      ) +
        Math.pow(
          handle8Canvas.y - perpPoint_S2TubercleLine_pubicSymphysisLine.y,
          2
        )
    ); //Distance between pubicSymphysisHandle and the perpendicular point on S2 Tubercle Line
    const dist_S2TubercleLine_pubicSymphysisLine_px = Math.sqrt(
      Math.pow(
        data.handles.pubicSymphysisHandle.x -
          perpPoint_S2TubercleLine_pubicSymphysisLine_px.x,
        2
      ) +
        Math.pow(
          data.handles.pubicSymphysisHandle.y -
            perpPoint_S2TubercleLine_pubicSymphysisLine_px.y,
          2
        )
    );

    //Calculate the distances between the calculated points on the SBL
    const point_rightSacralWingStart = {
      x: rightSacralWingStartX,
      y: rightSacralWingStartY,
    };
    const point_rightSacralWingStart_px = cornerstone.canvasToPixel(
      element,
      point_rightSacralWingStart
    );
    const point_s2TubercleStart = { x: s2TubercleStartX, y: s2TubercleStartY };
    const point_s2TubercleStart_px = cornerstone.canvasToPixel(
      element,
      point_s2TubercleStart
    );
    const dist_rightSacralWingOnSbl_s2TubercleOnSbl = Math.sqrt(
      Math.pow(rightSacralWingStartX - s2TubercleStartX, 2) +
        Math.pow(rightSacralWingStartY - s2TubercleStartY, 2)
    );
    const dist_rightSacralWingOnSbl_s2TubercleOnSbl_px = Math.sqrt(
      Math.pow(
        point_rightSacralWingStart_px.x - point_s2TubercleStart_px.x,
        2
      ) +
        Math.pow(
          point_rightSacralWingStart_px.y - point_s2TubercleStart_px.y,
          2
        )
    );
    const point_leftSacralWingStart = {
      x: leftSacralWingStartX,
      y: leftSacralWingStartY,
    };
    const point_leftSacralWingStart_px = cornerstone.canvasToPixel(
      element,
      point_leftSacralWingStart
    );
    const dist_leftSacralWingOnSbl_s2TubercleOnSbl = Math.sqrt(
      Math.pow(leftSacralWingStartX - s2TubercleStartX, 2) +
        Math.pow(leftSacralWingStartY - s2TubercleStartY, 2)
    );
    const dist_leftSacralWingOnSbl_s2TubercleOnSbl_px = Math.sqrt(
      Math.pow(point_leftSacralWingStart_px.x - point_s2TubercleStart_px.x, 2) +
        Math.pow(point_leftSacralWingStart_px.y - point_s2TubercleStart_px.y, 2)
    );

    //Calculate the perpendicular points and distances between the Iliac Wing and Posterior Superior Iliac Spine lines
    const perpPoint_rightIliacWingLine_rightPSISLine = {
      x:
        (fhlPerpGradient * handle13Canvas.x -
          fhlGradient * handle15Canvas.x -
          handle13Canvas.y +
          handle15Canvas.y) /
        (fhlPerpGradient - fhlGradient),
      y:
        (fhlPerpGradient * handle15Canvas.y -
          fhlGradient * handle13Canvas.y +
          fhlPerpGradient * fhlGradient * handle13Canvas.x -
          fhlPerpGradient * fhlGradient * handle15Canvas.x) /
        (fhlPerpGradient - fhlGradient),
    };
    const perpPoint_rightIliacWingLine_rightPSISLine_px =
      cornerstone.canvasToPixel(
        element,
        perpPoint_rightIliacWingLine_rightPSISLine
      );
    const dist_rightIliacWingLine_rightPSISLine = Math.sqrt(
      Math.pow(
        handle15Canvas.x - perpPoint_rightIliacWingLine_rightPSISLine.x,
        2
      ) +
        Math.pow(
          handle15Canvas.y - perpPoint_rightIliacWingLine_rightPSISLine.y,
          2
        )
    ); //Distance between rightPsisHandle and the perpendicular point on Right Iliac Wing Line
    const dist_rightIliacWingLine_rightPSISLine_px = Math.sqrt(
      Math.pow(
        data.handles.rightPsisHandle.x -
          perpPoint_rightIliacWingLine_rightPSISLine_px.x,
        2
      ) +
        Math.pow(
          data.handles.rightPsisHandle.y -
            perpPoint_rightIliacWingLine_rightPSISLine_px.y,
          2
        )
    );
    const perpPoint_leftIliacWingLine_leftPSISLine = {
      x:
        (fhlPerpGradient * handle14Canvas.x -
          fhlGradient * handle16Canvas.x -
          handle14Canvas.y +
          handle16Canvas.y) /
        (fhlPerpGradient - fhlGradient),
      y:
        (fhlPerpGradient * handle16Canvas.y -
          fhlGradient * handle14Canvas.y +
          fhlPerpGradient * fhlGradient * handle14Canvas.x -
          fhlPerpGradient * fhlGradient * handle16Canvas.x) /
        (fhlPerpGradient - fhlGradient),
    };
    const perpPoint_leftIliacWingLine_leftPSISLine_px =
      cornerstone.canvasToPixel(
        element,
        perpPoint_leftIliacWingLine_leftPSISLine
      );
    const dist_leftIliacWingLine_leftPSISLine = Math.sqrt(
      Math.pow(
        handle16Canvas.x - perpPoint_leftIliacWingLine_leftPSISLine.x,
        2
      ) +
        Math.pow(
          handle16Canvas.y - perpPoint_leftIliacWingLine_leftPSISLine.y,
          2
        )
    ); //Distance between rightPsisHandle and the perpendicular point on Left Iliac Wing Line
    const dist_leftIliacWingLine_leftPSISLine_px = Math.sqrt(
      Math.pow(
        data.handles.leftPsisHandle.x -
          perpPoint_leftIliacWingLine_leftPSISLine_px.x,
        2
      ) +
        Math.pow(
          data.handles.leftPsisHandle.y -
            perpPoint_leftIliacWingLine_leftPSISLine_px.y,
          2
        )
    );

    return {
      handle1Canvas: handle1Canvas,
      handle2Canvas: handle2Canvas,
      handle3Canvas: handle3Canvas,
      handle4Canvas: handle4Canvas,
      handle5Canvas: handle4Canvas,
      leftIschialTuberosityHandleCanvas: handle4Canvas,
      s2TubercleHandleCanvas: handle4Canvas,
      handle8Canvas: handle4Canvas,
      s1FacetBaseHandleCanvas: handle4Canvas,
      handle10Canvas: handle4Canvas,
      handle11Canvas: handle4Canvas,
      handle12Canvas: handle4Canvas,
      handle13Canvas: handle4Canvas,
      handle14Canvas: handle4Canvas,
      handle15Canvas: handle4Canvas,
      handle16Canvas: handle4Canvas,

      //Lines
      line_fhl,
      line_iliacCrestR,
      line_ischialTuberosityR,
      line_iliacCrestL,
      line_ischialTuberosityL,
      line_s2Tubercle,
      line_pubicSymphysis,
      line_sbl,
      line_rightSacralWing,
      line_leftSacralWing,
      line_mdPoint,
      line_rightIliacWing,
      line_leftIliacWing,
      line_rightPSIS,
      line_leftPSIS,

      //Calculated Points
      mdPoint,
      perpPoint_iliacCrestRLine_fhl,
      perpPoint_iliacCrestLLine_fhl,
      perpPoint_ischialTuberosityRLine_fhl,
      perpPoint_ischialTuberosityLLine_fhl,
      perpPoint_S2TubercleLine_pubicSymphysisLine,
      point_rightSacralWingStart,
      point_s2TubercleStart,
      point_leftSacralWingStart,
      perpPoint_rightIliacWingLine_rightPSISLine,
      perpPoint_leftIliacWingLine_leftPSISLine,

      //Measurements in canvas units
      dist_md,
      dist_iliacCrestRLine_fhl,
      dist_iliacCrestLLine_fhl,
      dist_ischialTuberosityRLine_fhl,
      dist_ischialTuberosityLLine_fhl,
      dist_iliacCrestRLine_ischialTuberosityRLine,
      dist_iliacCrestLLine_ischialTuberosityLLine,
      dist_S2TubercleLine_pubicSymphysisLine,
      dist_rightSacralWingOnSbl_s2TubercleOnSbl,
      dist_leftSacralWingOnSbl_s2TubercleOnSbl,
      dist_rightIliacWingLine_rightPSISLine,
      dist_leftIliacWingLine_leftPSISLine,

      //Measurements in pixels
      dist_md_px,
      dist_iliacCrestRLine_fhl_px,
      dist_iliacCrestLLine_fhl_px,
      dist_ischialTuberosityRLine_fhl_px,
      dist_ischialTuberosityLLine_fhl_px,
      dist_iliacCrestRLine_ischialTuberosityRLine_px,
      dist_iliacCrestLLine_ischialTuberosityLLine_px,
      dist_S2TubercleLine_pubicSymphysisLine_px,
      dist_rightSacralWingOnSbl_s2TubercleOnSbl_px,
      dist_leftSacralWingOnSbl_s2TubercleOnSbl_px,
      dist_rightIliacWingLine_rightPSISLine_px,
      dist_leftIliacWingLine_leftPSISLine_px,
    };
  }

  drawToolData(element, context, toolData) {
    const {
      handleRadius,
      prePlacementHandleRadius,
      circleColour,
      circleColourOutsideImage,
      labelColour, //'white'
      measurementColour, //'orange',
      lineColour, //'lime',
      lineColourOutsideImage,
    } = GONSTEAD_CONFIGURATION;

    var allHandlesInsideImage = true;

    Drawing.draw(context, (context) => {
      const {
        visible,
        handles,
        prePlacementHandles,
        rowPixelSpacing,
        colPixelSpacing,
      } = toolData;
      if (visible) {
        //Draw the pre-placement handle
        if (handles.leftPsisHandle.placed == false) {
          //If all the handles are placed (i.e. the last handle, leftPsisHandle, is placed), then don't draw the pre-placement handle
          Drawing.drawHandles(context, { element }, prePlacementHandles, {
            handleRadius: prePlacementHandleRadius,
            color: prePlacementHandles.handle.insideImage
              ? circleColour
              : circleColourOutsideImage,
          });
        }
        Object.keys(handles).map((key) => {
          const handle = handles[key];

          if (!handle.insideImage) {
            allHandlesInsideImage = false;
          }

          //If the handle is a handle point (not a textBox)
          if (handle.placed == true && !handle.handleKey) {
            Drawing.drawCircle(
              context,
              element,
              handle,
              handleRadius,
              {
                color: handle.insideImage
                  ? circleColour
                  : circleColourOutsideImage,
              }, //circleOptions,
              "pixel"
            );
          }
          if (handle.placed == true && handle.handleKey) {
            const correspondingHandle = handles[handle.handleKey];
            const textCoords = cornerstone.pixelToCanvas(element, handle);

            //Determine the position of the text
            if (!handle.hasMoved && handle.boundingBox) {
              const textBoxHeight = handle.boundingBox.height; ///pixelsPerCanvasPixel;
              const textBoxLeft = handle.boundingBox.left; ///pixelsPerCanvasPixel;
              const textBoxTop = handle.boundingBox.top; ///pixelsPerCanvasPixel;
              const textBoxWidth = handle.boundingBox.width; ///pixelsPerCanvasPixel;
              //console.log(
              //   "" +
              //     key +
              //     ": TB Left: " +
              //     textBoxLeft +
              //     ", TB Top: " +
              //     textBoxTop +
              //     ", TB Widgth: " +
              //     textBoxWidth +
              //     ", TB Height: " +
              //     textBoxHeight
              // );
              var xOffset;
              var yOffset;
              if (correspondingHandle.label.includes("R")) {
                xOffset = -3.5 * textBoxWidth; //-500;
              } else if (correspondingHandle.label.includes("L")) {
                xOffset = textBoxWidth / 2; //100;//1;
              } else {
                xOffset = -1.5 * textBoxWidth; //250;
              }
              if (
                key == "rightS1FacetBaseTextBox" ||
                key == "leftS1FacetBaseTextBox"
              ) {
                yOffset = -100;
              } else {
                yOffset = 40;
              }
              handle.x = correspondingHandle.x + xOffset; //positionDeterminer*textBoxWidth/2;
              handle.y = correspondingHandle.y + yOffset;
              ////console.log('pixelspercanvaspixel: ', pixelsPerCanvasPixel);

              //For testing texbox boundaries
              /*
                            Drawing.drawLine(
                                context, //Target context (CanvasRenderingContext2D)
                                element,//event.currentTarget, //The DOM Element to draw on (HTMLElement)
                                {x: 200, y:200},//{x: textBoxLeft, y: textBoxTop},//{x,y}
                                {x:300, y: 300},//{x: textBoxLeft + textBoxWidth, y: textBoxTop + textBoxHeight.y}, //{x,y}
                                {color: 'red'}, //See path
                                'pixel'
                            );
                            Drawing.drawLine(
                                context, //Target context (CanvasRenderingContext2D)
                                element,//event.currentTarget, //The DOM Element to draw on (HTMLElement)
                                {x: textBoxLeft, y: textBoxTop},//{x,y}
                                {x: textBoxLeft + textBoxWidth, y: textBoxTop + textBoxHeight.y}, //{x,y}
                                {color: 'orange'}, //See path
                                'pixel'
                            );
                            */
            }
            drawLinkedTextBox(
              context, //The canvas context.
              element, //The element on which to draw the link. HTMLElement
              handle, //The textBox to link.
              correspondingHandle.label, //The text to display in the textBox.
              handles, //The handles of the annotation.
              function () {
                return [correspondingHandle];
              }, //textBoxAnchorPoints, //An array of possible anchor points on the textBox.
              labelColour, //'white', //The link color.
              2, //The line width of the link.
              0, //The x offset of the textBox.
              false //Vertically centers the text if true.
            );
          }
        });

        const {
          line_fhl,
          line_iliacCrestR,
          line_ischialTuberosityR,
          line_iliacCrestL,
          line_ischialTuberosityL,
          line_s2Tubercle,
          line_pubicSymphysis,
          line_sbl,
          line_rightSacralWing,
          line_leftSacralWing,
          line_mdPoint,
          line_rightIliacWing,
          line_leftIliacWing,
          line_rightPSIS,
          line_leftPSIS,
          mdPoint,
          perpPoint_iliacCrestRLine_fhl,
          perpPoint_iliacCrestLLine_fhl,
          perpPoint_ischialTuberosityRLine_fhl,
          perpPoint_ischialTuberosityLLine_fhl,
          perpPoint_S2TubercleLine_pubicSymphysisLine,
          point_rightSacralWingStart,
          point_s2TubercleStart,
          point_leftSacralWingStart,
          perpPoint_rightIliacWingLine_rightPSISLine,
          perpPoint_leftIliacWingLine_leftPSISLine,
        } = this.getToolGeometry(element, toolData);

        const mdPoint_px = cornerstone.canvasToPixel(
          element,
          mdPoint as CanvasCoordinate
        );
        const perpPoint_iliacCrestRLine_fhl_px = cornerstone.canvasToPixel(
          element,
          perpPoint_iliacCrestRLine_fhl as CanvasCoordinate
        );
        const perpPoint_iliacCrestLLine_fhl_px = cornerstone.canvasToPixel(
          element,
          perpPoint_iliacCrestLLine_fhl as CanvasCoordinate
        );
        const perpPoint_ischialTuberosityRLine_fhl_px =
          cornerstone.canvasToPixel(
            element,
            perpPoint_ischialTuberosityRLine_fhl as CanvasCoordinate
          );
        const perpPoint_ischialTuberosityLLine_fhl_px =
          cornerstone.canvasToPixel(
            element,
            perpPoint_ischialTuberosityLLine_fhl as CanvasCoordinate
          );
        const perpPoint_S2TubercleLine_pubicSymphysisLine_px =
          cornerstone.canvasToPixel(
            element,
            perpPoint_S2TubercleLine_pubicSymphysisLine as CanvasCoordinate
          );
        const point_rightSacralWingStart_px = cornerstone.canvasToPixel(
          element,
          point_rightSacralWingStart as CanvasCoordinate
        );
        const point_s2TubercleStart_px = cornerstone.canvasToPixel(
          element,
          point_s2TubercleStart as CanvasCoordinate
        );
        const point_leftSacralWingStart_px = cornerstone.canvasToPixel(
          element,
          point_leftSacralWingStart as CanvasCoordinate
        );
        const perpPoint_rightIliacWingLine_rightPSISLine_px =
          cornerstone.canvasToPixel(
            element,
            perpPoint_rightIliacWingLine_rightPSISLine as CanvasCoordinate
          );
        const perpPoint_leftIliacWingLine_leftPSISLine_px =
          cornerstone.canvasToPixel(
            element,
            perpPoint_leftIliacWingLine_leftPSISLine as CanvasCoordinate
          );

        const lineOptions = allHandlesInsideImage
          ? { color: lineColour }
          : { color: lineColourOutsideImage };

        //Draw the FHL line
        if (
          handles.rightFemurHeadHandle.placed == true &&
          handles.leftFemurHeadHandle.placed == true
        ) {
          Drawing.drawLine(
            context, //Target context (CanvasRenderingContext2D)
            element, //event.currentTarget, //The DOM Element to draw on (HTMLElement)
            { x: line_fhl.start.x, y: line_fhl.start.y }, //{x,y}
            { x: line_fhl.end.x, y: line_fhl.end.y }, //{x,y}
            lineOptions, //See path
            "canvas"
          );
        }
        //Draw the right Iliac Crest Line (through rightIliacCrestHandle, parallel to FHL)
        if (handles.rightIliacCrestHandle.placed == true) {
          Drawing.drawLine(
            context, //Target context (CanvasRenderingContext2D)
            element, //event.currentTarget, //The DOM Element to draw on (HTMLElement)
            { x: line_iliacCrestR.start.x, y: line_iliacCrestR.start.y }, //{x,y}
            { x: line_iliacCrestR.end.x, y: line_iliacCrestR.end.y }, //{x,y}
            lineOptions, //See path
            "canvas"
          );
        }
        //Draw the right Ischial Tuberosity Line (through rightIschialTuberosityHandle, parallel to FHL)
        if (handles.rightIschialTuberosityHandle.placed == true) {
          Drawing.drawLine(
            context, //Target context (CanvasRenderingContext2D)
            element, //event.currentTarget, //The DOM Element to draw on (HTMLElement)
            {
              x: line_ischialTuberosityR.start.x,
              y: line_ischialTuberosityR.start.y,
            }, //{x,y}
            {
              x: line_ischialTuberosityR.end.x,
              y: line_ischialTuberosityR.end.y,
            }, //{x,y}
            lineOptions, //See path
            "canvas"
          );
        }
        //Draw the left Iliac Crest Line (through leftIliacCrestHandle, parallel to FHL)
        if (handles.leftIliacCrestHandle.placed == true) {
          Drawing.drawLine(
            context, //Target context (CanvasRenderingContext2D)
            element, //event.currentTarget, //The DOM Element to draw on (HTMLElement)
            { x: line_iliacCrestL.start.x, y: line_iliacCrestL.start.y }, //{x,y}
            { x: line_iliacCrestL.end.x, y: line_iliacCrestL.end.y }, //{x,y}
            lineOptions, //See path
            "canvas"
          );
        }
        //Draw the left Ischial Tuberosity Line (through leftIschialTuberosityHandle, parallel to FHL)
        if (handles.leftIschialTuberosityHandle.placed == true) {
          Drawing.drawLine(
            context, //Target context (CanvasRenderingContext2D)
            element, //event.currentTarget, //The DOM Element to draw on (HTMLElement)
            {
              x: line_ischialTuberosityL.start.x,
              y: line_ischialTuberosityL.start.y,
            }, //{x,y}
            {
              x: line_ischialTuberosityL.end.x,
              y: line_ischialTuberosityL.end.y,
            }, //{x,y}
            lineOptions, //See path
            "canvas"
          );
        }
        //Draw the S2 Tubercle Line (through s2TubercleHandle, perpendicular to FHL)
        if (
          handles.s2TubercleHandle.placed == true &&
          handles.pubicSymphysisHandle.placed == true &&
          handles.rightS1FacetBaseHandle.placed == true &&
          handles.leftS1FacetBaseHandle.placed == true
        ) {
          Drawing.drawLine(
            context, //Target context (CanvasRenderingContext2D)
            element, //event.currentTarget, //The DOM Element to draw on (HTMLElement)
            { x: line_s2Tubercle.start.x, y: line_s2Tubercle.start.y }, //{x,y}
            { x: line_s2Tubercle.end.x, y: line_s2Tubercle.end.y }, //{x,y}
            lineOptions, //See path
            "canvas"
          );
        }
        //Draw the Pubic SymphysisLine Line (through pubicSymphysisHandle, perpendicular to FHL)
        if (handles.pubicSymphysisHandle.placed == true) {
          Drawing.drawLine(
            context, //Target context (CanvasRenderingContext2D)
            element, //event.currentTarget, //The DOM Element to draw on (HTMLElement)
            { x: line_pubicSymphysis.start.x, y: line_pubicSymphysis.start.y }, //{x,y}
            { x: line_pubicSymphysis.end.x, y: line_pubicSymphysis.end.y }, //{x,y}
            lineOptions, //See path
            "canvas"
          );
        }
        //Draw the SBL line
        if (
          handles.rightS1FacetBaseHandle.placed == true &&
          handles.leftS1FacetBaseHandle.placed == true
        ) {
          Drawing.drawLine(
            context, //Target context (CanvasRenderingContext2D)
            element, //event.currentTarget, //The DOM Element to draw on (HTMLElement)
            { x: line_sbl.start.x, y: line_sbl.start.y }, //{x,y}
            { x: line_sbl.end.x, y: line_sbl.end.y }, //{x,y}
            lineOptions, //See path
            "canvas"
          );
        }
        //Draw the Right Sacral Wing line
        if (handles.rightSacralWingHandle.placed == true) {
          Drawing.drawLine(
            context, //Target context (CanvasRenderingContext2D)
            element, //event.currentTarget, //The DOM Element to draw on (HTMLElement)
            {
              x: line_rightSacralWing.start.x,
              y: line_rightSacralWing.start.y,
            }, //{x,y}
            { x: line_rightSacralWing.end.x, y: line_rightSacralWing.end.y }, //{x,y}
            lineOptions, //See path
            "canvas"
          );
        }
        //Draw the Left Sacral Wing line
        if (handles.leftSacralWingHandle.placed == true) {
          Drawing.drawLine(
            context, //Target context (CanvasRenderingContext2D)
            element, //event.currentTarget, //The DOM Element to draw on (HTMLElement)
            { x: line_leftSacralWing.start.x, y: line_leftSacralWing.start.y }, //{x,y}
            { x: line_leftSacralWing.end.x, y: line_leftSacralWing.end.y }, //{x,y}
            lineOptions, //See path
            "canvas"
          );
        }
        //Draw the Measured Deficiency Line
        if (handles.leftSacralWingHandle.placed == true) {
          Drawing.drawLine(
            context, //Target context (CanvasRenderingContext2D)
            element, //event.currentTarget, //The DOM Element to draw on (HTMLElement)
            { x: line_mdPoint.start.x, y: line_mdPoint.start.y }, //{x,y}
            { x: line_mdPoint.end.x, y: line_mdPoint.end.y }, //{x,y}
            lineOptions, //See path
            "canvas"
          );
        }
        //Draw the Right Iliac Wing Line (through rightIliacWingHandle, perpendicular to FHL)
        if (handles.rightIliacWingHandle.placed == true) {
          Drawing.drawLine(
            context, //Target context (CanvasRenderingContext2D)
            element, //event.currentTarget, //The DOM Element to draw on (HTMLElement)
            { x: line_rightIliacWing.start.x, y: line_rightIliacWing.start.y }, //{x,y}
            { x: line_rightIliacWing.end.x, y: line_rightIliacWing.end.y }, //{x,y}
            lineOptions, //See path
            "canvas"
          );
        }
        //Draw the Left Iliac Wing Line (through rightIliacWingHandle, perpendicular to FHL)
        if (handles.leftIliacWingHandle.placed == true) {
          Drawing.drawLine(
            context, //Target context (CanvasRenderingContext2D)
            element, //event.currentTarget, //The DOM Element to draw on (HTMLElement)
            { x: line_leftIliacWing.start.x, y: line_leftIliacWing.start.y }, //{x,y}
            { x: line_leftIliacWing.end.x, y: line_leftIliacWing.end.y }, //{x,y}
            lineOptions, //See path
            "canvas"
          );
        }
        //Draw the Right Posterior Superior Iliac Spine Line (through rightPsisHandle, perpendicular to FHL)
        if (handles.rightPsisHandle.placed == true) {
          Drawing.drawLine(
            context, //Target context (CanvasRenderingContext2D)
            element, //event.currentTarget, //The DOM Element to draw on (HTMLElement)
            { x: line_rightPSIS.start.x, y: line_rightPSIS.start.y }, //{x,y}
            { x: line_rightPSIS.end.x, y: line_rightPSIS.end.y }, //{x,y}
            lineOptions, //See path
            "canvas"
          );
        }
        //Draw the Left Posterior Superior Iliac Spine Line (through leftPsisHandle, perpendicular to FHL)
        if (handles.leftPsisHandle.placed == true) {
          Drawing.drawLine(
            context, //Target context (CanvasRenderingContext2D)
            element, //event.currentTarget, //The DOM Element to draw on (HTMLElement)
            { x: line_leftPSIS.start.x, y: line_leftPSIS.start.y }, //{x,y}
            { x: line_leftPSIS.end.x, y: line_leftPSIS.end.y }, //{x,y}
            lineOptions, //See path
            "canvas"
          );
        }

        let suffix = " mm";
        if (!rowPixelSpacing || !colPixelSpacing) {
          suffix = " pixels";
        }

        const dist_md_px = Math.abs(
          handles.leftFemurHeadHandle.y - handles.rightFemurHeadHandle.y
        );
        const dist_md_mm = dist_md_px * colPixelSpacing;
        const dist_iliacCrestRLine_fhl_px = Math.sqrt(
          Math.pow(
            handles.rightIliacCrestHandle.x -
              perpPoint_iliacCrestRLine_fhl_px.x,
            2
          ) +
            Math.pow(
              handles.rightIliacCrestHandle.y - perpPoint_iliacCrestRLine_fhl.y,
              2
            )
        );
        const dist_iliacCrestRLine_fhl_mm = Math.sqrt(
          Math.pow(
            (handles.rightIliacCrestHandle.x -
              perpPoint_iliacCrestRLine_fhl_px.x) *
              rowPixelSpacing,
            2
          ) +
            Math.pow(
              (handles.rightIliacCrestHandle.y -
                perpPoint_iliacCrestRLine_fhl.y) *
                colPixelSpacing,
              2
            )
        );
        const dist_iliacCrestLLine_fhl_px = Math.sqrt(
          Math.pow(
            handles.leftIliacCrestHandle.x - perpPoint_iliacCrestLLine_fhl_px.x,
            2
          ) +
            Math.pow(
              handles.leftIliacCrestHandle.y -
                perpPoint_iliacCrestLLine_fhl_px.y,
              2
            )
        );
        const dist_iliacCrestLLine_fhl_mm = Math.sqrt(
          Math.pow(
            (handles.leftIliacCrestHandle.x -
              perpPoint_iliacCrestLLine_fhl_px.x) *
              rowPixelSpacing,
            2
          ) +
            Math.pow(
              (handles.leftIliacCrestHandle.y -
                perpPoint_iliacCrestLLine_fhl_px.y) *
                colPixelSpacing,
              2
            )
        );
        const dist_ischialTuberosityRLine_fhl_px = Math.sqrt(
          Math.pow(
            handles.rightIschialTuberosityHandle.x -
              perpPoint_ischialTuberosityRLine_fhl_px.x,
            2
          ) +
            Math.pow(
              handles.rightIschialTuberosityHandle.y -
                perpPoint_ischialTuberosityRLine_fhl_px.y,
              2
            )
        );
        const dist_ischialTuberosityRLine_fhl_mm = Math.sqrt(
          Math.pow(
            (handles.rightIschialTuberosityHandle.x -
              perpPoint_ischialTuberosityRLine_fhl_px.x) *
              rowPixelSpacing,
            2
          ) +
            Math.pow(
              (handles.rightIschialTuberosityHandle.y -
                perpPoint_ischialTuberosityRLine_fhl_px.y) *
                colPixelSpacing,
              2
            )
        );
        const dist_ischialTuberosityLLine_fhl_px = Math.sqrt(
          Math.pow(
            handles.leftIschialTuberosityHandle.x -
              perpPoint_ischialTuberosityLLine_fhl_px.x,
            2
          ) +
            Math.pow(
              handles.leftIschialTuberosityHandle.y -
                perpPoint_ischialTuberosityLLine_fhl_px.y,
              2
            )
        );
        const dist_ischialTuberosityLLine_fhl_mm = Math.sqrt(
          Math.pow(
            (handles.leftIschialTuberosityHandle.x -
              perpPoint_ischialTuberosityLLine_fhl_px.x) *
              rowPixelSpacing,
            2
          ) +
            Math.pow(
              (handles.leftIschialTuberosityHandle.y -
                perpPoint_ischialTuberosityLLine_fhl_px.y) *
                colPixelSpacing,
              2
            )
        );
        const dist_iliacCrestRLine_ischialTuberosityRLine_px =
          dist_iliacCrestRLine_fhl_px + dist_ischialTuberosityRLine_fhl_px;
        const dist_iliacCrestRLine_ischialTuberosityRLine_mm =
          dist_iliacCrestRLine_fhl_mm + dist_ischialTuberosityRLine_fhl_mm;
        const dist_iliacCrestLLine_ischialTuberosityLLine_px =
          dist_iliacCrestLLine_fhl_px + dist_ischialTuberosityLLine_fhl_px;
        const dist_iliacCrestLLine_ischialTuberosityLLine_mm =
          dist_iliacCrestLLine_fhl_mm + dist_ischialTuberosityLLine_fhl_mm;
        const dist_S2TubercleLine_pubicSymphysisLine_px = Math.sqrt(
          Math.pow(
            handles.pubicSymphysisHandle.x -
              perpPoint_S2TubercleLine_pubicSymphysisLine_px.x,
            2
          ) +
            Math.pow(
              handles.pubicSymphysisHandle.y -
                perpPoint_S2TubercleLine_pubicSymphysisLine_px.y,
              2
            )
        );
        const dist_S2TubercleLine_pubicSymphysisLine_mm = Math.sqrt(
          Math.pow(
            (handles.pubicSymphysisHandle.x -
              perpPoint_S2TubercleLine_pubicSymphysisLine_px.x) *
              rowPixelSpacing,
            2
          ) +
            Math.pow(
              (handles.pubicSymphysisHandle.y -
                perpPoint_S2TubercleLine_pubicSymphysisLine_px.y) *
                colPixelSpacing,
              2
            )
        );
        const dist_rightSacralWingOnSbl_s2TubercleOnSbl_px = Math.sqrt(
          Math.pow(
            point_rightSacralWingStart_px.x - point_s2TubercleStart_px.x,
            2
          ) +
            Math.pow(
              point_rightSacralWingStart_px.y - point_s2TubercleStart_px.y,
              2
            )
        );
        const dist_rightSacralWingOnSbl_s2TubercleOnSbl_mm = Math.sqrt(
          Math.pow(
            (point_rightSacralWingStart_px.x - point_s2TubercleStart_px.x) *
              rowPixelSpacing,
            2
          ) +
            Math.pow(
              (point_rightSacralWingStart_px.y - point_s2TubercleStart_px.y) *
                colPixelSpacing,
              2
            )
        );
        const dist_leftSacralWingOnSbl_s2TubercleOnSbl_px = Math.sqrt(
          Math.pow(
            point_leftSacralWingStart_px.x - point_s2TubercleStart_px.x,
            2
          ) +
            Math.pow(
              point_leftSacralWingStart_px.y - point_s2TubercleStart_px.y,
              2
            )
        );
        const dist_leftSacralWingOnSbl_s2TubercleOnSbl_mm = Math.sqrt(
          Math.pow(
            (point_leftSacralWingStart_px.x - point_s2TubercleStart_px.x) *
              rowPixelSpacing,
            2
          ) +
            Math.pow(
              (point_leftSacralWingStart_px.y - point_s2TubercleStart_px.y) *
                colPixelSpacing,
              2
            )
        );
        const dist_rightIliacWingLine_rightPSISLine_px = Math.sqrt(
          Math.pow(
            handles.rightPsisHandle.x -
              perpPoint_rightIliacWingLine_rightPSISLine_px.x,
            2
          ) +
            Math.pow(
              handles.rightPsisHandle.y -
                perpPoint_rightIliacWingLine_rightPSISLine_px.y,
              2
            )
        );
        const dist_rightIliacWingLine_rightPSISLine_mm = Math.sqrt(
          Math.pow(
            (handles.rightPsisHandle.x -
              perpPoint_rightIliacWingLine_rightPSISLine_px.x) *
              rowPixelSpacing,
            2
          ) +
            Math.pow(
              (handles.rightPsisHandle.y -
                perpPoint_rightIliacWingLine_rightPSISLine_px.y) *
                colPixelSpacing,
              2
            )
        );
        const dist_leftIliacWingLine_leftPSISLine_px = Math.sqrt(
          Math.pow(
            handles.leftPsisHandle.x -
              perpPoint_leftIliacWingLine_leftPSISLine_px.x,
            2
          ) +
            Math.pow(
              handles.leftPsisHandle.y -
                perpPoint_leftIliacWingLine_leftPSISLine_px.y,
              2
            )
        );
        const dist_leftIliacWingLine_leftPSISLine_mm = Math.sqrt(
          Math.pow(
            (handles.leftPsisHandle.x -
              perpPoint_leftIliacWingLine_leftPSISLine_px.x) *
              rowPixelSpacing,
            2
          ) +
            Math.pow(
              (handles.leftPsisHandle.y -
                perpPoint_leftIliacWingLine_leftPSISLine_px.y) *
                colPixelSpacing,
              2
            )
        );
        const verticallyCenterText = false;
        const xOffset = 0; //10;
        const textLineWidth = 2;
        //Draw MD measurement text box
        if (handles.leftFemurHeadHandle.placed == true) {
          if (!handles.textBoxMD.hasMoved) {
            handles.textBoxMD.x = mdPoint_px.x;
            handles.textBoxMD.y = mdPoint_px.y - 80;
            ////console.log('   Text box MD coords: x: ' + handles.textBoxMD.x + ', y: ' + handles.textBoxMD.y);
          }
          drawLinkedTextBox(
            context, //The canvas context.
            element, //The element on which to draw the link. HTMLElement
            handles.textBoxMD, //The textBox to link.
            "MD " + roundToDecimal(dist_md_mm, 2) + suffix, //The text to display in the textBox.
            handles, //The handles of the annotation.
            function () {
              return [mdPoint_px];
            }, //textBoxAnchorPoints, //An array of possible anchor points on the textBox.
            measurementColour, //'orange', //The link color.
            textLineWidth, //The line width of the link.
            xOffset, //The x offset of the textBox.
            verticallyCenterText //Vertically centers the text if true.
          );
        }
        //Draw Right Iliac Crest to Ischail Tuberosity measurement textBox
        if (handles.rightIschialTuberosityHandle.placed == true) {
          if (
            !handles.textBox_iliacCrestRLine_ischialTuberosityRLine.hasMoved
          ) {
            handles.textBox_iliacCrestRLine_ischialTuberosityRLine.x =
              handles.rightFemurHeadHandle.x - 350;
            handles.textBox_iliacCrestRLine_ischialTuberosityRLine.y =
              handles.rightFemurHeadHandle.y - 15;
          }
          drawLinkedTextBox(
            context, //The canvas context.
            element, //The element on which to draw the link. HTMLElement
            handles.textBox_iliacCrestRLine_ischialTuberosityRLine, //The textBox to link.
            roundToDecimal(dist_iliacCrestRLine_ischialTuberosityRLine_mm, 2) +
              suffix, //The text to display in the textBox.
            handles, //The handles of the annotation.
            function () {
              return [
                handles.rightFemurHeadHandle,
                handles.rightIliacCrestHandle,
                handles.rightIschialTuberosityHandle,
              ];
            }, //textBoxAnchorPoints, //An array of possible anchor points on the textBox.
            measurementColour, //'orange', //The link color.
            textLineWidth, //The line width of the link.
            xOffset, //The x offset of the textBox.
            verticallyCenterText //Vertically centers the text if true.
          );
        }
        //Draw Left Iliac Crest to Ischail Tuberosity measurement textBox
        if (handles.leftIschialTuberosityHandle.placed == true) {
          if (
            !handles.textBox_iliacCrestLLine_ischialTuberosityLLine.hasMoved
          ) {
            handles.textBox_iliacCrestLLine_ischialTuberosityLLine.x =
              handles.leftFemurHeadHandle.x + 50;
            handles.textBox_iliacCrestLLine_ischialTuberosityLLine.y =
              handles.leftFemurHeadHandle.y - 15;
          }
          drawLinkedTextBox(
            context, //The canvas context.
            element, //The element on which to draw the link. HTMLElement
            handles.textBox_iliacCrestLLine_ischialTuberosityLLine, //The textBox to link.
            roundToDecimal(dist_iliacCrestLLine_ischialTuberosityLLine_mm, 2) +
              suffix, //The text to display in the textBox.
            handles, //The handles of the annotation.
            function () {
              return [
                handles.leftFemurHeadHandle,
                handles.leftIliacCrestHandle,
                handles.leftIschialTuberosityHandle,
              ];
            }, //textBoxAnchorPoints, //An array of possible anchor points on the textBox.
            measurementColour, //'orange', //The link color.
            textLineWidth, //The line width of the link.
            xOffset, //The x offset of the textBox.
            verticallyCenterText //Vertically centers the text if true.
          );
        }
        //Draw S2 Tubercle to Pubic Symphysis measurement textBox
        if (handles.pubicSymphysisHandle.placed == true) {
          if (!handles.textBox_S2TubercleLine_pubicSymphysisLine.hasMoved) {
            handles.textBox_S2TubercleLine_pubicSymphysisLine.x =
              handles.pubicSymphysisHandle.x + 50;
            handles.textBox_S2TubercleLine_pubicSymphysisLine.y =
              handles.pubicSymphysisHandle.y - 15;
          }
          drawLinkedTextBox(
            context, //The canvas context.
            element, //The element on which to draw the link. HTMLElement
            handles.textBox_S2TubercleLine_pubicSymphysisLine, //The textBox to link.
            roundToDecimal(dist_S2TubercleLine_pubicSymphysisLine_mm, 2) +
              suffix, //The text to display in the textBox.
            handles, //The handles of the annotation.
            function () {
              return [handles.pubicSymphysisHandle];
            }, //textBoxAnchorPoints, //An array of possible anchor points on the textBox.
            measurementColour, //'orange', //The link color.
            textLineWidth, //The line width of the link.
            xOffset, //The x offset of the textBox.
            verticallyCenterText //Vertically centers the text if true.
          );
        }
        //Draw Right Sacral Wing to S2 Tubercle measurement textBox
        if (handles.rightSacralWingHandle.placed == true) {
          if (!handles.textBox_rightSacralWingOnSbl_s2TubercleOnSbl.hasMoved) {
            handles.textBox_rightSacralWingOnSbl_s2TubercleOnSbl.x =
              handles.rightSacralWingHandle.x - 350;
            handles.textBox_rightSacralWingOnSbl_s2TubercleOnSbl.y =
              handles.rightSacralWingHandle.y - 15;
          }
          drawLinkedTextBox(
            context, //The canvas context.
            element, //The element on which to draw the link. HTMLElement
            handles.textBox_rightSacralWingOnSbl_s2TubercleOnSbl, //The textBox to link.
            roundToDecimal(dist_rightSacralWingOnSbl_s2TubercleOnSbl_mm, 2) +
              suffix, //The text to display in the textBox.
            handles, //The handles of the annotation.
            function () {
              return [point_rightSacralWingStart_px, point_s2TubercleStart_px];
            }, //textBoxAnchorPoints, //An array of possible anchor points on the textBox.
            measurementColour, //'orange', //The link color.
            textLineWidth, //The line width of the link.
            xOffset, //The x offset of the textBox.
            verticallyCenterText //Vertically centers the text if true.
          );
        }
        //Draw Left Sacral Wing to S2 Tuberosity measurement textBox
        if (handles.leftSacralWingHandle.placed == true) {
          if (!handles.textBox_leftSacralWingOnSbl_s2TubercleOnSbl.hasMoved) {
            handles.textBox_leftSacralWingOnSbl_s2TubercleOnSbl.x =
              handles.leftSacralWingHandle.x + 50;
            handles.textBox_leftSacralWingOnSbl_s2TubercleOnSbl.y =
              handles.leftSacralWingHandle.y - 15;
          }
          drawLinkedTextBox(
            context, //The canvas context.
            element, //The element on which to draw the link. HTMLElement
            handles.textBox_leftSacralWingOnSbl_s2TubercleOnSbl, //The textBox to link.
            roundToDecimal(dist_leftSacralWingOnSbl_s2TubercleOnSbl_mm, 2) +
              suffix, //The text to display in the textBox.
            handles, //The handles of the annotation.
            function () {
              return [point_leftSacralWingStart_px, point_s2TubercleStart_px];
            }, //textBoxAnchorPoints, //An array of possible anchor points on the textBox.
            measurementColour, //'orange', //The link color.
            textLineWidth, //The line width of the link.
            xOffset, //The x offset of the textBox.
            verticallyCenterText //Vertically centers the text if true.
          );
        }
        //Draw Right Iliac Wing to PSIS measurement textBox
        if (handles.rightPsisHandle.placed == true) {
          if (!handles.textBox_rightIliacWingLine_rightPSISLine.hasMoved) {
            handles.textBox_rightIliacWingLine_rightPSISLine.x =
              handles.rightIliacWingHandle.x - 350;
            handles.textBox_rightIliacWingLine_rightPSISLine.y =
              handles.rightIliacWingHandle.y - 15;
          }
          drawLinkedTextBox(
            context, //The canvas context.
            element, //The element on which to draw the link. HTMLElement
            handles.textBox_rightIliacWingLine_rightPSISLine, //The textBox to link.
            roundToDecimal(dist_rightIliacWingLine_rightPSISLine_mm, 2) +
              suffix, //The text to display in the textBox.
            handles, //The handles of the annotation.
            function () {
              return [
                perpPoint_rightIliacWingLine_rightPSISLine_px,
                handles.rightPsisHandle,
              ];
            }, //textBoxAnchorPoints, //An array of possible anchor points on the textBox.
            measurementColour, //'orange', //The link color.
            textLineWidth, //The line width of the link.
            xOffset, //The x offset of the textBox.
            verticallyCenterText //Vertically centers the text if true.
          );
        }
        //Draw Left Iliac Wing to PSIS measurement textBox
        if (handles.leftPsisHandle.placed == true) {
          if (!handles.textBox_leftIliacWingLine_leftPSISLine.hasMoved) {
            handles.textBox_leftIliacWingLine_leftPSISLine.x =
              handles.leftIliacWingHandle.x + 50;
            handles.textBox_leftIliacWingLine_leftPSISLine.y =
              handles.leftIliacWingHandle.y - 15;
          }
          drawLinkedTextBox(
            context, //The canvas context.
            element, //The element on which to draw the link. HTMLElement
            handles.textBox_leftIliacWingLine_leftPSISLine, //The textBox to link.
            roundToDecimal(dist_leftIliacWingLine_leftPSISLine_mm, 2) + suffix, //The text to display in the textBox.
            handles, //The handles of the annotation.
            function () {
              return [
                perpPoint_leftIliacWingLine_leftPSISLine_px,
                handles.leftPsisHandle,
              ];
            }, //textBoxAnchorPoints, //An array of possible anchor points on the textBox.
            measurementColour, //'orange', //The link color.
            textLineWidth, //The line width of the link.
            xOffset, //The x offset of the textBox.
            verticallyCenterText //Vertically centers the text if true.
          );
        }
      }
    });
  }
}
