import { useAppContext } from "context/app.context";
import { UserAuth, UserType } from "context/app.context.types";
import { TokenData } from "models/auth.types";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const LOGIN_URL = `/login`;
const ALLOWED_PAGES = ["/viewer", "/login", "", "/"];
export const useAuth = () => {
  const {
    dispatch,
    state: { userAuthData, isLoggedIn },
  } = useAppContext();

  const saveUserDetails = useCallback(
    (
      userAuthData: UserAuth,
      tokenData: TokenData,
      googleAccessToken?: { accessToken: string }
    ) => {
      secureLocalStorage.setItem("userAuthData", userAuthData);
      secureLocalStorage.setItem("tokenData", tokenData);
      if (googleAccessToken) {
        secureLocalStorage.setItem("googleAccessToken", googleAccessToken);
      }
    },
    []
  );

  const resetAuthData = useCallback(() => {
    secureLocalStorage.clear();
  }, []);
  const navigate = useNavigate();

  const redirectToLoginIfNotAuthenticated = useCallback(() => {
    if (
      !isLoggedIn &&
      window.location.pathname.indexOf("login") === -1 &&
      window.location.pathname.indexOf("viewer") === -1
    ) {
      navigate(`${LOGIN_URL}?redirectOnSuccess=true`, {
        state: { redirectUrl: window.location.href },
      });
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    const storedUserAuthData = secureLocalStorage.getItem(
      "userAuthData"
    ) as UserAuth | null;

    if (!userAuthData && storedUserAuthData) {
      dispatch({
        type: "LOGIN",
        payload: {
          userId: storedUserAuthData.userId,
          userType: storedUserAuthData.userType as UserType,
          userAuthData: storedUserAuthData,
        },
      });
    } else if (!userAuthData && !storedUserAuthData) {
      redirectToLoginIfNotAuthenticated();
    }
  }, [dispatch, userAuthData]);

  return { saveUserDetails, resetAuthData };
};
