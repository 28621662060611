import { useHomePageContext } from "pages/home-page/context/homepage.context";
import { useMemo } from "react";

export const useStudyFilterCount = () => {
  const {
    state: { filters },
  } = useHomePageContext();
  return useMemo(() => {
    return Object.entries(filters).flatMap(([_key, value]) =>
      value !== "" && value !== undefined && value !== null ? value : []
    ).length;
  }, [filters]);
};
