import { useMemo } from "react";
import { useViewerContext } from "../../context/viewer.context";

export const useXcaliberAnalysis = () => {
  const {
    state: { xcaliberAnalysisStudy, viewports },
  } = useViewerContext();
  return useMemo(() => {
    if (viewports[0]) {
      const { studyImageIndex } = viewports[0];
      const xcaliberAnalysis = xcaliberAnalysisStudy[studyImageIndex];
      return { xcaliberAnalysis };
    }
    return {
        xcaliberAnalysis: null,
    };
  }, [ xcaliberAnalysisStudy, viewports]);
};