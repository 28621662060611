import { CircularProgress } from "@mui/material";
import { SPINNER_SIZES } from "./defaultSpinner.consts";

interface DefaultSpinnerProps {
  spinnerSize?: number;
  displayText?: string;
}
export const DefaultSpinner = ({
  spinnerSize = SPINNER_SIZES.SMALL,
  displayText,
}: DefaultSpinnerProps) => {
  return (
    <div className="default-spinner-container">
      <CircularProgress size={spinnerSize} />
      {displayText && (
        <span className="spinner-container-text">{displayText}</span>
      )}
    </div>
  );
};
