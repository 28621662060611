import { useCallback, useEffect, useState } from "react";
import { formatStudyImageUrls } from "../helpers/formatImageUrls";
import { useViewerContext } from "../context/viewer.context";
import { fetchStudyArray } from "services/studies/studies";
import { isValidString } from "utils/validation/formValidation";

export const useStudyImageData = (studyPk: string, studyIuid: string) => {
  const {
    dispatch,
    state: { studyImageData },
  } = useViewerContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchStudyImageData = useCallback(async () => {
    setIsLoading(true);
    if (isValidString(studyPk) && isValidString(studyIuid)) {
      const studyArray = await fetchStudyArray(studyPk, studyIuid);
      const { images, studyModality } = formatStudyImageUrls(studyArray);
      const frameCount = images[0].frameImageIds
        ? images[0].frameImageIds.length
        : 1;
      dispatch({
        type: "SET_STUDY_DATA",
        payload: {
          modalityType: studyModality,
          totalFrames: frameCount,
          studyImageData: images,
        },
      });
      setIsLoading(false);
    }
  }, [studyPk, studyIuid, setIsLoading, dispatch]);

  useEffect(() => {
    fetchStudyImageData();
  }, [fetchStudyImageData]);

  return { isLoading, studyImageData };
};
