import Drawing from "../api/Drawing";
import Util from "../api/Util";
import { ExtendedAnnotationTool } from "../api/ExtendedAnnotationTool";
import { TOOL_IDS } from "../consts/tools.consts";
import { ToolData } from "../tools.types";

export class CentrePoint extends ExtendedAnnotationTool {
  hasIncomplete: boolean;
  constructor() {
    super(TOOL_IDS.CENTER_POINT);
    this.hasIncomplete = false;
  }

  createNewMeasurement(event) {
    const goodEventData =
      event && event.currentPoints && event.currentPoints.image;
    if (!goodEventData) {
      return;
    }
    const { x, y } = event.currentPoints.image;

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,

      complete: false, //////////////////

      handles: {
        start: {
          x,
          y,
          highlight: true,
          active: false,
        },
        end: {
          x,
          y,
          highlight: true,
          active: false,
        },
      },
    };
  }

  pointNearTool(element, data, coords) {
    const validParameters =
      data && data.handles && data.handles.start && data.handles.end;
    if (!validParameters) {
      // //console.log(
      //     `invalid parameters supplied to tool ${this.name}'s pointNearTool`
      // );
      return false;
    }

    if (data.visible === false) {
      return false;
    }

    if (this.hasIncomplete) {
      return false;
    }

    const line =
      Util.lineSegDistance(
        element,
        data.handles.start,
        data.handles.end,
        coords
      ) < 10;

    if (line) {
      data.handles.start.movesIndependently = false;
      data.handles.end.movesIndependently = false;
    }
    return line;
  }

  drawToolData(
    element: HTMLElement,
    context: CanvasRenderingContext2D,
    toolData: ToolData
  ) {
    const {
      handles: { start, end },
    } = toolData;
    const midX = (start.x + end.x) / 2;
    const midY = (start.y + end.y) / 2;
    const centerPoint = { x: midX, y: midY };

    Drawing.drawHandles(context, { element }, toolData.handles, {
      radius: 5,
      color: "green",
    });
    Drawing.drawHandles(
      context,
      { element },
      { centerPoint },
      {
        radius: 2,
        fill: "red",
        color: "red",
      }
    );
  }
}
