import cornerstoneTools from "cornerstone-tools";
import Util from "../../api/Util";
import {
  getDimensionData,
  getTextBoxAnchorPoints,
} from "../measurementToolUtils";
import { TOOL_IDS } from "../../consts/tools.consts";
import Drawing from "../../api/Drawing";
import toolStyle from "../../api/state-management/toolStyle";
import cornerstone, { triggerEvent } from "cornerstone-core";
import Manipulators from "../../api/Manipulators";
import toolColors from "../../api/state-management/toolColours";
import { DEFAULT_HANDLE } from "../../consts/tools.defaults";
import { ExtendedAnnotationTool } from "../../api/ExtendedAnnotationTool";
import { CobbAngleToolData, ToolData } from "../../tools.types";

/**
 * @public
 * @class CobbAngleTool
 * @memberof Tools.Annotation
 * @classdesc Tool for measuring the angle between two straight lines.
 * @extends Tools.Base.BaseAnnotationTool
 */

const COBB_ANGLE_CONFIG = {
  drawHandles: true,
  drawHandlesOnHover: true,
  hideHandlesIfMoving: true,
  renderDashed: false,
};
export default class CobbAngleTool extends ExtendedAnnotationTool {
  hasIncomplete: boolean;
  constructor() {
    super(TOOL_IDS.COBB_ANGLE);
    this.hasIncomplete = false;
  }

  createNewMeasurement(eventData) {
    // Create the measurement data for this tool with the end handle activated
    this.hasIncomplete = true;

    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      complete: false,
      value: "",
      handles: {
        start: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
        },
        end: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: true,
        },
        start2: {
          x: eventData.currentPoints.image.x,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
          drawnIndependently: true,
        },
        end2: {
          x: eventData.currentPoints.image.x + 1,
          y: eventData.currentPoints.image.y,
          highlight: true,
          active: false,
          drawnIndependently: true,
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }

  pointNearTool(element, data, coords) {
    if (data.visible === false) {
      return false;
    }

    if (this.hasIncomplete) {
      return false;
    }

    const seg1Near =
      Util.lineSegDistance(
        element,
        data.handles.start,
        data.handles.end,
        coords
      ) < 25;
    const seg2Near =
      Util.lineSegDistance(
        element,
        data.handles.start2,
        data.handles.end2,
        coords
      ) < 25;

    return seg1Near || seg2Near;
  }

  updateCachedStats(image, _element, data) {
    const { rowPixelSpacing, colPixelSpacing } = getDimensionData(
      image,
      this.imageMetaData
    );

    const dx1 =
      (Math.ceil(data.handles.start.x) - Math.ceil(data.handles.end.x)) *
      (colPixelSpacing || 1);
    const dy1 =
      (Math.ceil(data.handles.start.y) - Math.ceil(data.handles.end.y)) *
      (rowPixelSpacing || 1);
    const dx2 =
      (Math.ceil(data.handles.start2.x) - Math.ceil(data.handles.end2.x)) *
      (colPixelSpacing || 1);
    const dy2 =
      (Math.ceil(data.handles.start2.y) - Math.ceil(data.handles.end2.y)) *
      (rowPixelSpacing || 1);

    let angle = Math.acos(
      Math.abs(
        (dx1 * dx2 + dy1 * dy2) /
          (Math.sqrt(dx1 * dx1 + dy1 * dy1) * Math.sqrt(dx2 * dx2 + dy2 * dy2))
      )
    );

    angle *= 180 / Math.PI;

    data.rAngle = Util.roundToDecimal(angle, 2);
    data.invalidated = false;

    data.suffix = !rowPixelSpacing || !colPixelSpacing ? " (isotropic)" : "";
  }

  drawToolData(
    element: HTMLElement,
    context: CanvasRenderingContext2D,
    toolData: CobbAngleToolData
  ): void {
    const { drawHandles, drawHandlesOnHover, hideHandlesIfMoving } =
      COBB_ANGLE_CONFIG;

    const lineWidth = toolStyle.getToolWidth();
    const font = cornerstoneTools.textStyle.getFont();

    const { visible, value, handles, complete } = toolData;
    if (visible !== false) {
      Drawing.draw(context, (context) => {
        Drawing.setShadow(context, COBB_ANGLE_CONFIG);

        // Differentiate the color of activation tool
        const color = toolColors.getColorIfActive(toolData);

        const lineOptions = { color };

        Drawing.drawLine(
          context,
          element,
          handles.start,
          handles.end,
          lineOptions
        );

        if (complete) {
          Drawing.drawLine(
            context,
            element,
            handles.start2,
            handles.end2,
            lineOptions
          );
        }

        // Draw the handles
        const handleOptions = {
          ...DEFAULT_HANDLE,
          color,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };

        if (drawHandles) {
          Drawing.drawHandles(context, { element }, handles, handleOptions);
        }

        // Draw the text
        context.fillStyle = color;

        const text = value ?? this.textBoxText(toolData);

        if (!handles.textBox.hasMoved) {
          const textCoords = {
            x: (handles.start.x + handles.end.x) / 2,
            y: (handles.start.y + handles.end.y) / 2 - 10,
          };

          context.font = font;
          handles.textBox.x = textCoords.x;
          handles.textBox.y = textCoords.y;
        }

        Drawing.drawLinkedTextBox(
          context,
          element,
          handles.textBox,
          text,
          handles,
          getTextBoxAnchorPoints,
          color,
          lineWidth,
          0,
          true
        );
      });
    }
  }

  getIncomplete(element) {
    const toolState = cornerstoneTools.getToolState(element, this.toolId);

    if (toolState && Array.isArray(toolState.data)) {
      return toolState.data.find(({ complete }) => complete === false);
    }
  }

  addNewMeasurement(evt, interactionType) {
    evt.preventDefault();
    evt.stopPropagation();

    const eventData = evt.detail;

    let measurementData;
    let toMoveHandle;
    let doneMovingCallback = (success) => {
      // DoneMovingCallback for first measurement.
      if (!success) {
        cornerstoneTools.removeToolState(element, this.toolId, measurementData);

        return;
      }
      const eventType = cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED;
      const eventData = {
        toolName: this.toolId,
        toolType: this.toolId, // Deprecation notice: toolType will be replaced by toolName
        element,
        measurementData,
      };

      triggerEvent(element, eventType, eventData);
    };

    // Search for incomplete measurements
    const element = evt.detail.element;
    const pendingMeasurement = this.getIncomplete(element);

    if (pendingMeasurement) {
      measurementData = pendingMeasurement;
      measurementData.complete = true;
      measurementData.handles.start2 = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: false,
      };
      measurementData.handles.end2 = {
        x: eventData.currentPoints.image.x,
        y: eventData.currentPoints.image.y,
        drawnIndependently: false,
        highlight: true,
        active: true,
      };
      toMoveHandle = measurementData.handles.end2;
      this.hasIncomplete = false;
      doneMovingCallback = (success) => {
        // DoneMovingCallback for second measurement
        if (!success) {
          cornerstoneTools.removeToolState(
            element,
            this.toolId,
            measurementData
          );

          return;
        }

        const eventType = cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED;
        const eventData = {
          toolName: this.toolId,
          toolType: this.toolId, // Deprecation notice: toolType will be replaced by toolName
          element,
          measurementData,
        };

        triggerEvent(element, eventType, eventData);
      };
    } else {
      measurementData = this.createNewMeasurement(eventData);
      cornerstoneTools.addToolState(element, this.toolId, measurementData);
      toMoveHandle = measurementData.handles.end;
    }

    // Associate this data with this imageId so we can render it and manipulate it
    cornerstone.updateImage(element);

    Manipulators.moveNewHandle(
      eventData,
      this.toolId,
      measurementData,
      toMoveHandle,
      {},
      interactionType,
      doneMovingCallback
    );
  }

  onMeasureModified(ev) {
    const { element } = ev.detail;
    const image = cornerstone.getEnabledElement(element).image;
    if (ev.detail.toolName !== this.toolId) {
      return;
    }
    const data = ev.detail.measurementData;

    // Update textbox stats
    if (data.invalidated === true) {
      if (data.rAngle) {
        this.throttledUpdateCachedStats(image, element, data);
      } else {
        this.updateCachedStats(image, element, data);
      }
    }

    data.value = this.textBoxText(data);
  }

  textBoxText({ rAngle, suffix }) {
    if (rAngle === undefined) {
      return "";
    }
    if (Number.isNaN(rAngle)) {
      return "";
    }
    return `${rAngle}\u00B0${suffix ?? ""}`;
  }

  activeCallback(element) {
    this.onMeasureModified = this.onMeasureModified.bind(this);
    element.addEventListener(
      cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }

  passiveCallback(element) {
    this.onMeasureModified = this.onMeasureModified.bind(this);
    element.addEventListener(
      cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }

  enabledCallback(element) {
    element.removeEventListener(
      cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }

  disabledCallback(element) {
    element.removeEventListener(
      cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
      this.onMeasureModified
    );
  }
}
