import cornerstone from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";
import { getImageUrlData } from "../../functions/getImageUrlData";

export const addToolStateForImage = async (imageData, toolStateData) =>
  toolStateData.forEach(({ toolName, toolState }) => {
    const enabledElements = cornerstone.getEnabledElements();
    enabledElements.forEach((enabledElement) => {
      const { element, image } = enabledElement;
      const isCorrectElement = checkElementHasToolData(imageData, image);
      if (isCorrectElement && toolState.data.length) {
        const currentToolState = cornerstoneTools.getToolState(
          element,
          toolName
        );
        toolState.data.forEach((toolData) => {
          const index =
            currentToolState?.data.findIndex((t) => {
              return t.uuid == toolData.uuid;
            }) ?? -1;

          if (index == -1) {
            cornerstoneTools.addToolState(element, toolName, {
              ...toolData,
              active: true,
            });
            cornerstoneTools.setToolPassive(toolName, {
              mouseButtonMask: null,
            });
          }
        });
      }
    });
  });

const checkElementHasToolData = (imageData, image) => {
  const { imageId } = image ?? {};
  if (imageId) {
    const elementImageData = getImageUrlData(imageId);
    if (
      imageData.studyIUID === elementImageData.studyIUID &&
      imageData.seriesIUID === elementImageData.seriesIUID &&
      imageData.instanceIUID === elementImageData.instanceIUID
    ) {
      return true;
    }
  }
  return false;
};
