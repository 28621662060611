import { Route, Routes } from "react-router-dom";
import { LandingPage } from "./pages/landing-page/LandingPage";
import { UserLogin } from "./pages/login/Login";
import { DicomViewer } from "./pages/viewer/DicomViewer";
import { DicomRedirect } from "pages/viewer/DicomRedirect";
import ErrorPage from "./pages/ErrorPage";
import PageNotFound from "./pages/PageNotFound";
import { AnimatePresence } from "framer-motion";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@mui/system";
import XCaliberAI from "./pages/x-caliber/XCaliber";
import { AppProvider } from "context/app.context";
import { HomePage } from "pages/home-page/HomePage";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useAuth } from "hooks/useAuth";
import { useRefreshTokens } from "hooks/useRefreshTokens";
import { useAppTheme } from "theme/useAppTheme";
import { GoogleConnect } from "pages/google-connect/GoogleConnect";
import { useSetXCaliberStatus } from "hooks/useSetXCaliberStatus";

const App = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <AppProvider>
        <AppContent />
      </AppProvider>
    </GoogleOAuthProvider>
  );
};
const AppContent = () => {
  useAuth();
  useRefreshTokens();
  useSetXCaliberStatus();
  const appTheme = useAppTheme();
  return (
    <ThemeProvider theme={appTheme}>
      <ToastContainer />
      <AnimatePresence exitBeforeEnter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<UserLogin />} />
          <Route path="/google-connect" element={<GoogleConnect />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/xcaliber/*" element={<XCaliberAI />} />
          <Route path="/viewer/*" element={<DicomViewer />} />
          <Route path="/itxviewer/:base_64" element={<DicomRedirect />} />
          <Route path="/itxviewer/index.html" element={<DicomRedirect />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default App;
