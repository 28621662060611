import cornerstoneTools from "cornerstone-tools";
import Drawing from "../api/Drawing";
import Util from "../api/Util";
import cornerstone from "cornerstone-core";
import { TOOL_IDS } from "../consts/tools.consts";
import { ExtendedAnnotationTool } from "../api/ExtendedAnnotationTool";
import { ToolData } from "../tools.types";
import { renderSpinalLabels } from "./spinalLabel.functions";

const CERVICAL_LABEL_CONFIG = {
  markers: ["C1", "C2", "C3", "C4", "C5", "C6", "C7"],
  current: "C1",
  ascending: true,
  loop: true,
};
const CervicalTool = cornerstoneTools.TextMarkerTool;

export default class CervicalLabel extends ExtendedAnnotationTool {
  counter: number;
  hasIncomplete: boolean;
  constructor() {
    super(TOOL_IDS.CERVICAL_LABEL);
    this.counter = -1;
    this.hasIncomplete = false;
  }

  activeCallback(_element) {
    cornerstoneTools.addTool(CervicalTool, { CERVICAL_LABEL_CONFIG });
  }

  preMouseDownCallback(_event) {
    if (this.counter < CERVICAL_LABEL_CONFIG.markers.length - 1) {
      this.counter += 1;
    } else {
      this.counter = 0;
    }
  }

  createNewMeasurement(eventData) {
    const goodEventData =
      eventData && eventData.currentPoints && eventData.currentPoints.image;
    if (!goodEventData) {
      return;
    }

    const { x, y } = eventData.currentPoints.image;

    return {
      visible: true,
      active: true,
      color: "greenyellow",
      value: CERVICAL_LABEL_CONFIG.markers[this.counter],
      invalidated: true,
      handles: {
        start: {
          x,
          y,
          highlight: true,
          active: false,
        },
        end: {
          x,
          y,
          highlight: true,
          active: false,
        },
        textBox: {
          active: false,
          hasMoved: false,
          movesIndependently: false,
          drawnIndependently: true,
          allowedOutsideImage: true,
          hasBoundingBox: true,
        },
      },
    };
  }

  pointNearTool(element, data, coords) {
    const validParameters =
      data && data.handles && data.handles.start && data.handles.end;

    if (!validParameters) {
      // //console.log(
      //     `invalid parameters supplied to tool ${this.name}'s pointNearTool`
      // );
      return false;
    }

    if (data.visible === false) {
      return false;
    }

    if (this.hasIncomplete) {
      return false;
    }

    const line =
      Util.lineSegDistance(
        element,
        data.handles.start,
        data.handles.end,
        coords
      ) < 10;

    if (line) {
      data.handles.start.movesIndependently = false;
      data.handles.end.movesIndependently = false;
    }
    return line;
  }

  updateCachedStats(_image: any, _element: any, _data: any): void {}

  distanceFromPoint(event) {}

  drawToolData(
    element: HTMLElement,
    context: CanvasRenderingContext2D,
    toolData: ToolData
  ) {
    renderSpinalLabels(CERVICAL_LABEL_CONFIG, toolData, context, element);
  }

  _changeText(evt) {
    const eventData = evt.detail;
    const { element, currentPoints } = eventData;
    let data;

    function doneChangingTextCallback(data, updatedText, deleteTool) {
      if (deleteTool === true) {
        cornerstoneTools.removeToolState(element, this.name, data);
      } else {
        data.text = updatedText;
      }
      data.active = false;
      cornerstoneTools.updateImage(element);
    }

    const config = CERVICAL_LABEL_CONFIG;
    const coords = currentPoints.canvas;
    const toolData = cornerstoneTools.getToolState(
      element,
      TOOL_IDS.CERVICAL_LABEL
    );

    // Now check to see if there is a handle we can move
    if (!toolData) {
      return;
    }

    for (let i = 0; i < toolData.data.length; i++) {
      data = toolData.data[i];
      if (this.pointNearTool(element, data, coords)) {
        data.active = true;
        cornerstoneTools.updateImage(element);

        // Allow relabelling via a callback
        // @ts-ignore
        config.changeTextCallback(data, eventData, doneChangingTextCallback);

        evt.stopImmediatePropagation();
        evt.preventDefault();
        evt.stopPropagation();

        return;
      }
    }
  }
}
