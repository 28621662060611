import { Box } from "@mui/system";
import { StudyFilter } from "./study-filter/StudyFilter";
import { OpenInViewer } from "./open-in-viewer/OpenInViewer";

export const StudyTableControls = () => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: "1rem",
        width: "100%",
        gap: "1rem",
        paddingLeft: "0.3rem",
      }}
    >
      <StudyFilter />
      <OpenInViewer />
    </Box>
  );
};
