import cornerstone from "cornerstone-core";

// There are 4 items in a pixel - r,g,b,a
const PIXEL_UNIT_COUNT = 4;
const DEFAULT_MIN = 65535;
const DEFAULT_MAX = -32768;
const SERIES_INDEX = 0;

export const reconstructImages = async (
  axialImages,
  mprCoordinates: { x: number; y: number },
  processedMetadata
) => {
  if (!axialImages || axialImages.length === 0) {
    return;
  }
  const { x, y } = mprCoordinates;

  const [rowPixelSpacing, columnPixelSpacing] = String(
    processedMetadata.pixelSpacing
  )
    .split("\\")
    .map(parseFloat);

  let pixelNumV = 0;
  let pixelNum = 0;

  const sampleImg = axialImages[0];

  let coronalPixels = [];
  const coronalMinMax = { min: DEFAULT_MIN, max: DEFAULT_MAX };

  let sagittalPixels = [];
  const sagittalMinMax = { min: DEFAULT_MIN, max: DEFAULT_MAX };

  for (let imageIndex = 0; imageIndex < axialImages.length; imageIndex += 1) {
    const currentImage = axialImages[imageIndex];
    const tmpPixelMatrix = currentImage.getPixelData();

    var unitNum = Math.round(
      tmpPixelMatrix.length / currentImage.columns / currentImage.rows
    );

    for (var column = 0; column < currentImage.columns; column++) {
      for (var index = 0; index < PIXEL_UNIT_COUNT; index++) {
        var spIndex =
          ((y - 1) * currentImage.columns + column) * unitNum + index;
        coronalPixels[pixelNum++] = tmpPixelMatrix[spIndex];
        coronalMinMax.min = Math.min(
          coronalMinMax.min,
          tmpPixelMatrix[spIndex]
        );
        coronalMinMax.max = Math.max(
          coronalMinMax.max,
          tmpPixelMatrix[spIndex]
        );
      }
    }

    for (var row = 0; row < currentImage.rows; row++) {
      for (var index = 0; index < unitNum; index++) {
        var spIndex = (row * currentImage.columns + x) * unitNum + index;
        sagittalPixels[pixelNumV++] = tmpPixelMatrix[spIndex];
        sagittalMinMax.min = Math.min(
          sagittalMinMax.min,
          tmpPixelMatrix[spIndex]
        );
        sagittalMinMax.max = Math.max(
          sagittalMinMax.max,
          tmpPixelMatrix[spIndex]
        );
      }
    }
  }
  const coronalImage = {
    imageId: `${SERIES_INDEX}-${x}-coronal`,
    minPixelValue: coronalMinMax.min,
    maxPixelValue: coronalMinMax.max,
    getPixelData: () => coronalPixels,
    rows: axialImages.length,
    columns: sampleImg.columns,
    width: sampleImg.columns,
    height: axialImages.length,
    rgba: false,
    color: false,
    render: cornerstone.renderColorImage,
    columnPixelSpacing,
    rowPixelSpacing: (rowPixelSpacing * sampleImg.rows) / axialImages.length,
    invert: sampleImg.invert,
    windowCenter: sampleImg.windowCenter,
    windowWidth: sampleImg.windowWidth,
    sizeInBytes: sampleImg.sizeInBytes,
    intercept: sampleImg.intercept,
    slope: sampleImg.slope,
  };

  var maxVoi =
    coronalImage.maxPixelValue * coronalImage.slope + coronalImage.intercept;
  var minVoi =
    coronalImage.minPixelValue * coronalImage.slope + coronalImage.intercept;
  coronalImage.windowWidth = maxVoi - minVoi;
  coronalImage.windowCenter = (maxVoi + minVoi) / 2;

  const sagittalImage = {
    imageId: `${SERIES_INDEX}-${y}-sagittal`,
    minPixelValue: sagittalMinMax.min,
    maxPixelValue: sagittalMinMax.max,
    slope: sampleImg.slope,
    rgba: true,
    view: "sagittal",
    intercept: sampleImg.intercept,
    windowWidth: maxVoi - minVoi,
    windowCenter: (maxVoi + minVoi) / 2,
    getPixelData: () => sagittalPixels,
    rows: axialImages.length,
    columns: sampleImg.rows,
    render: cornerstone.renderColorImage,
    getCanvas: sampleImg.getCanvas,
    height: axialImages.length,
    width: sampleImg.rows,
    color: false,
    columnPixelSpacing:
      (columnPixelSpacing * sampleImg.columns) / sampleImg.rows,
    rowPixelSpacing: (rowPixelSpacing * sampleImg.rows) / axialImages.length,
    invert: sampleImg.invert,
    sizeInBytes: sampleImg.sizeInBytes,
    frameOfReferenceUID: processedMetadata.frameOfReferenceUID,
    sliceThickness: processedMetadata.sliceThickness,
    sliceLocation: processedMetadata.sliceLocation,
    pixelRepresentation: processedMetadata.pixelRepresentation,
  };

  // Extract the various attributes we need

  return { coronalImage, sagittalImage };
};
