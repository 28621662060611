import { AppBar, Stack, Toolbar, Typography } from "@mui/material";
import { DicomGridController } from "../dicom-grid-controller/DicomGridController";
import { DicomDownload } from "../dicom-download/DicomDownload";
import { DicomViewerHelpDialog } from "../dicom-viewer-help/DicomViewerHelp";
import { XcaliberSlider } from "pages/viewer/tools/miscellaneous-tools/xcaliber-tool/XcaliberSlider";
import { XcaliberModelButtons } from "pages/viewer/tools/miscellaneous-tools/xcaliber-tool/XcaliberModelButtons";

export const ViewerHeaderBar = () => {
  return (
    <AppBar
      position="fixed"
      sx={{
        bgcolor: "#1a1a1a",
        maxHeight: "48px",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar
        variant="dense"
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Stack direction="row" spacing={1} sx={{ position: "relative" }}>
          <Typography
            variant="h6"
            noWrap
            sx={{ flexGrow: 1, color: "white" }}
            component="div"
          >
            iTX Viewer
          </Typography>
        </Stack>
        <Typography
          id="toolInstructions"
          variant="h6"
          noWrap
          sx={{ flexGrow: 1, color: "lime", textAlign: "center" }}
          component="div"
        />
        <XcaliberModelButtons/>
        <XcaliberSlider/>
        <Stack direction="row" spacing={1} sx={{ position: "relative" }}>
          <DicomGridController />

          <DicomDownload />
          <DicomViewerHelpDialog />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
