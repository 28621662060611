import { DynamicFeed } from "@mui/icons-material";
import { TableButton } from "components/buttons/Buttons";
import { StudyData } from "pages/home-page/components/study-table/studyTable.types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { formatViewerUrl } from "./formatViewerUrl";
import { formatStudyImageUrls } from "pages/viewer/helpers/formatImageUrls";
import { HtmlTooltip } from "components/tooltips/Tooltips";
import { fetchStudyArray } from "services/studies/studies";
import { isValidString } from "utils/validation/formValidation";
import { Box } from "@mui/material";

interface ViewerLinkProps {
  studyData: StudyData;
  displayImage?: boolean;
}

export const ViewerLink = ({ studyData, displayImage }: ViewerLinkProps) => {
  const { studypk, studyIuid } = studyData;
  const { imageUrl } = useFetchImageUrl(studypk, studyIuid);

  if (displayImage) {
    return (
      <InlineImageLink
        imageUrl={imageUrl}
        studyPk={studypk}
        studyIuid={studyIuid}
      />
    );
  }
  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <img src={imageUrl} style={{ width: "150px", height: "150px" }} />
        </React.Fragment>
      }
      children={
        <Link
          to={formatViewerUrl(studypk, studyIuid)}
          style={{ position: "relative", textDecoration: "none" }}
        >
          <TableButton>
            <DynamicFeed /> VIEWER
          </TableButton>
        </Link>
      }
    />
  );
};

const InlineImageLink = ({ imageUrl, studyPk, studyIuid }) => {
  return (
    <Box>
      <Link
        className={"inline-image-link"}
        to={formatViewerUrl(studyPk, studyIuid)}
      >
        <img src={imageUrl} />
        <div className="image-link-footerimage-link-footer">
          <DynamicFeed /> VIEWER
        </div>
      </Link>
    </Box>
  );
};
const useFetchImageUrl = (studyPk, studyIuid) => {
  const [imageData, setImageData] = useState(null);
  const fetchImageData = useCallback(async () => {
    if (isValidString(studyPk) && isValidString(studyIuid)) {
      const studyArray = await fetchStudyArray(studyPk, studyIuid);
      const { images: imageUrls } = formatStudyImageUrls(
        studyArray,
        "image/jpeg"
      );

      setImageData(imageUrls);
    }
  }, [studyPk, studyIuid, setImageData]);

  useEffect(() => {
    fetchImageData();
  }, [fetchImageData]);

  const imageUrl = useMemo(() => {
    if (imageData && imageData[0]) {
      if (imageData[0].frameImageIds) {
        return imageData[0].frameImageIds[0].replace("application/dicom", "");
      } else if (imageData[0].image) {
        return imageData[0].image[0];
      }
    }
  }, [imageData]);
  return { imageUrl, imageData };
};
