// @ts-nocheck
import cornerstoneTools from "cornerstone-tools";
import Drawing from "../api/Drawing";
import Util from "../api/Util";
import Manipulator from "../api/Manipulators";
import { EVENTS, TOOL_IDS } from "../consts/tools.consts";
import cornerstoneMath from "cornerstone-math";
import cornerstone, { triggerEvent } from "cornerstone-core";
import { DEFAULT_FREE_HAND_AREA_CONFIG } from "./measurementTools.consts";
import { getDimensionData } from "./measurementToolUtils";
import { ImageMetadata } from "pages/viewer/dicomViewer.types";
import toolStyle from "../api/state-management/toolStyle";
import toolColors from "../api/state-management/toolColours";
import { ExtendedAnnotationTool } from "../api/ExtendedAnnotationTool";
import { DEFAULT_HANDLE } from "../consts/tools.defaults";
import { FreeHandAreaToolData, ToolData } from "../tools.types";

export default class FreeHandArea extends ExtendedAnnotationTool {
  isMultiPartTool: boolean;
  private _drawing: boolean;
  private _dragging: boolean;
  private _modifying: boolean;
  throttledUpdateCachedStats: any;

  _activeDrawingToolReference: any;
  _drawingInteractionType: string;
  imageMetaData: ImageMetadata | null;
  isMultiPartToolActive: boolean;
  isToolLocked: boolean;
  constructor() {
    super(TOOL_IDS.FREE_HAND_AREA);

    this.isMultiPartTool = true;

    this._drawing = false;
    this._dragging = false;
    this._modifying = false;
    this.imageMetaData = {};
    // Create bound callback functions for private event loops
    this._drawingMouseDownCallback = this._drawingMouseDownCallback.bind(this);
    this._drawingMouseMoveCallback = this._drawingMouseMoveCallback.bind(this);
    this._drawingMouseDragCallback = this._drawingMouseDragCallback.bind(this);
    this._drawingMouseUpCallback = this._drawingMouseUpCallback.bind(this);
    this._drawingMouseDoubleClickCallback =
      this._drawingMouseDoubleClickCallback.bind(this);
    this._editMouseUpCallback = this._editMouseUpCallback.bind(this);
    this._editMouseDragCallback = this._editMouseDragCallback.bind(this);

    this._drawingTouchStartCallback =
      this._drawingTouchStartCallback.bind(this);
    this._drawingTouchDragCallback = this._drawingTouchDragCallback.bind(this);
    this._drawingDoubleTapClickCallback =
      this._drawingDoubleTapClickCallback.bind(this);
    this._editTouchDragCallback = this._editTouchDragCallback.bind(this);

    this.throttledUpdateCachedStats = Util.throttle(
      this.updateCachedStats,
      110
    );
  }

  createNewMeasurement(eventData) {
    const goodEventData =
      eventData && eventData.currentPoints && eventData.currentPoints.image;

    if (!goodEventData) {
      console.error(
        `required eventData not supplied to tool ${this.toolId}'s createNewMeasurement`
      );

      return;
    }

    const measurementData = {
      visible: true,
      active: true,
      invalidated: true,
      color: undefined,
      handles: {
        points: [],
        textBox: {},
      },
    };

    measurementData.handles.textBox = {
      active: false,
      hasMoved: false,
      movesIndependently: false,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
    };

    return measurementData;
  }

  /**
   *
   *
   * @param {*} element element
   * @param {*} data data
   * @param {*} coords coords
   * @returns {Boolean}
   */
  pointNearTool(element, data, coords) {
    const validParameters = data && data.handles && data.handles.points;

    if (!validParameters) {
      throw new Error(
        `invalid parameters supplied to tool ${this.toolId}'s pointNearTool`
      );
    }

    if (!validParameters || data.visible === false) {
      return false;
    }

    const isPointNearTool = this._pointNearHandle(element, data, coords);

    if (isPointNearTool !== undefined) {
      return true;
    }

    return false;
  }

  /**
   * @param {*} element
   * @param {*} data
   * @param {*} coords
   * @returns {number} the distance in px from the provided coordinates to the
   * closest rendered portion of the annotation. -1 if the distance cannot be
   * calculated.
   */
  distanceFromPoint(element, data, coords) {
    let distance = Infinity;

    for (let i = 0; i < data.handles.points.length; i++) {
      const distanceI = cornerstoneMath.point.distance(
        data.handles.points[i],
        coords
      );

      distance = Math.min(distance, distanceI);
    }

    // If an error caused distance not to be calculated, return -1.
    if (distance === Infinity) {
      return -1;
    }

    return distance;
  }

  /**
   * @param {*} element
   * @param {*} data
   * @param {*} coords
   * @returns {number} the distance in canvas units from the provided coordinates to the
   * closest rendered portion of the annotation. -1 if the distance cannot be
   * calculated.
   */
  distanceFromPointCanvas(element, data, coords) {
    let distance = Infinity;

    if (!data) {
      return -1;
    }

    const canvasCoords = cornerstone.pixelToCanvas(element, coords);

    const points = data.handles.points;

    for (let i = 0; i < points.length; i++) {
      const handleCanvas = cornerstone.pixelToCanvas(element, points[i]);

      const distanceI = cornerstoneMath.point.distance(
        handleCanvas,
        canvasCoords
      );

      distance = Math.min(distance, distanceI);
    }

    // If an error caused distance not to be calculated, return -1.
    if (distance === Infinity) {
      return -1;
    }

    return distance;
  }

  /**
   *
   *
   *
   * @param {Object} image image
   * @param {Object} element element
   * @param {Object} data data
   *
   * @returns {void}  void
   */
  updateCachedStats(image, element, data) {
    // Define variables for the area and mean/standard deviation
    let meanStdDev, meanStdDevSUV;

    const seriesModule = cornerstone.metaData.get(
      "generalSeriesModule",
      image.imageId
    );
    data.seriesModule = seriesModule;
    const modality = seriesModule ? seriesModule.modality : null;

    const points = data.handles.points;
    // If the data has been invalidated, and the tool is not currently active,
    // We need to calculate it again.

    // Retrieve the bounds of the ROI in image coordinates
    const bounds = {
      left: points[0].x,
      right: points[0].x,
      bottom: points[0].y,
      top: points[0].x,
    };

    for (let i = 0; i < points.length; i++) {
      bounds.left = Math.min(bounds.left, points[i].x);
      bounds.right = Math.max(bounds.right, points[i].x);
      bounds.bottom = Math.min(bounds.bottom, points[i].y);
      bounds.top = Math.max(bounds.top, points[i].y);
    }

    const polyBoundingBox = {
      left: bounds.left,
      top: bounds.bottom,
      width: Math.abs(bounds.right - bounds.left),
      height: Math.abs(bounds.top - bounds.bottom),
    };

    // Store the bounding box information for the text box
    data.polyBoundingBox = polyBoundingBox;

    // First, make sure this is not a color image, since no mean / standard
    // Deviation will be calculated for color images.
    if (!image.color) {
      // Retrieve the array of pixels that the ROI bounds cover
      const pixels = cornerstone.getPixels(
        element,
        polyBoundingBox.left,
        polyBoundingBox.top,
        polyBoundingBox.width,
        polyBoundingBox.height
      );

      // Calculate the mean & standard deviation from the pixels and the object shape
      meanStdDev = Util.calculateFreehandStatistics.call(
        this,
        pixels,
        polyBoundingBox,
        data.handles.points
      );

      if (modality === "PT") {
        // If the image is from a PET scan, use the DICOM tags to
        // Calculate the SUV from the mean and standard deviation.

        // Note that because we are using modality pixel values from getPixels, and
        // The calculateSUV routine also rescales to modality pixel values, we are first
        // Returning the values to storedPixel values before calcuating SUV with them.
        // TODO: Clean this up? Should we add an option to not scale in calculateSUV?
        meanStdDevSUV = {
          mean: Util.calculateSUV(
            image,
            (meanStdDev.mean - image.intercept) / image.slope
          ),
          stdDev: Util.calculateSUV(
            image,
            (meanStdDev.stdDev - image.intercept) / image.slope
          ),
        };
      }

      // If the mean and standard deviation values are sane, store them for later retrieval
      if (meanStdDev && !isNaN(meanStdDev.mean)) {
        data.meanStdDev = meanStdDev;
        data.meanStdDevSUV = meanStdDevSUV;
      }
    }

    // Retrieve the pixel spacing values, and if they are not
    // Real non-zero values, set them to 1
    const { colPixelSpacing, rowPixelSpacing } = getDimensionData(
      image,
      this.imageMetaData
    );
    const scaling = (colPixelSpacing || 1) * (rowPixelSpacing || 1);

    const area = Util.freehandUtils.freehandArea(data.handles.points, scaling);

    // If the area value is sane, store it for later retrieval
    if (!isNaN(area)) {
      data.area = area;
    }

    // Set the invalidated flag to false so that this data won't automatically be recalculated
    data.invalidated = false;

    data.suffix =
      rowPixelSpacing && colPixelSpacing
        ? ` mm${String.fromCharCode(178)}`
        : ` pixels${String.fromCharCode(178)}`;
  }

  drawToolData(
    element: HTMLElement,
    context: CanvasRenderingContext2D,
    toolData: FreeHandAreaToolData
  ) {
    const {
      drawHandles,
      alwaysShowHandles,
      activeHandleRadius,
      completeHandleRadius,
      renderDashed,
      mouseLocation,
      invalidColor,
    } = DEFAULT_FREE_HAND_AREA_CONFIG;

    const lineWidth = toolStyle.getToolWidth();

    const lineDash = { color: "#fff" };

    const {
      visible,
      active,
      handles,
      polyBoundingBox,
      canComplete,
      seriesModule,
      suffix,
    } = toolData;
    const modality = seriesModule ? seriesModule.modality : null;
    if (visible) {
      Drawing.draw(context, (context) => {
        let color = toolColors.getColorIfActive(toolColors);
        let fillColor;

        if (active) {
          if (handles.invalidHandlePlacement) {
            color = invalidColor;
            fillColor = invalidColor;
          } else {
            color = toolColors.getColorIfActive(toolColors);
            fillColor = toolColors.getFillColor();
          }
        } else {
          fillColor = toolColors.getToolColor();
        }

        const lineOptions = { color, lineDash: renderDashed ? lineDash : null };

        if (handles.points.length) {
          const points = handles.points;

          Drawing.drawJoinedLines(
            context,
            element,
            points[0],
            points,
            lineOptions
          );

          if (polyBoundingBox) {
            Drawing.drawJoinedLines(
              context,
              element,
              points[points.length - 1],
              [points[0]],
              lineOptions
            );
          } else {
            Drawing.drawJoinedLines(
              context,
              element,
              points[points.length - 1],
              [mouseLocation.handles.start],
              lineOptions
            );
          }
        }

        // Draw handles
        const handleOptions = {
          ...DEFAULT_HANDLE,
          activeHandleRadius,
        };
        if (alwaysShowHandles || (active && polyBoundingBox)) {
          if (drawHandles) {
            Drawing.drawHandles(
              context,
              { element },
              handles.points,
              handleOptions
            );
          }
        }

        if (canComplete) {
          const handle = handles.points[0];
          if (drawHandles) {
            Drawing.drawHandles(context, { element }, [handle], {
              ...handleOptions,
              handleRadius: completeHandleRadius,
            });
          }
        }

        if (active && !polyBoundingBox) {
          if (drawHandles) {
            Drawing.drawHandles(
              context,
              { element },
              mouseLocation.handles,
              handleOptions
            );
          }

          const firstHandle = handles.points[0];

          if (drawHandles) {
            Drawing.drawHandles(
              context,
              { element },
              [firstHandle],
              handleOptions
            );
          }
        }

        // Only render text if polygon ROI has been completed and freehand 'shiftKey' mode was not used:
        if (polyBoundingBox && !handles.textBox.freehand) {
          // If the textbox has not been moved by the user, it should be displayed on the right-most
          // Side of the tool.
          if (!handles.textBox.hasMoved) {
            // Find the rightmost side of the polyBoundingBox at its vertical center, and place the textbox here
            // Note that this calculates it in image coordinates
            handles.textBox.x = polyBoundingBox.left + polyBoundingBox.width;
            handles.textBox.y =
              polyBoundingBox.top + polyBoundingBox.height / 2;
          }

          const text = textBoxText.call(this, toolData);

          Drawing.drawLinkedTextBox(
            context,
            element,
            handles.textBox,
            text,
            handles.points,
            textBoxAnchorPoints,
            color,
            lineWidth,
            0,
            true
          );
        }
      });
    }

    function textBoxText(data) {
      const { meanStdDev, meanStdDevSUV, area } = data;
      // Define an array to store the rows of text for the textbox
      const textLines = [];

      // If the mean and standard deviation values are present, display them
      if (meanStdDev && meanStdDev.mean !== undefined) {
        // If the modality is CT, add HU to denote Hounsfield Units
        let moSuffix = "";

        if (modality === "CT") {
          moSuffix = "HU";
        }
        data.unit = moSuffix;

        // Create a line of text to display the mean and any units that were specified (i.e. HU)
        let meanText = `Mean: ${meanStdDev.mean.toFixed(2)} ${moSuffix}`;
        // Create a line of text to display the standard deviation and any units that were specified (i.e. HU)
        let stdDevText = `StdDev: ${meanStdDev.stdDev.toFixed(2)} ${moSuffix}`;

        // If this image has SUV values to display, concatenate them to the text line
        if (meanStdDevSUV && meanStdDevSUV.mean !== undefined) {
          const SUVtext = " SUV: ";

          meanText += SUVtext + meanStdDevSUV.mean.toFixed(2);
          stdDevText += SUVtext + meanStdDevSUV.stdDev.toFixed(2);
        }

        // Add these text lines to the array to be displayed in the textbox
        textLines.push(meanText);
        textLines.push(stdDevText);
      }

      // If the area is a sane value, display it
      if (area) {
        // Determine the area suffix based on the pixel spacing in the image.
        // If pixel spacing is present, use millimeters. Otherwise, use pixels.
        // This uses Char code 178 for a superscript 2

        // Create a line of text to display the area and its units
        const areaText = `Area: ${area.toFixed(2)}${suffix ?? ""}`;

        // Add this text line to the array to be displayed in the textbox
        textLines.push(areaText);
      }

      return textLines;
    }

    function textBoxAnchorPoints(handles) {
      return handles;
    }
  }

  addNewMeasurement(evt) {
    const eventData = evt.detail;

    this._startDrawing(evt);
    this._addPoint(eventData);
  }

  preMouseDownCallback(evt) {
    const eventData = evt.detail;
    const nearby = this._pointNearHandleAllTools(eventData);

    if (eventData.event.ctrlKey) {
      if (nearby !== undefined && nearby.handleNearby.hasBoundingBox) {
        // Ctrl + clicked textBox, do nothing but still consume event.
      } else {
        Util.freehandUtils.insertOrDelete.call(this, evt, nearby);
      }

      // preventPropagation(evt);

      return true;
    }

    return false;
  }

  handleSelectedCallback(evt, toolData, handle, interactionType = "mouse") {
    const { element } = evt.detail;
    const toolState = cornerstoneTools.getToolState(element, this.toolId);

    if (handle.hasBoundingBox) {
      // Use default move handler.
      Manipulator.moveHandleNearImagePoint(
        evt,
        this,
        toolData,
        handle,
        interactionType
      );

      return;
    }

    const config = DEFAULT_FREE_HAND_AREA_CONFIG;

    config.dragOrigin = {
      x: handle.x,
      y: handle.y,
    };

    // Iterating over handles of all toolData instances to find the indices of the selected handle
    for (let toolIndex = 0; toolIndex < toolState.data.length; toolIndex++) {
      const points = toolState.data[toolIndex].handles.points;

      for (let p = 0; p < points.length; p++) {
        if (points[p] === handle) {
          config.currentHandle = p;
          config.currentTool = toolIndex;
        }
      }
    }

    this._modifying = true;

    this._activateModify(element);

    // Interupt eventDispatchers
    // preventPropagation(evt);
  }

  /**
   * Event handler for MOUSE_MOVE during drawing event loop.
   *
   * @event
   * @param {Object} evt - The event.
   * @returns {undefined}
   */
  _drawingMouseMoveCallback(evt) {
    const eventData = evt.detail;
    const { currentPoints, element } = eventData;
    const toolState = cornerstoneTools.getToolState(element, this.toolId);

    const config = DEFAULT_FREE_HAND_AREA_CONFIG;
    const currentTool = config.currentTool;

    const data = toolState.data[currentTool];
    const coords = currentPoints.canvas;

    // Set the mouseLocation handle
    this._getMouseLocation(eventData);
    this._checkInvalidHandleLocation(data, eventData);

    // Mouse move -> Polygon Mode
    const handleNearby = this._pointNearHandle(element, data, coords);
    const points = data.handles.points;
    // If there is a handle nearby to snap to
    // (and it's not the actual mouse handle)

    if (
      handleNearby !== undefined &&
      !handleNearby.hasBoundingBox &&
      handleNearby < points.length - 1
    ) {
      config.mouseLocation.handles.start.x = points[handleNearby].x;
      config.mouseLocation.handles.start.y = points[handleNearby].y;
    }

    // Force onImageRendered
    cornerstone.updateImage(element);
  }

  /**
   * Event handler for MOUSE_DRAG during drawing event loop.
   *
   * @event
   * @param {Object} evt - The event.
   * @returns {undefined}
   */
  _drawingMouseDragCallback(evt) {
    if (!this.options.mouseButtonMask.includes(evt.detail.buttons)) {
      return;
    }

    this._drawingDrag(evt);
  }

  /**
   * Event handler for TOUCH_DRAG during drawing event loop.
   *
   * @event
   * @param {Object} evt - The event.
   * @returns {undefined}
   */
  _drawingTouchDragCallback(evt) {
    this._drawingDrag(evt);
  }

  _drawingDrag(evt) {
    const eventData = evt.detail;
    const { element } = eventData;

    const toolState = cornerstoneTools.getToolState(element, this.toolId);

    const config = DEFAULT_FREE_HAND_AREA_CONFIG;
    const currentTool = config.currentTool;

    const data = toolState.data[currentTool];

    // Set the mouseLocation handle
    this._getMouseLocation(eventData);
    this._checkInvalidHandleLocation(data, eventData);
    this._addPointPencilMode(eventData, data.handles.points);
    this._dragging = true;

    // Force onImageRendered
    cornerstone.updateImage(element);
  }

  /**
   * Event handler for MOUSE_UP during drawing event loop.
   *
   * @event
   * @param {Object} evt - The event.
   * @returns {undefined}
   */
  _drawingMouseUpCallback(evt) {
    const { element } = evt.detail;

    if (!this._dragging) {
      return;
    }

    this._dragging = false;

    const config = DEFAULT_FREE_HAND_AREA_CONFIG;
    const currentTool = config.currentTool;
    const toolState = cornerstoneTools.getToolState(element, this.toolId);
    const data = toolState.data[currentTool];

    if (
      !Util.freehandUtils.freehandIntersect.end(data.handles.points) &&
      data.canComplete
    ) {
      const lastHandlePlaced = config.currentHandle;

      this._endDrawing(element, lastHandlePlaced);
    }

    // preventPropagation(evt);

    return;
  }

  /**
   * Event handler for MOUSE_DOWN during drawing event loop.
   *
   * @event
   * @param {Object} evt - The event.
   * @returns {undefined}
   */
  _drawingMouseDownCallback(evt) {
    const eventData = evt.detail;
    const { buttons, currentPoints, element } = eventData;

    if (!this.options.mouseButtonMask.includes(buttons)) {
      return;
    }

    const coords = currentPoints.canvas;

    const config = DEFAULT_FREE_HAND_AREA_CONFIG;
    const currentTool = config.currentTool;
    const toolState = cornerstoneTools.getToolState(element, this.toolId);
    const data = toolState.data[currentTool];

    const handleNearby = this._pointNearHandle(element, data, coords);

    if (
      !Util.freehandUtils.freehandIntersect.end(data.handles.points) &&
      data.canComplete
    ) {
      const lastHandlePlaced = config.currentHandle;

      this._endDrawing(element, lastHandlePlaced);
    } else if (handleNearby === undefined) {
      this._addPoint(eventData);
    }

    // preventPropagation(evt);

    return;
  }

  /**
   * Event handler for TOUCH_START during drawing event loop.
   *
   * @event
   * @param {Object} evt - The event.
   * @returns {undefined}
   */
  _drawingTouchStartCallback(evt) {
    const eventData = evt.detail;
    const { currentPoints, element } = eventData;

    const coords = currentPoints.canvas;

    const config = DEFAULT_FREE_HAND_AREA_CONFIG;
    const currentTool = config.currentTool;
    const toolState = cornerstoneTools.getToolState(element, this.toolId);
    const data = toolState.data[currentTool];

    const handleNearby = this._pointNearHandle(element, data, coords);

    if (
      !Util.freehandUtils.freehandIntersect.end(data.handles.points) &&
      data.canComplete
    ) {
      const lastHandlePlaced = config.currentHandle;

      this._endDrawing(element, lastHandlePlaced);
    } else if (handleNearby === undefined) {
      this._addPoint(eventData);
    }

    // preventPropagation(evt);

    return;
  }

  /** Ends the active drawing loop and completes the polygon.
   *
   * @public
   * @param {Object} element - The element on which the roi is being drawn.
   * @returns {null}
   */
  completeDrawing(element) {
    if (!this._drawing) {
      return;
    }
    const toolState = cornerstoneTools.getToolState(element, this.toolId);
    const config = DEFAULT_FREE_HAND_AREA_CONFIG;
    const data = toolState.data[config.currentTool];

    if (
      !Util.freehandUtils.freehandIntersect.end(data.handles.points) &&
      data.handles.points.length >= 2
    ) {
      const lastHandlePlaced = config.currentHandle;

      data.polyBoundingBox = {};
      this._endDrawing(element, lastHandlePlaced);
    }
  }

  /**
   * Event handler for MOUSE_DOUBLE_CLICK during drawing event loop.
   *
   * @event
   * @param {Object} evt - The event.
   * @returns {undefined}
   */
  _drawingMouseDoubleClickCallback(evt) {
    const { element } = evt.detail;

    this.completeDrawing(element);

    // preventPropagation(evt);
  }

  /**
   * Event handler for DOUBLE_TAP during drawing event loop.
   *
   * @event
   * @param {Object} evt - The event.
   * @returns {undefined}
   */
  _drawingDoubleTapClickCallback(evt) {
    const { element } = evt.detail;

    this.completeDrawing(element);

    // preventPropagation(evt);
  }

  /**
   * Event handler for MOUSE_DRAG during handle drag event loop.
   *
   * @event
   * @param {Object} evt - The event.
   * @returns {undefined}
   */
  _editMouseDragCallback(evt) {
    const eventData = evt.detail;
    const { element, buttons } = eventData;

    if (!this.options.mouseButtonMask.includes(buttons)) {
      return;
    }

    const toolState = cornerstoneTools.getToolState(element, this.toolId);

    const config = DEFAULT_FREE_HAND_AREA_CONFIG;
    const data = toolState.data[config.currentTool];
    const currentHandle = config.currentHandle;
    const points = data.handles.points;
    let handleIndex = -1;

    // Set the mouseLocation handle
    this._getMouseLocation(eventData);
    if (currentHandle && points) {
      data.handles.invalidHandlePlacement =
        Util.freehandUtils.freehandIntersect.modify(points, currentHandle);
      data.active = true;
      data.highlight = true;
      points[currentHandle].x = config.mouseLocation.handles.start.x;
      points[currentHandle].y = config.mouseLocation.handles.start.y;

      handleIndex = this._getPrevHandleIndex(currentHandle, points);

      if (currentHandle >= 0) {
        const lastLineIndex = points[handleIndex].lines.length - 1;
        const lastLine = points[handleIndex].lines[lastLineIndex];

        lastLine.x = config.mouseLocation.handles.start.x;
        lastLine.y = config.mouseLocation.handles.start.y;
      }

      // Update the image
      cornerstone.updateImage(element);
    }
  }

  /**
   * Event handler for TOUCH_DRAG during handle drag event loop.
   *
   * @event
   * @param {Object} evt - The event.
   * @returns {void}
   */
  _editTouchDragCallback(evt) {
    const eventData = evt.detail;
    const { element } = eventData;

    const toolState = cornerstoneTools.getToolState(element, this.toolId);

    const config = DEFAULT_FREE_HAND_AREA_CONFIG;
    const data = toolState.data[config.currentTool];
    const currentHandle = config.currentHandle;
    const points = data.handles.points;
    let handleIndex = -1;

    // Set the mouseLocation handle
    this._getMouseLocation(eventData);

    data.handles.invalidHandlePlacement =
      Util.freehandUtils.freehandIntersect.modify(points, currentHandle);
    data.active = true;
    data.highlight = true;
    points[currentHandle].x = config.mouseLocation.handles.start.x;
    points[currentHandle].y = config.mouseLocation.handles.start.y;

    handleIndex = this._getPrevHandleIndex(currentHandle, points);

    if (currentHandle >= 0) {
      const lastLineIndex = points[handleIndex].lines.length - 1;
      const lastLine = points[handleIndex].lines[lastLineIndex];

      lastLine.x = config.mouseLocation.handles.start.x;
      lastLine.y = config.mouseLocation.handles.start.y;
    }

    // Update the image
    cornerstone.updateImage(element);
  }

  /**
   * Returns the previous handle to the current one.
   * @param {Number} currentHandle - the current handle index
   * @param {Array} points - the handles Array of the freehand data
   * @returns {Number} - The index of the previos handle
   */
  _getPrevHandleIndex(currentHandle, points) {
    if (currentHandle === 0) {
      return points.length - 1;
    }

    return currentHandle - 1;
  }

  /**
   * Event handler for MOUSE_UP during handle drag event loop.
   *
   * @private
   * @param {Object} evt - The event.
   * @returns {undefined}
   */
  _editMouseUpCallback(evt) {
    const eventData = evt.detail;
    const { element } = eventData;
    const toolState = cornerstoneTools.getToolState(element, this.toolId);

    this._deactivateModify(element);

    this._dropHandle(eventData, toolState);
    this._endDrawing(element);

    cornerstone.updateImage(element);
  }

  /**
   * Places a handle of the freehand tool if the new location is valid.
   * If the new location is invalid the handle snaps back to its previous position.
   *
   * @private
   * @param {Object} eventData - Data object associated with the event.
   * @param {Object} toolState - The data associated with the freehand tool.
   * @modifies {toolState}
   * @returns {undefined}
   */
  _dropHandle(eventData, toolState) {
    const config = DEFAULT_FREE_HAND_AREA_CONFIG;
    const currentTool = config.currentTool;
    const handles = toolState.data[currentTool].handles;
    const points = handles.points;

    // Don't allow the line being modified to intersect other lines
    if (handles.invalidHandlePlacement) {
      const currentHandle = config.currentHandle;
      const currentHandleData = points[currentHandle];
      let previousHandleData;

      if (currentHandle === 0) {
        const lastHandleID = points.length - 1;

        previousHandleData = points[lastHandleID];
      } else {
        previousHandleData = points[currentHandle - 1];
      }

      // Snap back to previous position
      currentHandleData.x = config.dragOrigin.x;
      currentHandleData.y = config.dragOrigin.y;
      previousHandleData.lines[0] = currentHandleData;

      handles.invalidHandlePlacement = false;
    }
  }

  /**
   * Begining of drawing loop when tool is active and a click event happens far
   * from existing handles.
   *
   * @private
   * @param {Object} evt - The event.
   * @returns {undefined}
   */
  _startDrawing(evt) {
    const eventData = evt.detail;
    const measurementData = this.createNewMeasurement(eventData);
    const { element } = eventData;
    const config = DEFAULT_FREE_HAND_AREA_CONFIG;
    let interactionType;

    if (evt.type === EVENTS.MOUSE_DOWN_ACTIVATE) {
      interactionType = "Mouse";
    } else if (evt.type === EVENTS.TOUCH_START_ACTIVE) {
      interactionType = "Touch";
    }
    this._activateDraw(element, interactionType);
    this._getMouseLocation(eventData);

    cornerstoneTools.addToolState(element, this.toolId, measurementData);

    const toolState = cornerstoneTools.getToolState(element, this.toolId);

    config.currentTool = toolState.data.length - 1;

    this._activeDrawingToolReference = toolState.data[config.currentTool];
  }

  /**
   * Adds a point on mouse click in polygon mode.
   *
   * @private
   * @param {Object} eventData - data object associated with an event.
   * @returns {undefined}
   */
  _addPoint(eventData) {
    const { currentPoints, element } = eventData;
    const toolState = cornerstoneTools.getToolState(element, this.toolId);

    // Get the toolState from the last-drawn polygon
    const config = DEFAULT_FREE_HAND_AREA_CONFIG;
    const data = toolState.data[config.currentTool];
    const { handles } = data;
    if (handles.invalidHandlePlacement) {
      return;
    }

    const newHandleData = new Util.freehandUtils.FreehandHandleData(
      currentPoints.image
    );

    // If this is not the first handle
    if (handles.points.length) {
      // Add the line from the current handle to the new handle
      const currentHandlePoints = data.handles.points[config.currentHandle - 1];
      if (currentHandlePoints) {
        currentHandlePoints.lines.push(currentPoints.image);
      }
    }

    // Add the new handle
    data.handles.points.push(newHandleData);

    // Increment the current handle value
    config.currentHandle += 1;

    // Force onImageRendered to fire
    cornerstone.updateImage(element);
    this.fireModifiedEvent(element, data);
  }

  /**
   * If in pencilMode, check the mouse position is farther than the minimum
   * distance between points, then add a point.
   *
   * @private
   * @param {Object} eventData - Data object associated with an event.
   * @param {Object} points - Data object associated with the tool.
   * @returns {undefined}
   */
  _addPointPencilMode(eventData, points) {
    const config = DEFAULT_FREE_HAND_AREA_CONFIG;
    const { element } = eventData;
    const mousePoint = config.mouseLocation.handles.start;

    const handleFurtherThanMinimumSpacing = (handle) =>
      this._isDistanceLargerThanSpacing(element, handle, mousePoint);

    if (points.every(handleFurtherThanMinimumSpacing)) {
      this._addPoint(eventData);
    }
  }

  /**
   * Ends the active drawing loop and completes the polygon.
   *
   * @private
   * @param {Object} element - The element on which the roi is being drawn.
   * @param {Object} handleNearby - the handle nearest to the mouse cursor.
   * @returns {undefined}
   */
  _endDrawing(element, handleNearby) {
    const toolState = cornerstoneTools.getToolState(element, this.toolId);
    const config = DEFAULT_FREE_HAND_AREA_CONFIG;
    const data = toolState?.data[config.currentTool] ?? {};

    data.active = false;
    data.highlight = false;
    data.handles.invalidHandlePlacement = false;

    // Connect the end handle to the origin handle
    if (handleNearby !== undefined) {
      const points = data.handles.points;

      points[config.currentHandle - 1].lines.push(points[0]);
    }

    if (this._modifying) {
      this._modifying = false;
      data.invalidated = true;
    }

    // Reset the current handle
    config.currentHandle = 0;
    config.currentTool = -1;
    data.canComplete = false;

    if (this._drawing) {
      this._deactivateDraw(element);
    }

    cornerstone.updateImage(element);

    this.fireModifiedEvent(element, data);
    this.fireCompletedEvent(element, data);
  }

  /**
   * Returns a handle of a particular tool if it is close to the mouse cursor
   *
   * @private
   * @param {Object} element - The element on which the roi is being drawn.
   * @param {Object} data      Data object associated with the tool.
   * @param {*} coords
   * @returns {Number|Object|Boolean}
   */
  _pointNearHandle(element, data, coords) {
    if (data.handles === undefined || data.handles.points === undefined) {
      return;
    }

    if (data.visible === false) {
      return;
    }

    for (let i = 0; i < data.handles.points.length; i++) {
      const handleCanvas = cornerstone.pixelToCanvas(
        element,
        data.handles.points[i]
      );

      if (cornerstoneMath.point.distance(handleCanvas, coords) < 6) {
        return i;
      }
    }

    // Check to see if mouse in bounding box of textbox
    if (data.handles.textBox) {
      if (Util.pointInsideBoundingBox(data.handles.textBox, coords)) {
        return data.handles.textBox;
      }
    }
  }

  /**
   * Returns a handle if it is close to the mouse cursor (all tools)
   *
   * @private
   * @param {Object} eventData - data object associated with an event.
   * @returns {Object}
   */
  _pointNearHandleAllTools(eventData) {
    const { currentPoints, element } = eventData;
    const coords = currentPoints.canvas;
    const toolState = cornerstoneTools.getToolState(element, this.toolId);

    if (!toolState) {
      return;
    }

    let handleNearby;

    for (let toolIndex = 0; toolIndex < toolState.data.length; toolIndex++) {
      handleNearby = this._pointNearHandle(
        element,
        toolState.data[toolIndex],
        coords
      );
      if (handleNearby !== undefined) {
        return {
          handleNearby,
          toolIndex,
        };
      }
    }
  }

  /**
   * Gets the current mouse location and stores it in the configuration object.
   *
   * @private
   * @param {Object} eventData The data assoicated with the event.
   * @returns {undefined}
   */
  _getMouseLocation(eventData) {
    const { currentPoints, image } = eventData;
    // Set the mouseLocation handle
    const config = DEFAULT_FREE_HAND_AREA_CONFIG;

    config.mouseLocation.handles.start.x = currentPoints.image.x;
    config.mouseLocation.handles.start.y = currentPoints.image.y;
    Util.clipToBox(config.mouseLocation.handles.start, image);
  }

  /**
   * Returns true if the proposed location of a new handle is invalid.
   *
   * @private
   * @param {Object} data      Data object associated with the tool.
   * @param {Object} eventData The data assoicated with the event.
   * @returns {Boolean}
   */
  _checkInvalidHandleLocation(data, eventData) {
    if (data.handles.points.length < 2) {
      return true;
    }

    let invalidHandlePlacement;

    if (this._dragging) {
      invalidHandlePlacement = this._checkHandlesPencilMode(data, eventData);
    } else {
      invalidHandlePlacement = this._checkHandlesPolygonMode(data, eventData);
    }

    data.handles.invalidHandlePlacement = invalidHandlePlacement;
  }

  /**
   * Returns true if the proposed location of a new handle is invalid (in polygon mode).
   *
   * @private
   *
   * @param {Object} data - data object associated with the tool.
   * @param {Object} eventData The data assoicated with the event.
   * @returns {Boolean}
   */
  _checkHandlesPolygonMode(data, eventData) {
    const config = DEFAULT_FREE_HAND_AREA_CONFIG;
    const { element } = eventData;
    const mousePoint = config.mouseLocation.handles.start;
    const points = data.handles.points;
    let invalidHandlePlacement = false;

    data.canComplete = false;

    const mouseAtOriginHandle =
      this._isDistanceSmallerThanCompleteSpacingCanvas(
        element,
        points[0],
        mousePoint
      );

    if (
      mouseAtOriginHandle &&
      !Util.freehandUtils.freehandIntersect.end(points) &&
      points.length > 2
    ) {
      data.canComplete = true;
      invalidHandlePlacement = false;
    } else {
      invalidHandlePlacement = Util.freehandUtils.freehandIntersect.newHandle(
        mousePoint,
        points
      );
    }

    return invalidHandlePlacement;
  }

  /**
   * Returns true if the proposed location of a new handle is invalid (in pencilMode).
   *
   * @private
   * @param {Object} data - data object associated with the tool.
   * @param {Object} eventData The data associated with the event.
   * @returns {Boolean}
   */
  _checkHandlesPencilMode(data, eventData) {
    const config = DEFAULT_FREE_HAND_AREA_CONFIG;
    const mousePoint = config.mouseLocation.handles.start;
    const points = data.handles.points;
    let invalidHandlePlacement = Util.freehandUtils.freehandIntersect.newHandle(
      mousePoint,
      points
    );

    if (invalidHandlePlacement === false) {
      invalidHandlePlacement = this._invalidHandlePencilMode(data, eventData);
    }

    return invalidHandlePlacement;
  }

  /**
   * Returns true if the mouse position is far enough from previous points (in pencilMode).
   *
   * @private
   * @param {Object} data - data object associated with the tool.
   * @param {Object} eventData The data associated with the event.
   * @returns {Boolean}
   */
  _invalidHandlePencilMode(data, eventData) {
    const config = DEFAULT_FREE_HAND_AREA_CONFIG;
    const { element } = eventData;
    const mousePoint = config.mouseLocation.handles.start;
    const points = data.handles.points;

    const mouseAtOriginHandle =
      this._isDistanceSmallerThanCompleteSpacingCanvas(
        element,
        points[0],
        mousePoint
      );

    if (mouseAtOriginHandle) {
      data.canComplete = true;

      return false;
    }

    data.canComplete = false;

    // Compare with all other handles appart from the last one
    for (let i = 1; i < points.length - 1; i++) {
      if (this._isDistanceSmallerThanSpacing(element, points[i], mousePoint)) {
        return true;
      }
    }

    return false;
  }

  /**
   * Returns true if two points are closer than this.configuration.spacing.
   *
   * @private
   * @param  {Object} element     The element on which the roi is being drawn.
   * @param  {Object} p1          The first point, in pixel space.
   * @param  {Object} p2          The second point, in pixel space.
   * @returns {boolean}            True if the distance is smaller than the
   *                              allowed canvas spacing.
   */
  _isDistanceSmallerThanCompleteSpacingCanvas(element, p1, p2) {
    const p1Canvas = cornerstone.pixelToCanvas(element, p1);
    const p2Canvas = cornerstone.pixelToCanvas(element, p2);

    let completeHandleRadius;

    if (this._drawingInteractionType === "Mouse") {
      completeHandleRadius = DEFAULT_FREE_HAND_AREA_CONFIG.completeHandleRadius;
    } else if (this._drawingInteractionType === "Touch") {
      completeHandleRadius =
        DEFAULT_FREE_HAND_AREA_CONFIG.completeHandleRadiusTouch;
    }

    return this._compareDistanceToSpacing(
      element,
      p1Canvas,
      p2Canvas,
      "<",
      completeHandleRadius
    );
  }

  /**
   * Returns true if two points are closer than DEFAULT_FREE_HAND_AREA_CONFIG.spacing.
   *
   * @private
   * @param  {Object} element     The element on which the roi is being drawn.
   * @param  {Object} p1          The first point, in pixel space.
   * @param  {Object} p2          The second point, in pixel space.
   * @returns {boolean}            True if the distance is smaller than the
   *                              allowed canvas spacing.
   */
  _isDistanceSmallerThanSpacing(element, p1, p2) {
    return this._compareDistanceToSpacing(element, p1, p2, "<");
  }

  /**
   * Returns true if two points are farther than DEFAULT_FREE_HAND_AREA_CONFIG.spacing.
   *
   * @private
   * @param  {Object} element     The element on which the roi is being drawn.
   * @param  {Object} p1          The first point, in pixel space.
   * @param  {Object} p2          The second point, in pixel space.
   * @returns {boolean}            True if the distance is smaller than the
   *                              allowed canvas spacing.
   */
  _isDistanceLargerThanSpacing(element, p1, p2) {
    return this._compareDistanceToSpacing(element, p1, p2, ">");
  }

  /**
   * Compares the distance between two points to DEFAULT_FREE_HAND_AREA_CONFIG.spacing.
   *
   * @private
   * @param  {Object} element     The element on which the roi is being drawn.
   * @param  {Object} p1          The first point, in pixel space.
   * @param  {Object} p2          The second point, in pixel space.
   * @param  {string} comparison  The comparison to make.
   * @param  {number} spacing     The allowed canvas spacing
   * @returns {boolean}           True if the distance is smaller than the
   *                              allowed canvas spacing.
   */
  _compareDistanceToSpacing(
    element,
    p1,
    p2,
    comparison = ">",
    spacing = DEFAULT_FREE_HAND_AREA_CONFIG.spacing
  ) {
    if (comparison === ">") {
      return cornerstoneMath.point.distance(p1, p2) > spacing;
    }

    return cornerstoneMath.point.distance(p1, p2) < spacing;
  }

  /**
   * Adds drawing loop event listeners.
   *
   * @private
   * @param {Object} element - The viewport element to add event listeners to.
   * @param {string} interactionType - The interactionType used for the loop.
   * @modifies {element}
   * @returns {undefined}
   */
  _activateDraw(element, interactionType = "Mouse") {
    this._drawing = true;
    this._drawingInteractionType = interactionType;

    this.isMultiPartToolActive = true;
    // hideToolCursor(this.element);

    // Polygonal Mode
    element.addEventListener(EVENTS.MOUSE_DOWN, this._drawingMouseDownCallback);
    element.addEventListener(EVENTS.MOUSE_MOVE, this._drawingMouseMoveCallback);
    element.addEventListener(
      EVENTS.MOUSE_DOUBLE_CLICK,
      this._drawingMouseDoubleClickCallback
    );

    // Drag/Pencil Mode
    element.addEventListener(EVENTS.MOUSE_DRAG, this._drawingMouseDragCallback);
    element.addEventListener(EVENTS.MOUSE_UP, this._drawingMouseUpCallback);

    // Touch
    element.addEventListener(
      EVENTS.TOUCH_START,
      this._drawingMouseMoveCallback
    );
    element.addEventListener(
      EVENTS.TOUCH_START,
      this._drawingTouchStartCallback
    );

    element.addEventListener(EVENTS.TOUCH_DRAG, this._drawingTouchDragCallback);
    element.addEventListener(EVENTS.TOUCH_END, this._drawingMouseUpCallback);
    element.addEventListener(
      EVENTS.DOUBLE_TAP,
      this._drawingDoubleTapClickCallback
    );

    cornerstone.updateImage(element);
  }
  element(element: any) {
    throw new Error("Method not implemented.");
  }

  /**
   * Removes drawing loop event listeners.
   *
   * @private
   * @param {Object} element - The viewport element to add event listeners to.
   * @modifies {element}
   * @returns {undefined}
   */
  _deactivateDraw(element) {
    this._drawing = false;
    this.isMultiPartToolActive = false;
    this._activeDrawingToolReference = null;
    this._drawingInteractionType = null;

    element.removeEventListener(
      EVENTS.MOUSE_DOWN,
      this._drawingMouseDownCallback
    );
    element.removeEventListener(
      EVENTS.MOUSE_MOVE,
      this._drawingMouseMoveCallback
    );
    element.removeEventListener(
      EVENTS.MOUSE_DOUBLE_CLICK,
      this._drawingMouseDoubleClickCallback
    );
    element.removeEventListener(
      EVENTS.MOUSE_DRAG,
      this._drawingMouseDragCallback
    );
    element.removeEventListener(EVENTS.MOUSE_UP, this._drawingMouseUpCallback);

    // Touch
    element.removeEventListener(
      EVENTS.TOUCH_START,
      this._drawingTouchStartCallback
    );
    element.removeEventListener(
      EVENTS.TOUCH_DRAG,
      this._drawingTouchDragCallback
    );
    element.removeEventListener(
      EVENTS.TOUCH_START,
      this._drawingMouseMoveCallback
    );
    element.removeEventListener(EVENTS.TOUCH_END, this._drawingMouseUpCallback);

    cornerstone.updateImage(element);
  }

  /**
   * Adds modify loop event listeners.
   *
   * @private
   * @param {Object} element - The viewport element to add event listeners to.
   * @modifies {element}
   * @returns {undefined}
   */
  _activateModify(element) {
    this.isToolLocked = true;

    element.addEventListener(EVENTS.MOUSE_UP, this._editMouseUpCallback);
    element.addEventListener(EVENTS.MOUSE_DRAG, this._editMouseDragCallback);
    element.addEventListener(EVENTS.MOUSE_CLICK, this._editMouseUpCallback);

    element.addEventListener(EVENTS.TOUCH_END, this._editMouseUpCallback);
    element.addEventListener(EVENTS.TOUCH_DRAG, this._editTouchDragCallback);

    cornerstone.updateImage(element);
  }

  /**
   * Removes modify loop event listeners.
   *
   * @private
   * @param {Object} element - The viewport element to add event listeners to.
   * @modifies {element}
   * @returns {undefined}
   */
  _deactivateModify(element) {
    this.isToolLocked = false;

    element.removeEventListener(EVENTS.MOUSE_UP, this._editMouseUpCallback);
    element.removeEventListener(EVENTS.MOUSE_DRAG, this._editMouseDragCallback);
    element.removeEventListener(EVENTS.MOUSE_CLICK, this._editMouseUpCallback);

    element.removeEventListener(EVENTS.TOUCH_END, this._editMouseUpCallback);
    element.removeEventListener(EVENTS.TOUCH_DRAG, this._editTouchDragCallback);

    cornerstone.updateImage(element);
  }

  passiveCallback(element) {
    this._closeToolIfDrawing(element);
  }

  enabledCallback(element) {
    this._closeToolIfDrawing(element);
  }

  disabledCallback(element) {
    this._closeToolIfDrawing(element);
  }

  _closeToolIfDrawing(element) {
    if (this._drawing) {
      // Actively drawing but changed mode.
      const config = DEFAULT_FREE_HAND_AREA_CONFIG;
      const lastHandlePlaced = config.currentHandle;

      this._endDrawing(element, lastHandlePlaced);
      cornerstone.updateImage(element);
    }
  }

  /**
   * Fire MEASUREMENT_MODIFIED event on provided element
   * @param {any} element which freehand data has been modified
   * @param {any} measurementData the measurment data
   * @returns {void}
   */
  fireModifiedEvent(element, measurementData) {
    const eventType = EVENTS.MEASUREMENT_MODIFIED;
    const eventData = {
      toolName: this.toolId,
      toolType: this.toolId, // Deprecation notice: toolType will be replaced by toolName
      element,
      measurementData,
    };

    triggerEvent(element, eventType, eventData);
  }

  fireCompletedEvent(element, measurementData) {
    const eventType = EVENTS.MEASUREMENT_COMPLETED;
    const eventData = {
      toolName: this.toolId,
      toolType: this.toolId, // Deprecation notice: toolType will be replaced by toolName
      element,
      measurementData,
    };

    triggerEvent(element, eventType, eventData);
  }

  /**
   * Ends the active drawing loop and removes the polygon.
   *
   * @public
   * @param {Object} element - The element on which the roi is being drawn.
   * @returns {null}
   */
  cancelDrawing(element) {
    if (!this._drawing) {
      return;
    }
    const toolState = cornerstoneTools.getToolState(element, this.toolId);

    const config = DEFAULT_FREE_HAND_AREA_CONFIG;

    const data = toolState.data[config.currentTool];

    data.active = false;
    data.highlight = false;
    data.handles.invalidHandlePlacement = false;

    // Reset the current handle
    config.currentHandle = 0;
    config.currentTool = -1;
    data.canComplete = false;

    cornerstoneTools.removeToolState(element, this.toolId, data);

    this._deactivateDraw(element);

    cornerstone.updateImage(element);
  }

  /**
   * New image event handler.
   *
   * @public
   * @param  {Object} evt The event.
   * @returns {null}
   */
  newImageCallback(evt) {
    const config = DEFAULT_FREE_HAND_AREA_CONFIG;

    if (!(this._drawing && this._activeDrawingToolReference)) {
      return;
    }

    // Actively drawing but scrolled to different image.

    const element = evt.detail.element;
    const data = this._activeDrawingToolReference;

    data.active = false;
    data.highlight = false;
    data.handles.invalidHandlePlacement = false;

    // Connect the end handle to the origin handle
    const points = data.handles.points;

    points[config.currentHandle - 1].lines.push(points[0]);

    // Reset the current handle
    config.currentHandle = 0;
    config.currentTool = -1;
    data.canComplete = false;

    this._deactivateDraw(element);

    cornerstone.updateImage(element);
  }

  public setImageMetadata(imageMetaData: ImageMetadata) {
    if (imageMetaData) {
      this.imageMetaData = imageMetaData;
    }
  }
}
