import { useCallback } from "react";
import cornerstoneTools from "cornerstone-tools";

export const useDeactivationPerform = () => {
  return useCallback((selectedToolId: string, enabledElement) => {
    const toolInstance = cornerstoneTools.getToolForElement(
      enabledElement.element,
      selectedToolId
    );
    if (toolInstance && toolInstance.onToolDeactivation) {
      toolInstance.onToolDeactivation(enabledElement.element);
    }
  }, []);
};
