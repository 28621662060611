import { convertStatusValueToId } from "pages/home-page/components/study-table/functions/convertStatusValueToId";
import { FIELD_KEYS } from "pages/home-page/components/study-table/study-table-columns/columns.consts";
import { encodeDateToHL7Date } from "utils/formatting/encodeHL7";
import { changeTimezoneToUtc, isDate } from "utils/formatting/formatValue";
import {
  DROPDOWN_FILTER_FIELDS,
  EMPTY_DROPDOWN_VALUES,
} from "../studyFilter.consts";

export const studyFilterToUrl = (
  filterData: Record<string, any> = {},
  pageNumber: number,
  sortColumn: string,
  sortDirection: string
) => {
  const pageQuery = `?page=${Math.max(pageNumber + 1, 1)}`;
  const sortColumnQuery = `&sortCol=${sortColumn}`;
  const sortDirectionQuery = `&sortDir=${sortDirection}`;

  const filterQueriesArray = Object.entries(filterData).flatMap(
    ([key, value]) => {
      if (
        (DROPDOWN_FILTER_FIELDS.includes(key) &&
          EMPTY_DROPDOWN_VALUES.includes(String(value))) ||
        value === undefined ||
        value === null ||
        String(value).length === 0
      ) {
        return [];
      }
      if (key === FIELD_KEYS.STATUS) {
        const statusId = convertStatusValueToId(value as string | undefined);
        if (statusId === undefined) {
          return [];
        }
      }

      if (isDate(value)) {
        if (key === FIELD_KEYS.PAT_BIRTHDATE) {
          return `&${FIELD_KEYS.PAT_BIRTHDATE}=${encodeDateToHL7Date(
            value as Date
          )}`;
        }

        const convertedDate = changeTimezoneToUtc(value);
        return `&${key}=${convertedDate.toISOString()}`;
      }
      if (!isNaN(Number(value))) {
        return `&${key}=${value}`;
      }

      return `&${key}=${encodeURIComponent(value as string)}`;
    }
  );
  const filterQueryString = filterQueriesArray.join("");
  return `${pageQuery}${sortColumnQuery}${sortDirectionQuery}${filterQueryString}`;
};
