export const XcaliberSubscriptionLink = () => (
  <div>
    <p>
      Upgrade to unlock AI Assistance.
      <a href="https://atx.com.au/ai-vet-diagnosis/" target="_blank">
        Learn More
      </a>
    </p>
  </div>
);
