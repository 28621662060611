import { useCallback, useEffect, useMemo } from "react";
import { getImageUrlData } from "../../functions/getImageUrlData";
import { addToolStateForImage } from "./addToolStateForImage";
import { getDicomImageAnnotations } from "services/studies/studies";
import { useViewportStatus } from "pages/viewer/hooks/useViewportStatus";
import { VIEWPORT_STATUSES } from "pages/viewer/dicomViewer.consts";
import { useViewerContext } from "pages/viewer/context/viewer.context";

export const useSavedAnnotations = (viewportIndex: number) => {
  const {
    state: { viewports, isMPRActive },
  } = useViewerContext();
  const { viewportStatus, setViewportStatus } =
    useViewportStatus(viewportIndex);

  const studyImageIndex = useMemo(
    () => viewports[viewportIndex].studyImageIndex,
    [viewports, viewportIndex]
  );
  const fetchAnnotations = useCallback(async () => {
    const imageUrl = viewports[viewportIndex]["imageIds"][0];
    const { studyIUID, seriesIUID, instanceIUID } = getImageUrlData(imageUrl);
    const annotations = await getDicomImageAnnotations(
      studyIUID,
      seriesIUID,
      instanceIUID
    );
    if (!!annotations && annotations.length) {
      const toolState = JSON.parse(annotations[0]["annotation"]);
      if (toolState) {
        await addToolStateForImage(
          { studyIUID, seriesIUID, instanceIUID },
          toolState
        );
      }
    }
    setViewportStatus(VIEWPORT_STATUSES.COMPLETE);
  }, [viewports, viewportIndex, setViewportStatus]);

  useEffect(() => {
    if (viewportStatus === VIEWPORT_STATUSES.FETCHING_SAVED_ANNOTATIONS) {
      fetchAnnotations();
    }
  }, [
    fetchAnnotations,
    viewportStatus,
    studyImageIndex,
    isMPRActive,
    viewportIndex,
  ]);
};
