import { useState } from "react";
import cornerstone from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";
import { useUserType } from "../../../../../hooks/useUserType";
import { TOOL_IDS } from "../../consts/tools.consts";

export const XcaliberSlider = () => {
  const { userTypeColour } = useUserType();

  const [value, setValue] = useState(50);

  const handleChange = (event) => {
    setValue(event.target.value);
    updateToolData(event.target.value);
  };

  const updateToolData = (newValue) => {
    const enabledElements = cornerstone.getEnabledElements();
    if (enabledElements && enabledElements.length > 0) {
      enabledElements.forEach((enabledElement) => {
        const xcaliberTool = cornerstoneTools.getToolForElement(
          enabledElement.element,
          TOOL_IDS.XCALIBER_TOOL
        );
        if (xcaliberTool) {
          xcaliberTool.setXcaliberThreshold(newValue);
          cornerstone.updateImage(enabledElement.element);
        }
      });
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <label
        id="xcaliberSliderLabel"
        htmlFor="slider"
        style={{ color: "white", display: "none" }}
      >
        AI Probability: {value}% &nbsp;{" "}
      </label>
      <style>
        {`
          input[type="range"] {
            -webkit-appearance: none;
              appearance: none;
              background: transparent;
              cursor: pointer;
              width: 15rem;
          }

          input[type="range"]:focus {
            outline: none;
          }

          input[type="range"]::-webkit-slider-runnable-track {
            background-color: ${userTypeColour};
            border-radius: 0.5rem;
            height: 0.5rem;  
          }

          input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none; /* Override default look */
            appearance: none;
            margin-top: -12px; /* Centers thumb on the track */

            background-color: ${userTypeColour};
            height: 2rem;
            width: 1rem;
          }

          input[type="range"]:focus::-webkit-slider-thumb {   
            border: 1px solid #053a5f;
            outline: 3px solid ${userTypeColour};
            outline-offset: 0.125rem; 
          }

          input[type="range"]::-moz-range-track {
            background-color: ${userTypeColour};
            border-radius: 0.5rem;
            height: 0.5rem;
          }

          input[type="range"]::-moz-range-thumb {
            border: none; /*Removes extra border that FF applies*/
            border-radius: 0; /*Removes default border-radius that FF applies*/

            background-color: ${userTypeColour};
            height: 2rem;
            width: 1rem;
          }

          input[type="range"]:focus::-moz-range-thumb {
            border: 1px solid #053a5f;
            outline: 3px solid ${userTypeColour};
            outline-offset: 0.125rem; 
          }
        `}
      </style>
      <input
        id="xcaliberSlider"
        type="range"
        min="0"
        max="100"
        value={value}
        onChange={handleChange}
        style={{ width: "300px", display: "none" }}
      />
    </div>
  );
};
