import cornerstone from "cornerstone-core";
import Drawing from "../../api/Drawing";
import Util from "../../api/Util";
import { TOOL_IDS } from "../../consts/tools.consts";
import {
  checkEventData,
  isPointNearToolEventValid,
} from "../../functions/eventHandlingValidation";
import { DEFAULT_HANDLE } from "../../consts/tools.defaults";
import { ExtendedAnnotationTool } from "../../api/ExtendedAnnotationTool";

export class StraightLine extends ExtendedAnnotationTool {
  hasIncomplete: boolean;
  constructor() {
    super(TOOL_IDS.STRAIGHT_LINE);
    this.hasIncomplete = false;
  }

  createNewMeasurement(event) {
    const isEventDataValid = checkEventData(event);
    if (isEventDataValid) {
      const { x, y } = event.currentPoints.image;
      return {
        visible: true,
        active: true,
        color: undefined,
        invalidated: true,
        complete: false,

        handles: {
          start: {
            x,
            y,
            highlight: true,
            active: false,
          },
          end: {
            x,
            y,
            highlight: true,
            active: false,
          },
        },
      };
    }
  }

  pointNearTool(element, data, coords) {
    const validPointerEvent = isPointNearToolEventValid(data);
    if (validPointerEvent) {
      if (this.hasIncomplete) {
        return false;
      }
      const line =
        Util.lineSegDistance(
          element,
          {
            x: data.handles.start.x,
            y: Math.min(data.handles.start.y, data.handles.end.y),
          },
          {
            x: data.handles.start.x,
            y: Math.max(data.handles.start.y, data.handles.end.y),
          },
          coords
        ) < 10;

      if (line) {
        data.handles.start.movesIndependently = false;
        data.handles.end.movesIndependently = false;
      }
      return line;
    }
  }

  drawToolData(element, context, toolData) {
    const { visible, handles } = toolData;
    if (visible) {
      const textCoordsStart = cornerstone.pixelToCanvas(element, handles.start);
      const textCoordsEnd = cornerstone.pixelToCanvas(element, handles.end);
      const { x: startX, y: startY } = textCoordsStart;
      const { x: endX, y: endY } = textCoordsEnd;

      context.beginPath();
      context.fillStyle = "red";
      context.arc(startX, startY, 3, 0, 2 * Math.PI); // Control point two handle 2
      context.arc(endX, endY, 3, 0, 2 * Math.PI);
      context.fill();

      const lineHandles = {
        start: {
          ...handles.start,
        },
        end: {
          ...handles.end,
        },
      };
      const handleOptions = {
        ...DEFAULT_HANDLE,
      };
      Drawing.drawHandles(context, { element }, lineHandles, handleOptions);
      Drawing.drawLine(
        context,
        element,
        textCoordsStart,
        textCoordsEnd,
        "red",
        "canvas"
      );
    }
  }
}
