import { PatientData, RadiologySite } from "models/study.types";
import { REPORT_TYPES } from "pages/home-page/components/study-table/study-table-rows/studyTableRows.consts";
import {
  getPatientDetails,
  getRadiologistSignatureUri,
  getReportingDoctor,
} from "../documentFormatting";
import { setUpDocumentHeader } from "../setup-document-header/setUpDocumentHeader";
import { setUpDocumentFooter } from "../set-up-document-footer/setUpDocumentFooter";
import {
  DOCTOR_SIGNATURE_HEIGH,
  DOCTOR_SIGNATURE_WIDTH,
  REPORT_BODY,
} from "../googleDocs.consts";

export const setUpReportParameters = (
  reportType: string,
  patientData: PatientData,
  siteInfo: RadiologySite | null,
  headerId: string,
  footerId: string
) => {
  const { studyDate, patientName, patientDob, patientSex, institution } =
    patientData;
  if (reportType === REPORT_TYPES.REFERRAL) {
    return {
      requests: [
        {
          insertText: {
            location: {
              segmentId: headerId,
              index: 0,
            },
            text:
              studyDate +
              " " +
              patientName.toUpperCase() +
              " " +
              patientDob +
              " " +
              patientSex,
          },
        },
      ],
    };
  } else {
    const patientDetails = getPatientDetails(patientData);

    const reportingDoctor = getReportingDoctor(siteInfo);
    const patientDetailsIndex = 1;
    const reportBodyIndex = patientDetailsIndex + patientDetails.length;

    const headerDetails = setUpDocumentHeader(siteInfo, headerId, institution);
    const footerDetails = setUpDocumentFooter(footerId);
    const doctorSignatureUri = getRadiologistSignatureUri(siteInfo);
    const doctorSignatureIndex = reportBodyIndex + REPORT_BODY.length;
    const reportingDoctorIndex = doctorSignatureIndex + 1;
    const insertSignature = doctorSignatureUri
      ? [
          {
            insertInlineImage: {
              uri: doctorSignatureUri,
              objectSize: {
                height: {
                  magnitude: DOCTOR_SIGNATURE_HEIGH,
                  unit: "PT",
                },
                width: {
                  magnitude: DOCTOR_SIGNATURE_WIDTH,
                  unit: "PT",
                },
              },
              location: {
                index: doctorSignatureIndex,
              },
            },
          },
        ]
      : [];
    return {
      requests: [
        ...headerDetails,
        {
          insertText: {
            location: {
              index: patientDetailsIndex,
            },
            text: patientDetails,
          },
        },
        {
          updateTextStyle: {
            textStyle: {
              fontSize: {
                magnitude: 12,
                unit: "PT",
              },
              weightedFontFamily: {
                fontFamily: "Cambria",
              },
            },
            fields: "fontSize, weightedFontFamily",
            range: {
              startIndex: patientDetailsIndex,
              endIndex: patientDetailsIndex + patientDetails.length,
            },
          },
        },
        {
          insertText: {
            location: {
              index: reportBodyIndex,
            },
            text: REPORT_BODY,
          },
        },
        {
          updateTextStyle: {
            textStyle: {
              bold: true,
              underline: true,
            },
            fields: "bold, underline", //"bold",
            range: {
              startIndex: reportBodyIndex + 2,
              endIndex: reportBodyIndex + 2 + 20,
            },
          },
        },
        {
          updateTextStyle: {
            textStyle: {
              bold: true,
              underline: true,
            },
            fields: "bold, underline", //"bold",
            range: {
              startIndex: reportBodyIndex + 2 + 20 + 2,
              endIndex: reportBodyIndex + 2 + 20 + 2 + 20,
            },
          },
        },
        {
          updateTextStyle: {
            textStyle: {
              bold: true,
              underline: true,
            },
            fields: "bold, underline", //"bold",
            range: {
              startIndex: reportBodyIndex + 2 + 20 + 2 + 20 + 2,
              endIndex: reportBodyIndex + 2 + 20 + 2 + 20 + 2 + 50,
            },
          },
        },
        ...insertSignature,
        {
          insertText: {
            location: {
              index: reportingDoctorIndex,
            },
            text: reportingDoctor,
          },
        },
        {
          updateTextStyle: {
            textStyle: {
              bold: true,
            },
            fields: "bold",
            range: {
              startIndex: reportingDoctorIndex,
              endIndex: reportingDoctorIndex + reportingDoctor.length,
            },
          },
        },
        ...footerDetails,
      ],
    };
  }
};
