import { styled } from "@mui/system";
import { Button } from "@mui/material";
import { ReactNode } from "react";
import { useDarkModeTheme } from "hooks/useDarkmodeTheme";

const DEFAULT_COLOR = "rgb(123, 31, 162)";
export const GetStartedButton = styled(Button)(() => ({
  color: "#FFFFFF",
  borderRadius: "4px",
  backgroundColor: "rgb(25, 118, 210)",
  padding: "8px 16px",
  fontSize: "0.875rem",
  lineHeight: 1.75,
  letterSpacing: "0.02857em",
  textTransform: "uppercase",
  fontWeight: 500,

  "&:hover": {
    backgroundColor: "rgb(20, 100, 180)", // Darken the background color on hover
    boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.4)`, // Dark box shadow on hover
  },
}));

export const ActionButton = styled(Button)<{
  buttoncolor?: string;
}>(({ buttoncolor }) => ({
  borderRadius: "4px",
  backgroundColor: buttoncolor ? `${buttoncolor}` : `${DEFAULT_COLOR}`,
  padding: "8px 16px",
  fontSize: "0.875rem",
  lineHeight: 1.75,
  color: "white",
  letterSpacing: "0.02857em",
  textTransform: "uppercase",
  fontWeight: 500,
  margin: "5px !important",
  "&:hover": {
    backgroundColor: buttoncolor ? `${buttoncolor}` : `${DEFAULT_COLOR}`,
    boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.4)`, // Dark box shadow on hover
  },
}));

export const StyledTableButton = styled(Button)<{
  buttoncolor?: string;
  textcolor?: string;
}>(({ buttoncolor, textcolor }) => ({
  borderRadius: "4px",
  backgroundColor: buttoncolor ? `${buttoncolor}` : `${DEFAULT_COLOR}`,
  padding: "8px 16px",
  fontSize: "0.875rem",
  lineHeight: 1.75,
  color: textcolor ?? "white",
  letterSpacing: "0.02857em",
  textTransform: "uppercase",
  fontWeight: 500,
  margin: "5px !important",
  "&:hover": {
    backgroundColor: buttoncolor ? `${buttoncolor}` : `${DEFAULT_COLOR}`,
    boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.4)`, // Dark box shadow on hover
  },
}));

export const XcaliberModelButton = styled(Button)<{
  buttoncolor?: string;
  textcolor?: string;
  display?: string;
}>(({ buttoncolor, textcolor, display }) => ({
  borderRadius: "4px",
  backgroundColor: buttoncolor ? `${buttoncolor}` : `${DEFAULT_COLOR}`,
  padding: "8px 16px",
  fontSize: "0.875rem",
  lineHeight: 1.75,
  color: textcolor ?? "white",
  letterSpacing: "0.02857em",
  textTransform: "uppercase",
  fontWeight: 500,
  margin: "5px !important",
  display: display,
  "&:hover": {
    backgroundColor: buttoncolor ? `${buttoncolor}` : `${DEFAULT_COLOR}`,
    boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.4)`, // Dark box shadow on hover
  },
}));

interface TableButtonProps {
  children: ReactNode;
  onClick?: () => void;
}
export const TableButton = ({ children, onClick }: TableButtonProps) => {
  const { backgroundThemeColor, textThemeColor } = useDarkModeTheme();
  return (
    <StyledTableButton
      buttoncolor={backgroundThemeColor}
      textcolor={textThemeColor}
      onClick={onClick}
    >
      {children}
    </StyledTableButton>
  );
};
