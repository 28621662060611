import { useViewerContext } from "pages/viewer/context/viewer.context";
import { useCallback, useEffect, useMemo } from "react";
import { useViewportStatus } from "../useViewportStatus";
import {
  METADATA_KEYS,
  VIEWPORT_STATUSES,
} from "pages/viewer/dicomViewer.consts";
import { fetchAndParseMetaData } from "pages/viewer/helpers/fetchAndParseMetaData";

export const useFetchMetaData = (viewportIndex: number) => {
  const {
    dispatch,
    state: { studyImageMetaData, viewports, studyImageData },
  } = useViewerContext();
  const studyImageIndex = useMemo(
    () => viewports[viewportIndex]?.studyImageIndex ?? null,
    [viewports, viewportIndex]
  );

  const { setViewportStatus, viewportStatus } =
    useViewportStatus(viewportIndex);
  const canRetrieveMetaData = useMemo(
    () =>
      Boolean(
        studyImageIndex !== null &&
          viewportStatus === VIEWPORT_STATUSES.FETCHING_METADATA
      ),
    [studyImageIndex, studyImageMetaData, viewportStatus]
  );

  const formattedImages = useMemo(
    () =>
      studyImageData?.map(
        ({ image }) => `${image[0]}&contentType=application/dicom`
      ) ?? [],
    [studyImageData]
  );

  const fetchData = useCallback(async () => {
    if (
      formattedImages.length &&
      studyImageMetaData[studyImageIndex] === null
    ) {
      try {
        const dataSet = await fetchAndParseMetaData(
          formattedImages[studyImageIndex]
        );
        const { elements } = dataSet;
        const metadataValues = {};
        Object.values(elements).forEach((element) => {
          const { tag } = element;
          const keyArr = Object.keys(METADATA_KEYS).map((key) => key);
          if (keyArr.includes(tag)) {
            metadataValues[METADATA_KEYS[tag]] = dataSet.string(tag);
          }
        });
        // Return metadata object
        dispatch({
          type: "SAVE_STUDY_METADATA",
          payload: {
            studyImageMetaData: metadataValues,
            studyImageIndex,
            rawMetaData: dataSet,
          },
        });
      } catch (error) {
        console.error("Error loading DICOM image:", error);
      }
    }
    setViewportStatus(VIEWPORT_STATUSES.FETCHING_SAVED_ANNOTATIONS);
  }, [studyImageMetaData, formattedImages, studyImageIndex, setViewportStatus]);

  useEffect(() => {
    if (canRetrieveMetaData) {
      fetchData();
    }
  }, [fetchData, canRetrieveMetaData]);
};
