import { useUserType } from "hooks/useUserType";
import { ReactNode } from "react";

interface ActionButtonProps {
  children: ReactNode;
  title: string;
  disabled?: boolean;
  isBranded?: boolean;
  onClick?: () => void;
}

export const ActionButton = ({
  children,
  title,
  onClick,
  isBranded = false,
  disabled = false,
}: ActionButtonProps) => {
  const { userColorClass } = useUserType();
  return (
    <button
      disabled={disabled}
      className={`action-button ${isBranded && userColorClass}`}
      onClick={onClick}
      title={title}
    >
      {children}
    </button>
  );
};
