// @ts-nocheck
import { Component, useMemo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSearchParams } from "react-router-dom";
import { Button, Paper } from "@mui/material";
import { motion } from "framer-motion";
import { Navigate } from "react-router-dom";
import { XCaliberHeader } from "./components/XCaliberHeader";
import { styled } from "@mui/system";
import HL7 from "../../api/hl7";
import XCaliberPopup from "./components/XCaliberPopup";
import { toast } from "react-toastify";
import getColourByType from "../../utils/Colours";
import {
  studyImageFinder_dicom,
  studyImageFinder_png,
} from "services/studyImages";
import { retrieveXcaliberPdf, submitXCaliberRequest } from "services/xCaliber";
import {
  getSeriesByStudyPk,
  getStudyByPk,
  getInstanceBySeriesPk,
} from "services/studies/studies";
import { getPatientsByPk } from "services/patients";
import { useAppContext } from "context/app.context";
import { ViewerLink } from "components/table/table-row-components/viewer-link/ViewerLink";
import { useDarkModeTheme } from "hooks/useDarkmodeTheme";
import { AppHeader } from "components/header/AppHeader";
import { DefaultSpinner } from "components/loading/DefaultSpinner";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

class XCaliberAI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      init: 0,
      patient: "",
      study: "",

      instance: "",
      imageNotFoundMap: {},
      selectedImages: [],
      selectedAnalysisModel: "",
      showWarning: false, //For Warning Popup
      currentAddButton: "", //For Warning Popup
      currentPopup: "", //For Warning Popup
      submissionInProgress: false,
      type: props.type,
    };
    this.images = [];
    this.rows = [];
    this.type = props.type;

    this.colourValue = getColourByType(this.type);

    this.handleClickViewer = this.handleClickViewer.bind(this);

    //For warning popup
    this.handleShowWarning = this.handleShowWarning.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  ////AUXILIARY FUNCTIONS//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  handleAnalysisModelChange = (selectedAnalysisModel: string) => {
    this.setState({ selectedAnalysisModel });
  };

  handleClickViewer(studyPk) {
    console.log(
      "Calling XCaliberAI.js handleClickViewer - clicked view button for study " +
        studyPk
    );

    const studyArray = this.state.study;
    let imageParams = { studyArray };
    let imageFinderResult = studyImageFinder_dicom(imageParams).filter(
      (image) => image.study_pk == studyPk
    );
  }

  handleImageError = (instanceId, isSrcNotFound) => {
    this.setState((prevState) => ({
      imageNotFoundMap: {
        ...prevState.imageNotFoundMap,
        [instanceId]: { isSrcNotFound },
      },
    }));
  };

  ////IMAGE SUBMISSION//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //For Warning Popup - NOT SURE IF USED
  handleShowWarning() {
    this.setState({ showWarning: true });
  }
  handleConfirm() {
    // Handle the confirmation action
    console.log("Confirmed!");
    this.setState({ showWarning: false });
  }
  handleCancel() {
    // Handle the cancellation action
    console.log("Cancelled!");
    this.setState({ showWarning: false });
  }

  //Callback function to handle the result of the popup button click
  handlePopupResult = (
    result,
    sopIuid,
    seriesIuid,
    xcaliberId,
    xcaliberLink,
    buttonId
  ) => {
    console.log("Popup result:", result);
    //The result value depends on whether the user clicked "OK" or "CANCEL"
    if (result) {
      //User clicked OK. Call addOrRemoveImage
      console.log("User clicked OK");
      this.addOrRemoveImage(
        sopIuid,
        seriesIuid,
        xcaliberId,
        xcaliberLink,
        buttonId
      );
    } else {
      //User clicked CANCEL. Don't call addOrRemoveImage
      console.log("User clicked CANCEL");
    }
    //Remove and reset the popup
    this.setState({ showWarning: false });
    this.setState({ currentPopup: "" });
    document.removeEventListener("mousedown", this.handleOutsidePopupClick);
  };
  handleOutsidePopupClick = (event) => {
    const currentPopupId = this.state.currentPopup;
    const popup = document.getElementById(currentPopupId);
    if (popup && !popup.contains(event.target)) {
      console.log("User clicked outside the popup. Closing Popup.");
      //Clicked outside the popup so closing the popup
      this.setState({ showWarning: false });
    }
  };
  handleClickAdd(
    sopIuid,
    seriesIuid,
    xcaliberId,
    xcaliberLink,
    buttonId,
    popupId
  ) {
    //Don't allow adding of images if a submission is already in progress
    if (this.state.submissionInProgress) {
      return;
    }

    //Set the currrent popup id state
    this.setState({ currentPopup: popupId });

    //Set the state for the current Add button clicked for the popup
    this.setState({ currentAddButton: buttonId });

    //Check if the image is selected or not
    const isImageSelected = this.state.selectedImages.some(
      (image) => image.sop_iuid === sopIuid
    );

    console.log(
      "Calling XCaliberAI.js handleClickAdd - clicked ADD button for study " +
        sopIuid +
        " and " +
        seriesIuid
    );

    //CHECK IF THE SELECTED IMAGE ALREADY HAS AN ID OR LINK AND SEND A WARNING THAT THE PREVIOUS ANALYSIS WILL BE OVERWRITTEN
    if (!isImageSelected && (xcaliberId || xcaliberLink)) {
      //Set the state so that the warning popup displays
      this.setState({ showWarning: true }, () => {
        console.log(
          "HAVE SET showWarning STATE TO TRUE: ",
          this.state.showWarning
        );
        //Add event listener for if the user clicks outside the popup to cancel it
        document.addEventListener("mousedown", this.handleOutsidePopupClick);
        //The popup is now showing so this function ends here and control is given to the handlePopupResult function
      });
      console.log(
        "CHECKING STATE OF showWarning STRAIGHT AFTER SET STATE: ",
        this.state.showWarning
      );
    } else {
      this.addOrRemoveImage(
        sopIuid,
        seriesIuid,
        xcaliberId,
        xcaliberLink,
        buttonId
      );
    }
  }
  addOrRemoveImage = (
    sopIuid,
    seriesIuid,
    xcaliberId,
    xcaliberLink,
    buttonId
  ) => {
    // Check if the image is already selected
    const isImageSelected = this.state.selectedImages.some(
      (image) => image.sop_iuid === sopIuid
    );

    // Update the state based on whether the image is selected or not
    if (isImageSelected) {
      this.setState(
        (prevState) => ({
          selectedImages: prevState.selectedImages.filter(
            (image) =>
              !(image.sop_iuid === sopIuid && image.series_iuid === seriesIuid)
          ),
        }),
        () => {
          console.log("SELECTED IMAGES UNDO: ", this.state.selectedImages);
        }
      );
    } else {
      this.setState(
        (prevState) => ({
          selectedImages: [
            ...prevState.selectedImages,
            { sop_iuid: sopIuid, series_iuid: seriesIuid },
          ],
        }),
        () => {
          console.log("SELECTED IMAGES ADD: ", this.state.selectedImages);
        }
      );
    }
  };

  handleClickSubmit = () => {
    console.log("clicked submit. State: ", this.state);
    const analysisModel = this.state.selectedAnalysisModel;
    const studyIuid = this.state.study[0].study_iuid;
    const imageIuids = [];
    const userId = this.props.userId;

    console.log("Analysis Model: ", analysisModel);
    console.log("Study Iuid: ", studyIuid);

    //Turn on the in-progress spinner and process the request while it's spinning
    this.setState({ submissionInProgress: true }, async () => {
      console.log("Start");

      //For testing delay:
      //setTimeout(() => {
      //console.log("Delayed action");
      //console.log('SUBMITTSIONS IN PROGRESS???: ', this.state.submissionInProgress);

      //Create the image Iuids object for the selected images, to pass to the backend
      this.state.selectedImages.map((image) => {
        console.log("Series Iuid: ", image.series_iuid);
        console.log("Instance Iuid: ", image.sop_iuid);
        const imageIuidsObject = {
          seriesIuid: image.series_iuid,
          instanceIuid: image.sop_iuid,
        };
        imageIuids.push(imageIuidsObject);
      });

      //Submit the request to the backend
      const result = await submitXCaliberRequest(
        userId,
        studyIuid,
        imageIuids,
        analysisModel
      );
      const {
        rsltData: { reqId },
        error,
        status,
        statusText,
      } = result;

      if (error) {
        toast.error("Submission Unsuccessful: " + error.rsltMsg, {
          position: "top-center",
          style: { background: this.colourValue, color: "white" },
        });
      } else if (reqId && reqId.trim() != "") {
        const message = "Submitted";
        const requestId = reqId;
        toast.success(message, {
          position: "top-center",
          style: { background: this.colourValue, color: "white" },
        });
        const { study, selectedImages } = this.state;
        // Iterate through seriesArray
        study.forEach((study) => {
          // Iterate through instanceArray
          study.seriesArray.forEach((series) => {
            series.instanceArray.forEach((instance) => {
              // Check if sop_iuid is in selectedImages
              const selectedImage = selectedImages.find(
                (image) => image.sop_iuid === instance.sop_iuid
              );
              // If found, update xcaliber_id
              if (selectedImage) {
                instance.xcaliber_id = requestId; //res.data.requestId;
              }
            });
          });
        });
        // Update the state with the modified study
        this.setState({ study }, () => {
          //Once the state has been updated, reset the image selection state so that the selections are cleared on the screen
          this.setState({ selectedImages: [] });
        });
      } else {
        toast.error(
          `Analysis Id Not Returned: ${statusText ?? "Unknown reason"}`,
          {
            position: "top-center",
            style: { background: this.colourValue, color: "white" },
          }
        );
      }
      this.setState({ submissionInProgress: false });
    });
    console.log("End");
  };

  ////IMAGE RETRIEVAL//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  async handleClickResult(xcaliberId, xcaliberLink, studyPk) {
    if (xcaliberId && xcaliberLink) {
      //If the study has an Id and a link, open the link in a new tab/window. NOTE: Keeping this functionality here in case a link is used in the future.
      if (xcaliberLink.trim() != "") {
        window.open(xcaliberLink, "_blank");
      }
    } else if (xcaliberId && !xcaliberLink) {
      //1) Obtain result from Xcaliber based on the userId and requestId
      const userId = this.props.userId;
      const requestId = xcaliberId;
      const result = await retrieveXcaliberPdf(userId, requestId);
      if (result.status && result.status == 202) {
        toast.success("Analysis in Progress. Please try again soon.", {
          position: "top-center",
          style: { background: this.colourValue, color: "white" },
        });
      } else {
        const url = window.URL.createObjectURL(result);
        const newWindow = window.open(url, "_blank");
        if (
          !newWindow ||
          newWindow.closed ||
          typeof newWindow.closed === "undefined"
        ) {
          // Popup blocked or failed to open
          // Provide alternative instructions to the user
          alert(
            "The PDF could not be opened. Please check your popup blocker settings then retry"
          );
        }
      }
      //Setting link to null because using direct PDF download instead. Remove this in future if XCaliber provide direct links to their anaylsis, and replace with the actual link to the results.
      // const link = null; //result.data.link; //'https://google.com'

      // //2) If the link exists, update the database with it (could do this as part of the backend check) and update dbInstanceArray[k].xcaliber_link = 'https://google.com'
      // if (link) {
      //   //Set the state with the array updated with the link:
      //   const updatedStudyArray = this.state.study.map((study) => {
      //     return {
      //       ...study,
      //       seriesArray: study.seriesArray.map((series) => {
      //         return {
      //           ...series,
      //           instanceArray: series.instanceArray.map((instance) => {
      //             if (instance.xcaliber_id === xcaliberId) {
      //               return { ...instance, xcaliber_link: link };
      //             }
      //             return instance;
      //           }),
      //         };
      //       }),
      //     };
      //   });
      //   console.log("ORIGINAL STATE STUDY ARRAY: ", this.state.study);
      //   console.log("UPDATED STUDY ARRAY: ", updatedStudyArray);
      //   this.setState({
      //     study: updatedStudyArray,
      //   });
    } else if (result.errorMessage) {
      toast.success(result.message, {
        position: "top-center",
        style: { background: this.colourValue, color: "white" },
      });
    } else if (!xcaliberId && xcaliberLink) {
      console.log("Something went wrong. Instance has link but no id.");
      toast.error("Error processing request", {
        position: "top-center",
        style: { background: this.colourValue, color: "white" },
      });
    } else {
      console.log(
        "Something went wrong. Button was clicked with no id or link."
      );
      toast.error("Error processing request", {
        position: "top-center",
        style: { background: this.colourValue, color: "white" },
      });
    }
  }

  ////SETUP AND RENDER//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  async componentDidMount() {
    //Retreive data from the database and set to state
    let studyByPkArray = await getStudyByPk(this.props.studyPk);
    let patientData = await getPatientsByPk(studyByPkArray[0].patient_fk);
    this.setState({ patient: patientData });
    let studyByPk = studyByPkArray[0];
    if (!studyByPk.studyPk) {
      studyByPk.studyPk = studyByPk.pk;
    } //Just for consistency. Check why the TableDataStudies uses studyPk instead of pk

    let studyArray = [];
    let seriesArray = []; //Contains all series combined for the study (for compatibility with before image object structure redesign)
    let instanceArray = []; //Contains all instances combined for the study (for compatibility with before image object structure redesign)

    //Create arrays for the images
    studyArray.push(studyByPk);
    //Popuplate arrays by iterating through each series and instance
    for (let i = 0; studyArray.length > i; i++) {
      const dbSeriesArray = await getSeriesByStudyPk(studyArray[i].studyPk);
      studyArray[i].seriesArray = dbSeriesArray;
      seriesArray = seriesArray.concat(dbSeriesArray); //So compatible with original version
      for (let j = 0; dbSeriesArray.length > j; j++) {
        const dbInstanceArray = await getInstanceBySeriesPk(
          dbSeriesArray[j].pk
        );

        for (let k = 0; dbInstanceArray.length > k; k++) {
          if (
            dbInstanceArray[k].xcaliber_id &&
            !dbInstanceArray[k].xcaliber_link
          ) {
            //Check if it has a link yet by sending results request to XCaliber
            //remove this comment once testing done
            //console.log('RETRIEVEED RESULT: ', result);
            //If so, update the database with the link (could do this as part of the backend check) and update dbInstanceArray[k].xcaliber_link = ....
          }
        }
        studyArray[i].seriesArray[j].instanceArray = dbInstanceArray;
        instanceArray = instanceArray.concat(dbInstanceArray); //So compatible with original version
      }
    }
    let imageParams = { studyArray };
    seriesArray = [seriesArray]; //So compatible with original version
    instanceArray = [instanceArray]; //So compatible with original version

    //Retrieve the images from WADO and set to session storage
    let imageFinderResult = await studyImageFinder_png(imageParams);
    await localStorage.setItem("images", JSON.stringify(imageFinderResult));

    //Set default analysis Model if it isn't set yet.
    //CHANGE THIS IF THERE'S A WAY TO IDENTIFY IT BASSED ON DATABASE DATA SUCH AS BODY PART OR BASED ON WHICHEVER OPTION IS DISPLAYED ON THE SCREEN
    if (!this.state.selectedAnalysisModel) {
      await this.setState({ selectedAnalysisModel: "01|vet_vhs" });
    }

    //Set the state with the gathered data
    await this.setState({
      study: studyArray,
      images: imageFinderResult, //Possibly not used
      init: 1,
    });
  }

  renderProduct() {
    const study = this.state.study;
    let imageParams = { studyArray: study };
    this.images = studyImageFinder_png(imageParams);

    //Dark Mode
    let backgroundThemeColor;
    let textThemeColor;
    let buttonTheme;
    this.props.darkMode
      ? (backgroundThemeColor = "#212121")
      : (backgroundThemeColor = "#f5f5f5");
    this.props.darkMode
      ? (textThemeColor = "#ffffff")
      : (textThemeColor = "#000000");
    this.props.darkMode ? (buttonTheme = "#6e6e6e") : (buttonTheme = "#e5e5e5");

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.25 }}
        exit={{ opacity: 0 }}
      >
        <AppHeader />

        <XCaliberHeader
          patientDetails={this.state.patient[0]}
          submitButtonText={
            !this.state.submissionInProgress &&
            this.state.selectedImages.length > 0
              ? "SUBMIT"
              : ""
          }
          onSubmitButtonClick={this.handleClickSubmit}
          isSubmissionDisabled={
            !this.state.submissionInProgress &&
            this.state.selectedImages.length > 0
              ? false
              : true
          }
          infoText={
            this.state.selectedImages.length > 0
              ? this.state.selectedImages.length > 1
                ? this.state.selectedImages.length + " images selected"
                : "1 image selected"
              : "No images selected"
          }
          selectedDropdown={this.state.selectedAnalysisModel}
          onDropdownChange={this.handleAnalysisModelChange}
          submissionInProgress={this.state.submissionInProgress}
        />
        <TableContainer component={Paper}>
          <Table aria-label="patient table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    color: this.colourValue,
                    fontSize: "17px",
                    fontWeight: "bold",
                    position: "relative",
                    bottom: "-4px",
                  }}
                  align="center"
                >
                  THUMBNAIL
                </TableCell>
                <TableCell
                  style={{
                    color: this.colourValue,
                    fontSize: "17px",
                    fontWeight: "bold",
                    position: "relative",
                    bottom: "-4px",
                  }}
                  align="center"
                >
                  ADD TO BUNDLE
                </TableCell>
                <TableCell
                  style={{
                    color: this.colourValue,
                    fontSize: "17px",
                    fontWeight: "bold",
                    position: "relative",
                    bottom: "-4px",
                  }}
                  align="center"
                >
                  RESULT
                </TableCell>
                <TableCell
                  style={{
                    color: this.colourValue,
                    fontSize: "17px",
                    fontWeight: "bold",
                    position: "relative",
                    bottom: "-4px",
                  }}
                  align="center"
                >
                  BODY PART
                </TableCell>
                <TableCell
                  style={{
                    color: this.colourValue,
                    fontSize: "17px",
                    fontWeight: "bold",
                    position: "relative",
                    bottom: "-4px",
                  }}
                  align="center"
                >
                  SERIES NUMBER
                </TableCell>
                <TableCell
                  style={{
                    color: this.colourValue,
                    fontSize: "17px",
                    fontWeight: "bold",
                    position: "relative",
                    bottom: "-4px",
                  }}
                  align="center"
                >
                  INSTANCE NUMBER
                </TableCell>
                <TableCell
                  style={{
                    color: this.colourValue,
                    fontSize: "17px",
                    fontWeight: "bold",
                    position: "relative",
                    bottom: "-4px",
                  }}
                  align="center"
                >
                  IMAGE TIME
                </TableCell>
                <TableCell
                  style={{
                    color: this.colourValue,
                    fontSize: "17px",
                    fontWeight: "bold",
                    position: "relative",
                    bottom: "-4px",
                  }}
                  align="center"
                >
                  REFERRING PHYSICIAN
                </TableCell>
                <TableCell
                  style={{
                    color: this.colourValue,
                    fontSize: "17px",
                    fontWeight: "bold",
                    position: "relative",
                    bottom: "-4px",
                  }}
                  align="center"
                >
                  PERFORMING PHYSICIAN
                </TableCell>
                <TableCell
                  style={{
                    color: this.colourValue,
                    fontSize: "17px",
                    fontWeight: "bold",
                    position: "relative",
                    bottom: "-4px",
                  }}
                  align="center"
                >
                  VIEW STUDY
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="row-animation">
              {this.state.study.map((study) =>
                study.seriesArray
                  .slice() // Create a copy of the array to avoid modifying the original
                  .sort((a, b) => a.series_no - b.series_no) //sort by smallest to largest (switch the b.series_no and a.series_no around to reverse)
                  .map((series) =>
                    series.instanceArray
                      .slice() // Create a copy of the array to avoid modifying the original
                      .sort((a, b) => a.inst_no - b.inst_no) //sort by smallest to largest (switch the b.inst_no and a.inst_no around to reverse)
                      .map((instance) => {
                        //Get the image from the list of images, for each instance iuid
                        const image = this.images.find(
                          (image) => image.sop_iuid === instance.sop_iuid
                        );
                        //Check if the image is listed
                        const imageSrcNotFound =
                          image &&
                          !this.images.some((img) => img.image === image.image);
                        // Check if the image is selected
                        const isImageSelected = this.state.selectedImages.some(
                          (img) => img.sop_iuid === instance.sop_iuid
                        );
                        //Check if image has XCaliber ID
                        const xcaliberId = instance.xcaliber_id;
                        //Check if the image has an XCaliber link (initially checked and populated in ComponentDidMount)
                        const xcaliberLink = instance.xcaliber_link;
                        return (
                          <StyledTableRow key={instance.sop_iuid}>
                            {/*THUMBNAIL*/}
                            <TableCell
                              style={{ color: textThemeColor }}
                              align="center"
                            >
                              {image &&
                              !this.state.imageNotFoundMap[
                                instance.sop_iuid
                              ] ? (
                                <img
                                  src={image.image}
                                  style={{ width: "150px", height: "150px" }}
                                  onError={() =>
                                    this.handleImageError(
                                      instance.sop_iuid,
                                      false
                                    )
                                  }
                                />
                              ) : (
                                <div
                                  style={{
                                    width: "150px",
                                    height: "150px",
                                    background: "black",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "white",
                                      textAlign: "center",
                                      whiteSpace: "pre-line",
                                    }}
                                  >
                                    {imageSrcNotFound
                                      ? "Image IUID not found"
                                      : "Image not retrieved from\nATX PACS WADO"}
                                  </span>
                                </div>
                              )}
                            </TableCell>
                            {/*ADD TO BUNDLE*/}
                            <TableCell
                              style={{ color: textThemeColor }}
                              align="center"
                            >
                              <Button
                                id={`button${instance.pk}`}
                                style={{
                                  background: isImageSelected
                                    ? this.colourValue
                                    : buttonTheme,
                                  color: isImageSelected
                                    ? "white"
                                    : textThemeColor,
                                }}
                                variant="contained"
                                onClick={() => {
                                  this.handleClickAdd(
                                    instance.sop_iuid,
                                    series.series_iuid,
                                    instance.xcaliber_id,
                                    instance.xcaliber_link,
                                    `button${instance.pk}`,
                                    `popup${instance.pk}`
                                  );
                                }}
                              >
                                {isImageSelected ? "UNDO" : "ADD"}
                              </Button>
                              <XCaliberPopup
                                id={`popup${instance.pk}`}
                                isOpen={
                                  this.state.currentAddButton ==
                                    `button${instance.pk}` &&
                                  this.state.showWarning
                                }
                                messageLine1="This will overwrite the previous analysis"
                                messageLine2="Click OK to Proceed"
                                onRequestClose={() =>
                                  this.setState({ showWarning: false })
                                }
                                buttonId={`button${instance.pk}`}
                                onButtonClick={(result) =>
                                  this.handlePopupResult(
                                    result,
                                    instance.sop_iuid,
                                    series.series_iuid,
                                    instance.xcaliber_id,
                                    instance.xcaliber_link,
                                    `button${instance.pk}`
                                  )
                                }
                                colourValue={this.colourValue}
                              />
                            </TableCell>
                            {/*RESULT*/}
                            <TableCell
                              style={{ color: textThemeColor }}
                              align="center"
                            >
                              {xcaliberId ? (
                                <Button
                                  style={{
                                    background: xcaliberLink
                                      ? this.colourValue
                                      : buttonTheme,
                                    color: xcaliberLink
                                      ? "white"
                                      : textThemeColor,
                                  }}
                                  variant="contained"
                                  onClick={() => {
                                    this.handleClickResult(
                                      xcaliberId,
                                      xcaliberLink,
                                      this.props.studyPk
                                    );
                                  }}
                                >
                                  {/*xcaliberLink ? 'READY' : 'CHECK' //No longer using link - download pdf directly each request instead*/}
                                  OPEN
                                </Button>
                              ) : (
                                <div />
                              )}
                            </TableCell>
                            {/*BODY PART*/}
                            <TableCell
                              style={{ color: textThemeColor }}
                              align="center"
                            >
                              {series.body_part}
                            </TableCell>
                            {/*SERIES NUMBER*/}
                            <TableCell
                              style={{ color: textThemeColor }}
                              align="center"
                            >
                              {series.series_no}
                            </TableCell>
                            {/*INSTANCE NUMBER*/}
                            <TableCell
                              style={{ color: textThemeColor }}
                              align="center"
                            >
                              {instance.inst_no}
                            </TableCell>
                            {/*IMAGE TIME*/}
                            <TableCell
                              style={{ color: textThemeColor }}
                              align="center"
                            >
                              {HL7.getStudyDate(instance.content_datetime)}
                            </TableCell>
                            {/*REFERRING PHYSICIAN*/}
                            <TableCell
                              style={{ color: textThemeColor }}
                              align="center"
                            >
                              {this.state.study[0].ref_physician == null
                                ? "Unknown"
                                : HL7.getPatientName(
                                    this.state.study[0].ref_physician
                                  )}
                            </TableCell>
                            {/*PERFORMING PHYSICIAN*/}
                            <TableCell
                              style={{ color: textThemeColor }}
                              align="center"
                            >
                              {series.perf_physician == null
                                ? "Unknown"
                                : HL7.getPatientName(series.perf_physician)}
                            </TableCell>
                            {/*VIEW STUDY*/}
                            <TableCell
                              style={{ color: textThemeColor }}
                              align="center"
                            >
                              <ViewerLink
                                studyData={{
                                  studypk: this.props.studyPk,
                                  studyIuid: this.props.studyIuid,
                                  modsInStudy: "",
                                }}
                              />
                            </TableCell>
                          </StyledTableRow>
                        );
                      })
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </motion.div>
    );
  }

  render() {
    //console.log('Calling render of XCaliberAI (which in turn calls renderProduct IF state.init = 1)');
    //Dark Mode
    let backgroundThemeColor;
    let textThemeColor;
    this.props.darkMode
      ? (backgroundThemeColor = "#212121")
      : (backgroundThemeColor = "#f5f5f5");
    this.props.darkMode
      ? (textThemeColor = "#ffffff")
      : (textThemeColor = "#000000");

    return (
      <>
        {(true ||
          this.type === "chiro" ||
          this.type === "dental" ||
          this.type === "medical" ||
          this.type === "vet") &&
        this.props.studyPk !== null ? (
          <>
            {this.state.init ? (
              <div>{this.renderProduct()}</div>
            ) : (
              <div className="xcaliber-loader">
                <DefaultSpinner
                  spinnerSize={60}
                  displayText={"Loading XCaliber AI"}
                />
              </div>
            )}
          </>
        ) : (
          <Navigate to="/error" />
        )}
      </>
    );
  }
}

export default (props) => {
  const {
    state: { userId, userType },
  } = useAppContext();
  const [queryParameters] = useSearchParams();
  const { studyPk, studyIuid } = useMemo(() => {
    const encodedParams = queryParameters.entries().next().value[0];
    const decodedParams = atob(encodedParams);
    const urlSearchParams = new URLSearchParams(decodedParams);
    const studyPk = urlSearchParams.get("pkid");
    const studyIuid = urlSearchParams.get("pkuid");
    return { studyPk, studyIuid };
  }, [queryParameters]);
  const { isDarkModeOn } = useDarkModeTheme();
  return (
    <XCaliberAI
      userId={userId}
      studyPk={studyPk}
      studyIuid={studyIuid}
      type={userType}
      darkMode={isDarkModeOn}
    />
  );
};
