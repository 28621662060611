import { ImageMetadata } from "pages/viewer/dicomViewer.types";
import Util from "../api/Util";
import {
  DEFAULT_MEASUREMENT_DECIMALS,
  MEASUREMENT_SUFFIXES,
} from "../consts/tools.consts";
import { TwoDimensionCoords } from "../tools.types";

export const getDimensionData = (
  image,
  imageMetaData: ImageMetadata | undefined = {}
) => {
  const { rowPixelSpacing, colPixelSpacing } = Util.getPixelSpacing(image);
  const { pixelSpacing } = imageMetaData ?? {};
  if ((!rowPixelSpacing || !colPixelSpacing) && pixelSpacing) {
    const [metaDataRowPixelSpacing, metaDataColPixelSpacing] = String(
      pixelSpacing
    )
      .split("\\")
      .map(parseFloat);
    return {
      rowPixelSpacing: metaDataRowPixelSpacing,
      colPixelSpacing: metaDataColPixelSpacing,
    };
  }
  if ((!rowPixelSpacing || !colPixelSpacing) && !pixelSpacing) {
    console.warn("No pixel spacing found - using pixels as measurement units");
    return {
      rowPixelSpacing: 0.1,
      colPixelSpacing: 0.1,
    };
  }
  return { rowPixelSpacing, colPixelSpacing };
};

export const getAxisLengths = (
  start: TwoDimensionCoords,
  end: TwoDimensionCoords,
  colPixelSpacing: number,
  rowPixelSpacing: number
) => {
  const dx = Math.abs((end.x - start.x) * rowPixelSpacing);
  const dy = Math.abs((end.y - start.y) * colPixelSpacing);
  return { dx, dy };
};

export const getDisplayedMeasurement = (
  measurement: string | number,
  suffix: string = MEASUREMENT_SUFFIXES.PIXELS,
  decimalPlaces: number = DEFAULT_MEASUREMENT_DECIMALS
) => {
  const measuredValue = sanitizeMeasuredValue(measurement);
  if (!measuredValue) {
    return "";
  }
  const formattedValue = measuredValue.toFixed(decimalPlaces).toLocaleString();
  return `${formattedValue} ${suffix}`;
};

const sanitizeMeasuredValue = (value: string | number) => {
  const parsedValue = Number(value);
  if (!isNaN(parsedValue)) {
    return parsedValue;
  }
  return null;
};

export const getTextBoxAnchorPoints = (handles) => {
  const midpoint = {
    x: (handles.start.x + handles.end.x) / 2,
    y: (handles.start.y + handles.end.y) / 2,
  };

  return [handles.start, midpoint, handles.end];
};
