import { USER_TYPES } from "consts";

export const getUserColorClass = (userType: string) => {
  switch (userType) {
    case USER_TYPES.DENTAL:
      return "user-color-gray";
    case USER_TYPES.CHIRO:
    case USER_TYPES.RADIOLOGIST:
    case USER_TYPES.CHIRO_MEDICARE:
    case USER_TYPES.MEDICARE_ADMIN:
    case USER_TYPES.DEVELOPER:
      return "user-color-aqua";
    case USER_TYPES.VET:
      return "user-color-green";
    case USER_TYPES.VET_XCALIBER:
    case USER_TYPES.ADMIN:
    case USER_TYPES.MEDICAL:
    case USER_TYPES.PUBLIC:
    default:
      return "user-color-maroon";
  }
};
